import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';

// Helpers
import {
  getActions,
  getColumns,
  mapTableData,
  getLocalization,
  defaultTableOptions,
} from 'util/table-utils';
import { CLIENT_TABLE_COLUMNS } from 'constants/ui-constants';
import { PATHS } from 'util/appConstants';

// Actions
import { usePartners } from 'hooks';
import { deletePartner, selectPartnersStatus, searchFilter, setSearch } from 'redux/slices/partnerSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'PARTNER';

const PartnersList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const user = useSelector(selectUser);
  const loading = useSelector(selectPartnersStatus);
  const history = useHistory();
  const dispatch = useDispatch();
  const { partners, revalidate } = usePartners();
  const search = useSelector(searchFilter);

  const callbackOnDelete = (e, { id }) => {
    e.stopPropagation();

    confirm(
      async () => {
        await dispatch(deletePartner(id));
        revalidate();
      },
      { description: 'Are you sure?' },
    );
  };

  const actions = useMemo(
    () => getActions(
      user,
      tableTitle,
      () => history.push(PATHS.partners.add),
      (e, rowData) => callbackOnDelete(e, rowData),
    ), [user]);


  if (!user || loading) return <div>Loading..</div>;

  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        data={partners.map((p) => {
          return {
            ...p,
            partnerType: t(p.partnerType),
          };
        })}
        title={t(tableTitle)}
        actions={actions}
        columns={getColumns(CLIENT_TABLE_COLUMNS, t)}
        minRows={0}
        localization={getLocalization(t)}
        onRowClick={(e, { id }) => history.push(
          PATHS.partners.edit.replace(':id', id),
        )}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(PartnersList);
