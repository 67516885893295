import useBaseHook from './useBaseHook';
import {
  selectPartners,
  fetchPartners,
  selectPartnersStatus,
  fetchDeviatingPartners,
  selectDeviatingPartners,
  selectDeviatingTimestamp,
  selectTimestamp,
  selectDeviatingPartnersStatus,
} from 'redux/slices/partnerSlice';

const usePartners = () => {
  const {
    records: partners,
    loading,
    revalidate,
    timestamp,
  } = useBaseHook({
    recordsSelector: selectPartners,
    loadingSelector: selectPartnersStatus,
    timestampSelector: selectTimestamp,
    fetchRecords: fetchPartners,
  });

  return { partners, loading, revalidate, timestamp };
};

export const useDeviatingPartners = () => {
  const {
    records: deviatings,
    loading,
    revalidate,
  } = useBaseHook({
    recordsSelector: selectDeviatingPartners,
    loadingSelector: selectDeviatingPartnersStatus,
    timestampSelector: selectDeviatingTimestamp,
    fetchRecords: fetchDeviatingPartners,
  });

  return { deviatings, loading, revalidate };
};

export default usePartners;
