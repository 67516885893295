import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/warehouses';
const initialState = {
  warehouses: {},
  warehouse: null,
  loading: false,
  timestamp: null,
  search: ""
};

const warehouseSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    addNewWarehouse: (state, action) => {
      const { _id } = action.payload;
      state.warehouses[_id] = action.payload;
      state.warehouse = action.payload;
    },
    setEditWarehouse: (state, action) => {
      const { id } = action.payload;
      state.warehouses[id] = {
        ...state.warehouses[id],
        ...action.payload,
      };
      state.warehouse = action.payload;
    },
    setWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },
    setWarehouses: (state, action) => {
      const warehouses = {};
      action.payload.forEach(warehouse => {
        warehouses[warehouse.id] = warehouse;
      });
      state.warehouses = warehouses;
      state.timestamp = +new Date();
    },
    removeWarehouse: (state, action) => {
      delete state.warehouses[action.payload];
    },
    setWarehouseLoading: (state) => {
      state.loading = true;
    },
    setWarehouseReady: (state) => {
      state.loading = false;
    },
    resetWarehouses: (state) => {
      state.warehouses = {};
      state.timestamp = null;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setWarehouse,
  setWarehouses,
  addNewWarehouse,
  setEditWarehouse,
  removeWarehouse,
  setWarehouseLoading,
  setWarehouseReady,
  resetWarehouses,
  setSearch
} = warehouseSlice.actions;
export default warehouseSlice.reducer;

export const getWarehouse = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setWarehouseLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setWarehouse(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setWarehouseReady());
  }
};

export const getWarehouses = () => async dispatch => {
  dispatch(setWarehouseLoading());

  try {
    const warehouses = await coreApi.fetch(baseUrl);
    dispatch(setWarehouses(warehouses));

    return warehouses;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setWarehouseReady());
  }
};

export const getStock = () => async (dispatch) => {
  dispatch(setWarehouseLoading());

  try {
    const warehouses = await coreApi.fetch(`${baseUrl}/stocks`);

    return warehouses;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setWarehouseReady());
  }
};

export const addWarehouse = (payload) => async dispatch => {
  dispatch(setWarehouseLoading());

  try {
    const warehouse = await coreApi.post(baseUrl, payload);
    dispatch(resetWarehouses());
    dispatch(setShowMessage({
      description: 'Warehouse Added Successfully!',
      type: 'success',
    }));

    return warehouse;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding warehouse. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setWarehouseReady());
  }
};

export const editWarehouse = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setWarehouseLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res) {
      dispatch(setEditWarehouse({ ...payload, id }));
      dispatch(resetWarehouses());
      dispatch(setShowMessage({
        description: 'Warehouse Updated Successfully',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed editing warehouse. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setWarehouseReady());
  }
};

export const deleteWarehouse = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setWarehouseLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeWarehouse(id));
      dispatch(setShowMessage({
        description: 'Warehouse Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Warehouse. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setWarehouseReady());
  }
};

const warehouseSelector = ({ warehouses }) => warehouses.warehouse;
const warehousesSelector = ({ warehouses }) => warehouses.warehouses;
const warehouseStatusSelector = ({ warehouses }) => warehouses.loading;
const searchSelector = ({ warehouses }) => warehouses.search;

export const selectWarehouse = createSelector(warehouseSelector, warehouse => warehouse);
export const selectWarehouses = createSelector(warehousesSelector, warehouses => Object.values(warehouses));
export const selectWarehouseStatus = createSelector(warehouseStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ warehouses }) => warehouses.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
