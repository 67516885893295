import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import clsx from 'clsx';

// Helpers
import { TeamSchema } from './constants';
import { PATHS, formActions } from 'util/appConstants';

// Actions
import { selectPersonLoading } from 'redux/slices/personSlice';

// Components
import { Box, Grid, Button, Typography } from '@material-ui/core';
import {
  Input,
  Select,
  ChipsInput,
  InputOnlyNumbers,
  MultiSelectChips,
  PermissionsPicker,
} from 'components/Shared/mui-formik-inputs';
import UploadSignature from './UploadSignature';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  button: {
    margin: 10,
    backgroundColor: 'white',
  },
  buttonSubmit: {
    color: 'green',

    '&:hover': {
      color: '#fff',
    },
  },
  buttonCancel: {
    color: 'red',
  },
  wrapper: {
    padding: '8px',

    '&:last-of-type': {
      margin: '20px 0',
    },
  },
}));


const TeamForm = ({ onSubmit, initialValues, action }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const loading = useSelector(selectPersonLoading);
  const history = useHistory();

  if (loading) return <div>loading..</div>;
  return (
    <Box boxShadow={3} p={2}>
      <Typography variant='h4'>{t('Team Information')}</Typography>
      <Formik
        enableReinitialize={true}
        validationSchema={TeamSchema}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnMount={action === formActions.edit}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};

          if (!initialValues._id && !values.password) {
            errors.password = 'Required';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (action === formActions.add) {
              return await onSubmit(values);
            }

            if (action === formActions.edit) {
              const payload = omit(values, [
                'createdAt', 'id', 'teamSignature', '_id', 'updatedAt', '__v', 'teamEmails', 'signatures',
              ]);

              payload.permissions = {
                ...initialValues.permissions,
                ...values.permissions,
              };

              return await onSubmit(values._id, payload);
            }
          } catch (err) {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
          resetForm,
          touched,
          submitCount,
        }) => {
          if (!submitCount) {
            handleBlur = null;
          }

          return (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='email'
                    value={values.email}
                    label={'User Email'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='password'
                    value={values.password}
                    label={'User Password'}
                    type='password'
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <MultiSelectChips
                    name='role'
                    value={values.role}
                    label={'Role'}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    errors={errors}
                    options={[
                      'Office Staff',
                      'Account Manager',
                      'Waste Officer',
                      'Contact',
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Select
                    name='salutation'
                    value={values.salutation}
                    label={'Salutation'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={[
                      { label: t('Mr'), value: t('Mr') },
                      { label: t('Mrs'), value: t('Mrs') },
                      { label: t('Dr'), value: t('Dr') },
                      { label: t('Other'), value: t('Other') },
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='firstName'
                    value={values.firstName}
                    label={'First Name'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='surname'
                    value={values.surname}
                    label={'Surname'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='position'
                    value={values.position}
                    label={'Position'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='responsibility'
                    value={values.responsibility}
                    label={'Responsibility'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='department'
                    value={values.department}
                    label={'Department'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <InputOnlyNumbers
                    name='phone'
                    value={values.phone}
                    label={'Phone'}
                    type='tel'
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <InputOnlyNumbers
                    name='mobileNumber'
                    value={values.mobileNumber}
                    label={'Mobile Number'}
                    type='tel'
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='fax'
                    value={values.fax}
                    label={'Fax'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name='comments'
                    value={values.comments}
                    label={'Comments'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <ChipsInput
                    name='emails'
                    value={values.emails}
                    label='E-mail addresses*'
                    errors={errors}
                    touched={touched}
                    required={true}
                    setFieldValue={setFieldValue}
                    fullWidth
                  />
                </Grid>
                <div className={classes.wrapper}>
                  <PermissionsPicker
                    name='permissions'
                    values={values.permissions}
                    label={'Select permissions'}
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    errors={errors}
                    required
                  />
                </div>
                <div className={classes.wrapper}>
                  <UploadSignature setFieldValue={setFieldValue} values={values} />
                </div>
              </Grid>
              <Button
                className={clsx(classes.button, classes.buttonSubmit)}
                variant='contained'
                color='primary'
                startIcon={<CloudUploadIcon />}
                type='submit'
              >
                {action === formActions.add ? t('Add Team Member') : t('Edit Team Member')}
              </Button>
              <Button
                className={clsx(classes.button, classes.buttonCancel)}
                variant='contained'
                startIcon={<CancelIcon />}
                onClick={() => {
                  resetForm({ ...initialValues });
                  history.push(PATHS.system.team.root);
                }}
              >
                {t('Cancel')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

TeamForm.propTypes = {
  action: PropTypes.oneOf([formActions.add, formActions.edit]),
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  person: PropTypes.object,
};

export default TeamForm;
