import moment from 'moment';
import { fixPreviousBrokenDates } from '../util/index';

export const mobileBreakpoint = 766;

export const TOP_NAVIGATION_ROUTES = [
  'PARTNER',
  'CARRIERS',
  'PERSON',
  'ORDERS',
  'WAREHOUSE',
  'STOCKS',
  'CONTRACTS',
];

export const BOTTOM_NAVIGATION_ROUTES = ['SYSTEM', 'COMPANIES'];

export const MID_NAVIGATION_ROUTES = ['BILL RUN', 'PAYMENT RUN', 'ACCOUNT BOOKS', 'ENTER INVOICES'];

export const SYSTEM_NAVIGATION_ROUTES = ['BANKS', 'TAX MATRIX', 'ARTICLES', 'TEAMS', 'WAREHOUSE'];

export const ORDER_MENU_TABS = ['Order Details', 'WS+ Invoice', 'Tuning'];

export const ARTICLE_ITEM_TYPE_MENU_CHOICES = ['Waste', 'Services', 'Product'];

export const PERMISSIONS_EDIT = {
  PARTNER: 'partners_update',
  CARRIERS: 'partners_update',
  PERSON: 'partners_update',
  ORDERS: 'partners_update',
  WAREHOUSE: 'partners_update',
  CONTRACTS: 'contracts_update',
};

export const PERMISSIONS_CREATE = {
  PARTNER: 'partners_create',
  CARRIERS: 'carriers_create',
  PERSON: 'persons_create',
  ORDERS: 'orders_create',
  WAREHOUSE: 'warehouse_create',
  CONTRACTS: 'contracts_create',
};

export const PERMISSIONS_DELETE = {
  PARTNER: 'partners_delete',
  CARRIERS: 'carriers_delete',
  PERSON: 'persons_delete',
  ORDERS: 'orders_delete',
  WAREHOUSE: 'warehouse_delete',
  CONTRACTS: 'contracts_delete',
};

export const CLIENT_TABLE_COLUMNS = [
  { title: 'Name1', field: 'name1' },
  { title: 'Address', field: 'address' },
  { title: 'Name2', field: 'name2' },
  { title: 'Partner Type', field: 'partnerType' },
];

export const TAX_TABLE_COLUMNS = [
  { title: 'Country Source', field: 'group' },
  { title: 'Group', field: 'group' },
];

export const TAX_MATRIX_COLUMNS = [
  { title: 'Country source', field: 'country_source' },
  { title: 'Country processor', field: 'country_processor' },
  { title: 'Article category', field: 'article_category' },
  { title: 'Incoterms processor', field: 'incoterms_processor' },
  { title: 'Incoterms source', field: 'incoterms_source' },
  { title: 'Material flow source', field: 'material_flow_source' },
  { title: 'Material flow processor', field: 'material_flow_processor' },
  { title: 'Tax code processor', field: 'tax_code_processor' },
  { title: 'Tax code source', field: 'tax_code_source' },
  { title: 'Tax rate processor', field: 'tax_rate_processor' },
  { title: 'Tax rate source', field: 'tax_rate_source' },
  { title: 'Tax text processor', field: 'tax_text_processor' },
  { title: 'Tax text source', field: 'tax_text_source' },
];

export const WAREHOUSE_TABLE_COLUMNS = [
  { title: 'Name', field: 'name1' },
  { title: 'Alias', field: 'alias' },
  { title: 'Address', field: 'address' },
];

export const WAREHOUSE_STOCK_TABLE_COLUMNS = [
  { title: 'Name', field: 'name1' },
  { title: 'Address', field: 'address' },
];

export const CARRIER_TABLE_COLUMNS = [
  { title: 'Name1', field: 'name1' },
  { title: 'Address', field: 'address' },
  { title: 'Name2', field: 'name2' },
];

export const HARBORS_TABLE_COLUMNS = [
  { title: 'Name', field: 'name' },
  { title: 'Code', field: 'code' },
];

export const ARTICLE_TABLE_COLUMNS = [
  { title: 'Article Number', field: 'articleNumber' },
  { title: 'Item type', field: 'itemType' },
  { title: 'Description in German', field: 'descriptionInGerman' },
  { title: 'Description in English', field: 'descriptionInEnglish' },
  { title: 'HS Code', field: 'hsCode' },
  { title: 'Basal Annex IX', field: 'basalAnnexIX' },
  { title: 'Waste code', field: 'avv' },
];

export const PERSON_TABLE_COLUMNS = [
  { title: 'Salutation', field: 'salutation' },
  { title: 'First Name', field: 'firstName' },
  { title: 'Surname', field: 'surname' },
  {
    title: 'Role',
    field: 'role',
    render: (rowData) => {
      return '';
    },
  },
  { title: 'Department', field: 'department' },
  { title: 'Phone', field: 'phone' },
  { title: 'E-mail', field: 'emails' },
];

export const ORDER_TABLE_COLUMNS = [
  {
    title: 'Nummer',
    field: 'orderNumber',
    cellStyle: {
      padding: '0px !important',
    },
  },
  {
    title: 'Status',
    field: 'orderNumber',
    headerStyle: {
      fontWeight: 'bold',
      width: 200,
      maxWidth: 200,
    },
    cellStyle: { width: 200, maxWidth: 200 },
    render: (rowData) => {
      return (
        <span>
          {rowData.status && rowData.status.yellow && (
            <span className={'yellow'} />
          )}
          {rowData.status && rowData.status.orange && (
            <span className={'orange'} />
          )}
          {rowData.status && rowData.status.green && (
            <span className={'green'} />
          )}
          {rowData.status && rowData.status.checkbox && (
            <span className={'blue'} />
          )}
        </span>
      );
    },
  },
  {
    title: 'Date Purchase',
    field: 'source[0].name2',
    render: (rowData) => {
      return (
        <span>
          {rowData.docStatus.purchase_confirmation.date
            ? moment(rowData.docStatus.purchase_confirmation.date)
              .format(
                'DD.MM.YYYY',
              )
            : 'N/A'}
        </span>
      );
    },
  },
  {
    title: 'Source',
    field: 'source[0].name1',
    render: (r) => (<>{r.source[0]?.name2 || r.source[0]?.name1}</>),
  },
  {
    title: 'Article',
    field: 'firstArticleNameGerman',
  },
  { title: 'Loading Weight', field: 'sourceWeight' },
  {
    title: 'Delivery Date',
    field: 'deliveryDate.startDate',
    render: (rowData) => {
      let date;
      let processorInvoiceDate = rowData.firstArticleWeightSlipDate
        ? rowData.firstArticleWeightSlipDate
        : null;

      if (processorInvoiceDate) {
        date = processorInvoiceDate;
      } else {
        date = rowData.deliveryDate && rowData.deliveryDate.startDate
            ? rowData.deliveryDate.startDate
            : null;
      }

      if (!date) {
        return <span>N/A</span>
      }

      return <span>{moment(fixPreviousBrokenDates(date)).format('DD.MM.YYYY')}</span>;
    },
  },

  {
    title: 'Price P',
    field: 'sourcePrice',
    render: (rowData) => (
      <span>
        {rowData.articleTotalSource
          ? rowData.articleTotalSource
          : rowData.firstArticleSourcePrice > 0
            ? rowData.firstArticleSourcePrice
            : ''}
      </span>
    ),
  },
  {
    title: 'Modality P',
    field: 'firstArticleProcessorCashflow',
  },
  {
    title: 'Price S',
    field: 'processorPrice',
    render: (rowData) => (
      <span>
        {rowData.articleTotalProcessor
          ? rowData.articleTotalProcessor
          : rowData.firstArticleProcessorPrice > 0
            ? rowData.firstArticleProcessorPrice
            : ''}
      </span>
    ),
  },
  {
    title: 'Modality S',
    field: 'firstArticleSourceCashflow',
  },
  {
    title: 'Processor',
    field: 'processor[0].name2',
    render: (r) => (<>{r.processor[0]?.name2 || r.processor[0]?.name1}</>),
  },
  { title: 'Carrier', field: 'carrier[0].name2' },
  { title: 'Shipping Cost', field: 'shippingCost' },
  {
    title: 'Booking No',
    field: 'bookingNo',
  },
  {
    title: 'invoice_no',
    field: 'invoiceNumberWithPrefixAndSuffix',
    customFilterAndSearch: (value, rowData) => {
      return rowData.invoiceNumberSourceWithPrefixAndSuffix.includes(value) ||
        rowData.invoiceNumberProcessorWithPrefixAndSuffix.includes(value);
    },
    render: (rowData) => (
      <span>
        {rowData.invoiceNumberSourceWithPrefixAndSuffix &&
          rowData.invoiceNumberSourceWithPrefixAndSuffix.length > 0 && (
            <span>
              {'P'}:{' '}
              {rowData.invoiceNumberSourceWithPrefixAndSuffix}
            </span>
          )}
        <br />
        {rowData.invoiceNumberProcessorWithPrefixAndSuffix &&
          rowData.invoiceNumberProcessorWithPrefixAndSuffix.length > 0 && (
            <span>
              {'S'}:{' '}
              {rowData.invoiceNumberProcessorWithPrefixAndSuffix}
            </span>
          )}
      </span>
    ),
  },
];

export const CONTRACTS_TABLE_COLUMNS = [
  { title: 'Buyer', field: 'buyer' },
  { title: 'Shipper', field: 'shipper' },
  { title: 'Consignee', field: 'consignee' },
  { title: 'Notify', field: 'notify' },
  { title: 'Commercial', field: 'commercial' },
  { title: 'End User', field: 'endUser' },
  { title: 'Incoterms', field: 'incoterms' },
  { title: 'POD', field: 'pod' },
  { title: 'DTCH', field: 'dtch' },
  { title: 'LOTS', field: 'lots' },
  { title: 'Bank', field: 'bank' },
  { title: 'Payment Term', field: 'paymentTerm' },
];

export const BANK_TABLE_COLUMNS = [
  { title: 'Company Code', field: 'companyCode' },
  { title: 'Bank Name', field: 'bankName' },
  { title: 'Account Number', field: 'accountNumber' },
  { title: 'Bank Code', field: 'bankCode' },
  { title: 'IBAN', field: 'iban' },
  { title: 'BIC', field: 'bic' },
];

export const COMPANY_TABLE_COLUMNS = [
  { title: 'Company Code', field: 'companyCode' },
  { title: 'Name1', field: 'name1' },
  { title: 'Name2', field: 'name2' },
  { title: 'Address', field: 'address' },
];

export const GROUP_TABLE_COLUMNS = [
  { title: 'Number', field: 'groupNumber' },
  { title: 'Name', field: 'groupName' },
  { title: 'Company', field: 'company' },
];

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  TOP_NAVIGATION_ROUTES,
  BOTTOM_NAVIGATION_ROUTES,
  SYSTEM_NAVIGATION_ROUTES,
  CLIENT_TABLE_COLUMNS,
  ARTICLE_TABLE_COLUMNS,
  PERSON_TABLE_COLUMNS,
  ORDER_TABLE_COLUMNS,
  CONTRACTS_TABLE_COLUMNS,
  BANK_TABLE_COLUMNS,
  GROUP_TABLE_COLUMNS,
  COMPANY_TABLE_COLUMNS,
  TAX_TABLE_COLUMNS,
  PERMISSIONS_CREATE,
  PERMISSIONS_DELETE,
  PERMISSIONS_EDIT,
  EMAIL_REGEX,
};
