import * as yup from 'yup';

export const PartnerSchema = yup.object().shape({
  // Basic Data
  partnerType: yup.string().required('Required'),
  name1: yup.string().required('Required'),
  name2: yup.string().required('Required'),
  street: yup.string().required('Required'),
  houseNumber: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  city: yup.string().required('Required'),
  country: yup.string().required('Required'),
  phone1: yup.string().required('Required'),
  phone2: yup.string().nullable(),
  mobile: yup.string().nullable(),
  fax: yup.string().nullable(),
  email: yup.string().email('Invalid email').required('Required'),
  certifiedRecoveryOptions: yup.string().nullable(),
  eFact: yup.string().nullable(),
  pod: yup.array().nullable(),
  // Banking and Billing
  paymentTerms: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  taxID: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  paymentDays: yup.string().when('paymentTerms', {
    is: 'Other',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  invoiceDeliveryMethod: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  invoiceEmail: yup.array().when('invoiceDeliveryMethod', {
    is: 'Email',
    then: yup.array().test('invoiceEmail', 'Required. Valid emails only', (value) => {
      return value.length && value.every((v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v));
    }),
    otherwise: yup.array().nullable(),
  }),
  documentLanguage: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  basisForInvoice: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  currency: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  iban: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  bic: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  bankName: yup.string().when('partnerType', {
    is: 'Debitor/Kreditor',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  clientNumberAtClient: yup.string().nullable(),
  nationalTaxID: yup.string().nullable(),
  // Additional Locations
  locations: yup.array().of(yup.object().shape({
    city: yup.string().required('Required'),
    country: yup.string().required('Required'),
    houseNumber: yup.string().required('Required'),
    street: yup.string().required('Required'),
    zipcode: yup.string().required('Required'),
  })).required('Required'),
  openingHours: yup.array().of(
    yup.object().shape({
      end_day: yup.string().nullable(),
      end_time: yup.string().nullable(),
      end_time1: yup.string().nullable(),
      start_day: yup.string().nullable(),
      start_time: yup.string().nullable(),
      start_time1: yup.string().nullable(),
    })
  ),
});

export const initialValues = {
  // Basic Data
  partnerType: '',
  name1: '',
  name2: '',
  street: '',
  houseNumber: '',
  zipcode: '',
  city: '',
  country: '',
  phone1: '',
  phone2: '',
  mobile: '',
  fax: '',
  email: '',
  certifiedRecoveryOptions: '',
  eFact: '',
  pod: [],
  // Opening Hours
  openingHours: [
    {
      start_day: '',
      start_time: '',
      start_time1: '',
      end_day: '',
      end_time: '',
      end_time1: '',
    },
    {
      start_day: '',
      start_time: '',
      start_time1: '',
      end_day: '',
      end_time: '',
      end_time1: '',
    },
  ],
  // Banking and Billing
  paymentTerms: '',
  invoiceDeliveryMethod: '',
  documentLanguage: '',
  basisForInvoice: '',
  currency: '',
  clientNumberAtClient: '',
  taxID: '',
  nationalTaxID: '',
  iban: '',
  bic: '',
  bankName: '',
  invoiceEmail: [],
  // Additional Locations
  locations: [],
};

export const getEfactOptions = (efactList) => {
  return efactList.map(n => ({
    name: `${n['Anlagennummer']} | ${n['V_BEZEICHNUNG1']}`,
  }));
};

export const allowedFields = [
  'partnerType',
  'name1',
  'name2',
  'street',
  'houseNumber',
  'zipcode',
  'city',
  'country',
  'phone1',
  'phone2',
  'mobile',
  'fax',
  'email',
  'certifiedRecoveryOptions',
  'eFact',
  'pod',
  'openingHours',
  'paymentTerms',
  'paymentDays',
  'invoiceDeliveryMethod',
  'invoiceEmail',
  'documentLanguage',
  'basisForInvoice',
  'currency',
  'iban',
  'bic',
  'bankName',
  'clientNumberAtClient',
  'taxID',
  'nationalTaxID',
  'locations',
  'contactPersons'
];
