import useBaseHook from './useBaseHook';
import { selectContracts, fetchContracts, selectContractStatus, selectTimestamp } from 'redux/slices/contractsSlice';

const useContracts = () => {
  const {
    records: contracts,
    loading,
    revalidate,
  } = useBaseHook({
    fetchRecords: fetchContracts,
    recordsSelector: selectContracts,
    loadingSelector: selectContractStatus,
    timestampSelector: selectTimestamp,
  });

  return { contracts, loading, revalidate };
};

export default useContracts;
