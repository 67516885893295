import * as yup from 'yup';

const StockSchema = yup.object().shape({
  article_id: yup.string().required('Required'),
  amount: yup.number().required('Required'),
  unit: yup.string().required('Required'),
});

export const WarehouseSchema = yup.object().shape({
  name1: yup.string().required('Required'),
  alias: yup.string().nullable(),
  houseNumber: yup.string().required('Required'),
  city: yup.string().required('Required'),
  street: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  country: yup.string().required('Required'),
  openingHours: yup.array().of(
    yup.object().shape({
      end_day: yup.string().nullable(),
      end_time: yup.string().nullable(),
      end_time1: yup.string().nullable(),
      start_day: yup.string().nullable(),
      start_time: yup.string().nullable(),
      start_time1: yup.string().nullable(),
    })
  ),
  contactPersons: yup.array(),
  stocks: yup.array().of(StockSchema).required(),
});

export const WarehouseFormAllowedFields = [
  'name1',
  'alias',
  'houseNumber',
  'city',
  'street',
  'zipcode',
  'country',
  'openingHours',
  'contactPersons',
  'stocks',
];
