import * as yup from 'yup';

const IncotermRequirement = yup.mixed().when('incotermSource', {
  is: 'EXW',
  then: yup.mixed().when('incotermProcessor', {
    is: 'EXW',
    then: yup.string().nullable(),
    otherwise: yup.string().required('Required').typeError('Required'),
  }),
  otherwise: yup.mixed().when('incotermSource', {
    is: 'DAP',
    then: yup.mixed().when('incotermProcessor', {
      is: 'DAP',
      then: yup.string().nullable(),
      otherwise: yup.string().required('Required').typeError('Required'),
    }),
    otherwise: yup.mixed().when('incotermSource', {
      is: 'DDP',
      then: yup.mixed().when('incotermProcessor', {
        is: 'DDP',
        then: yup.string().required('Required').typeError('Required'),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().required('Required').typeError('Required'),
    }),
  })
});

const BaseArticle = yup.object().shape({
  article_id: yup.string().required('Required'),
  article_name: yup.string().required('Required'),
  wastecode: yup.string().nullable(),

  processor_deviating: yup.string().nullable(),
  processor_cashflow: yup.string().required('Required'),
  processor_price: yup.string().required('Required'),
  processor_currency: yup.string().required('Required'),
  processor_unit: yup.string().required('Required'),
  processor_condition: yup.string().nullable(),

  source_deviating: yup.string().nullable(),
  source_cashflow: yup.string().required('Required'),
  source_price: yup.string().required('Required'),
  source_currency: yup.string().required('Required'),
  source_unit: yup.string().required('Required'),
  source_condition: yup.string().nullable(),
});

export const ArticleRequiredSourceOnly = yup.array().required('Required').of(
  BaseArticle.shape({
    processor_deviating: yup.string().nullable(),
    processor_cashflow: yup.string().nullable(),
    processor_price: yup.string().nullable(),
    processor_currency: yup.string().nullable(),
    processor_unit: yup.string().nullable(),
    processor_condition: yup.string().nullable(),
  }),
);

export const ArticleRequiredProcessorOnly = yup.array().required('Required').of(
  BaseArticle.shape({
    source_deviating: yup.string().nullable(),
    source_cashflow: yup.string().nullable(),
    source_price: yup.string().nullable(),
    source_currency: yup.string().nullable(),
    source_unit: yup.string().nullable(),
    source_condition: yup.string().nullable(),
  }),
);

const CommissionArticle = yup.object().shape({
  article_id: yup.number().min(0).max(0).required('Required'),
  article_name: yup.string().required('Required'),
  commissionPartner: yup.string().required('Required'),
  commission_currency: yup.string().required('Required'),
  commission_price: yup.string().nullable(),
  source_deviating: yup.string().nullable(),
});

const Weightslip = yup.object().shape({
  invoiceDate: yup.string().nullable(),
  invoiceNumber: yup.string().nullable(),
  files: yup.array().of(yup.string()),
});

export const CreateSchema = yup.object().shape({
  source: yup.string().required('Required'),
  sourceLocationId: yup.string().nullable(),
  fieldAgent: yup.string().required('Required'),
  processor: yup.string().required('Required'),
  processorLocationId: yup.string().nullable(),
  deliveryProcess: yup.string().required('Required'),
  collectionSource: yup.string().required('Required'),
  accountManagerProcessor: yup.string().required('Required'),
  carrier: IncotermRequirement,
  shippingCost: IncotermRequirement,
  vehicle: IncotermRequirement,
  minLoadingWeight: IncotermRequirement,
  deviatingInvoiceAddressSource: yup.string().nullable(),
  deviatingInvoiceAddressProcessor: yup.string().nullable(),
  destinationType: yup.string().required('Required'),
  incotermSource: yup.string().required('Required'),
  incotermProcessor: yup.string().required('Required'),
  countries: yup.array().of(yup.string()).required('Required'),
  transportationBudget: yup.string().nullable(),
  currency: yup.string().required('Required'),
  bookingNo: yup.string().nullable(),
  transportRemark: yup.string().nullable(),
  category: yup.string().nullable(),
  pickupDate: yup.object().shape({
    startDate: yup.date().nullable(),
  }).required('Required'),
  deliveryDate: yup.object().shape({
    startDate: yup.date().nullable(),
  }).required('Required'),
  loadingReferenceSource: yup.string().nullable(),
  loadingReferenceProcessor: yup.string().nullable(),
  articles: yup.mixed().when('destinationType', {
    is: 'dropshippment',
    then: yup.array().required('Required').of(BaseArticle),
    otherwise: yup.mixed().when('processorIsWarehouse', {
      is: true,
      then: ArticleRequiredSourceOnly,
      otherwise: yup.mixed().when('sourceIsWarehouse', {
        is: true,
        then: ArticleRequiredProcessorOnly,
        otherwise: yup.array().required('Required').of(BaseArticle),
      }),
    }),
  }),
  commissionArticles: yup.array().of(CommissionArticle),
  weightslips: yup.object().shape({
    carrierEntries: yup.array().of(Weightslip),
    processorEntries: yup.array().of(Weightslip),
    sourceEntries: yup.array().of(Weightslip),
  }).nullable(),
  orderRemark: yup.string().nullable(),
  internRemarks: yup.string().nullable(),
});

export const acceptedFields = [
  'source',
  'sourceLocationId',
  'fieldAgent',
  'processor',
  'processorLocationId',
  'deliveryProcess',
  'collectionSource',
  'accountManagerProcessor',
  'carrier',
  'shippingCost',
  'vehicle',
  'minLoadingWeight',
  'deviatingInvoiceAddressSource',
  'deviatingInvoiceAddressProcessor',
  'destinationType',
  'incotermSource',
  'incotermProcessor',
  'countries',
  'transportationBudget',
  'shippingCost',
  'currency',
  'bookingNo',
  'transportRemark',
  'category',
  'pickupDate',
  'deliveryDate',
  'loadingReferenceSource',
  'loadingReferenceProcessor',
  'articles',
  'commissionArticles',
  'weightslips',
  'orderRemark',
  'internRemarks',
];

export const articlesAcceptedFields = [
  '_id',
  'article_id',
  'wastecode',
  'processor_deviating',
  'processor_cashflow',
  'processor_price',
  'processor_currency',
  'processor_unit',
  'processor_condition',
  'source_deviating',
  'source_cashflow',
  'source_price',
  'source_currency',
  'source_unit',
  'source_condition',
  'article_name',
  'commissionPartner',
  'commission_currency',
  'commission_price',
  'source_weightslip_date',
  'source_weightslip_number',
  'source_weight',
  'source_amount',
  'source_packaging_units',
  'processor_weightslip_date',
  'processor_weightslip_number',
  'processor_weight',
  'processor_amount',
  'processor_packaging_units',
  'files',
];

export const commissionArticlesAcceptedFields = [
  '_id',
  'article_id',
  'article_name',
  'commissionPartner',
  'commission_currency',
  'commission_price',
  'source_deviating',
];
