import * as yup from 'yup';

export const HarborSchema = yup.object().shape({
  name: yup.string().required('Required'),
  code: yup.string().required('Required'),
});

export const allowedFields = [
  'name',
  'code',
];
