import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/articles';
const initialState = {
  articles: {},
  article: null,
  timestamp: null,
  loading: false,
  search: ""
};

const articleSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    addNewArticle: (state, action) => {
      const { _id } = action.payload;
      state.articles[_id] = action.payload;
      state.article = action.payload;
    },
    setEditArticle: (state, action) => {
      const { id } = action.payload;
      state.articles[id] = {
        ...state.articles[id],
        ...action.payload,
      };
      state.article = action.payload;
    },
    setArticle: (state, action) => {
      state.article = action.payload;
    },
    resetArticles: (state, action) => {
      state.articles = {};
      state.timestamp = null;
    },
    setArticles: (state, action) => {
      const articles = {};
      action.payload.forEach(article => {
        articles[article.id] = article;
      });
      state.articles = articles;
      state.timestamp = +new Date();
    },
    removeArticle: (state, action) => {
      delete state.articles[action.payload];
    },
    setArticleLoading: (state) => {
      state.loading = true;
    },
    setArticleReady: (state) => {
      state.loading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setArticle,
  setArticles,
  addNewArticle,
  setEditArticle,
  removeArticle,
  setArticleLoading,
  setArticleReady,
  resetArticles,
  setSearch
} = articleSlice.actions;
export default articleSlice.reducer;

export const getArticle = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setArticleLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setArticle(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setArticleReady());
  }
};

export const getArticles = () => async dispatch => {
  dispatch(setArticleLoading());

  try {
    const articles = await coreApi.fetch(baseUrl);
    dispatch(setArticles(articles));

    return articles;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setArticleReady());
  }
};

export const addArticle = (payload) => async dispatch => {
  dispatch(setArticleLoading());

  try {
    const article = await coreApi.post(baseUrl, payload);
    dispatch(resetArticles());
    dispatch(setShowMessage({
      description: 'Article Added Successfully!',
      type: 'success',
    }));

    return article;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding article. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setArticleReady());
  }
};

export const editArticle = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setArticleLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res) {
      dispatch(setEditArticle({ ...payload, id }));
      dispatch(resetArticles());
      dispatch(setShowMessage({
        description: 'Article Updated Successfully',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed editing article. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setArticleReady());
  }
};

export const deleteArticle = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setArticleLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeArticle(id));
      dispatch(setShowMessage({
        description: 'Article Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Article. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setArticleReady());
  }
};

const articleSelector = ({ articles }) => articles.article;
const articlesSelector = ({ articles }) => articles.articles;
const articleStatusSelector = ({ articles }) => articles.loading;
const searchSelector = ({ articles }) => articles.search;

export const selectArticle = createSelector(articleSelector, article => article);
export const selectArticles = createSelector(articlesSelector, articles => Object.values(articles));
export const selectArticleStatus = createSelector(articleStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ articles }) => articles.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
