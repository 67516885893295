import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

// Helpers
import { getLocalization } from 'util/table-utils';

// Components
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  addButton: {
    marginBottom: '12px',
    backgroundColor: 'white',
    color: 'green',
  },
}));


const TabSelectTeam = ({ allPersons, contactPersonsIds, onAdd }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);

  const getTableData = (data) => {
    return data
      .filter((person) => !contactPersonsIds.includes(person._id) && person.type === 'Intern')
      .map((person) => {
        return {
          ...person,
          salutation: t(person.salutation),
          role: person.role.map((r)=> t(r)),
          // we are keeping track of the selected rows ourselves
          // so onSelectionChange is causing a re-render
          // so we set this tableData so that the rows appear selected in the table
          tableData: {
            checked: selectedRows.includes(person._id),
          },
        };
      })
  };

  const handleAddPerson = async () => {
    const selected = allPersons.filter((p) => selectedRows.includes(p._id));

    onAdd(selected);
    setSelectedRows([]);
  };

  return (
    <>
      {
        !!selectedRows.length && (
          <Box p={2}>
            <Button
              className={classes.addButton}
              color='primary'
              variant='contained'
              size='large'
              onClick={handleAddPerson}
            >
              {t('Add selected to contact persons')}
            </Button>
          </Box>
        )
      }
      <MaterialTable
        data={getTableData(allPersons)}
        title=''
        columns={[
          { title: t('Salutation'), field: 'salutation' },
          { title: t('First Name'), field: 'firstName' },
          { title: t('Surname'), field: 'surname' },
          {
            title: t('Role'),
            field: 'role',
            render: (rowData) => rowData.role.join(', '),
          },
          { title: t('Department'), field: 'department' },
          { title: t('Phone'), field: 'phone' },
          { title: t('E-mail'), field: 'emails' },
        ]}
        options={{
          selection: true,
        }}
        onSelectionChange={(rows) => {
          setSelectedRows(rows.map((r) => r._id));
        }}
        minRows={0}
        localization={getLocalization(t)}
      />
    </>
  );
};

TabSelectTeam.propTypes = {
  allPersons: PropTypes.array.isRequired,
  contactPersonsIds: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default TabSelectTeam;
