import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/carriers';
const initialState = {
  carriers: {},
  carrier: null,
  loading: false,
  timestamp: null,
  search: ""
};

const carrierSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    resetList: (state, action) => {
      state.carriers = {};
      state.carrier = {};
      state.timestamp = null;
    },
    setCarrier: (state, action) => {
      state.carrier = action.payload;
    },
    setCarriers: (state, action) => {
      const carriers = {};
      action.payload.forEach(carrier => {
        carriers[carrier.id] = carrier;
      });
      state.carriers = carriers;
      state.timestamp = +new Date();
    },
    removeCarrier: (state, action) => {
      delete state.carriers[action.payload];
      state.carrier = {};
    },
    setCarrierLoading: (state) => {
      state.loading = true;
    },
    setCarrierReady: (state) => {
      state.loading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setCarrier,
  setCarriers,
  addNewCarrier,
  removeCarrier,
  setCarrierLoading,
  setCarrierReady,
  resetList,
  setSearch
} = carrierSlice.actions;
export default carrierSlice.reducer;

export const getCarrier = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCarrierLoading());

  try {
    const res = await coreApi.fetch(url);

    dispatch(setCarrier(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setCarrierReady());
  }
};

export const getCarriers = () => async dispatch => {
  dispatch(setCarrierLoading());

  try {
    const carriers = await coreApi.fetch(baseUrl);
    dispatch(setCarriers(carriers));

    return carriers;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setCarrierReady());
  }
};

export const addCarrier = (payload) => async dispatch => {
  dispatch(setCarrierLoading());

  try {
    const carrier = await coreApi.post(baseUrl, payload);

    if (carrier.error) {
      throw new Error('FAILED');
    }

    dispatch(resetList());
    dispatch(setShowMessage({
      description: 'Carrier Added Successfully!',
      type: 'success',
    }));

    return carrier;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding carrier. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCarrierReady());
  }
};

export const editCarrier = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCarrierLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res.error) {
      throw new Error('FAILED');
    }

    dispatch(resetList());
    dispatch(setShowMessage({
      description: 'Carrier Updated Successfully',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed editing carrier. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCarrierReady());
  }
};

export const deleteCarrier = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCarrierLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeCarrier(id));
      dispatch(setShowMessage({
        description: 'Carrier Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Carrier. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCarrierReady());
  }
};

const carrierSelector = ({ carriers }) => carriers.carrier;
const carriersSelector = ({ carriers }) => carriers.carriers;
const carrierStatusSelector = ({ carriers }) => carriers.loading;
const searchSelector = ({ carriers }) => carriers.search;

export const selectCarrier = createSelector(carrierSelector, carrier => carrier);
export const selectCarriers = createSelector(carriersSelector, carriers => Object.values(carriers));
export const selectCarrierStatus = createSelector(carrierStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ carriers }) => carriers.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
