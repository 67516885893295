import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Box, CircularProgress } from '@material-ui/core';

import { ORDER_TABLE_COLUMNS } from 'constants/ui-constants';
import { defaultTableOptions, getLocalization, getColumns, getActions } from 'util/table-utils';
import { PATHS } from 'util/appConstants';
import CopyOrdersForm from 'components/Orders/CopyOrdersForm';
import SearchForm from 'components/Orders/SearchForm';
import {
  selectOrders,
  selectOrderStatus,
  deleteOrder,
  fetchOrders,
  copyOrders,
  selectFilters,
  searchFilter,
  setSearch,
} from 'redux/slices/ordersSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import withConfirm from 'components/dialogs/delete';

const tableTitle = 'ORDERS';

const OrdersList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector(selectFilters);
  const loading = useSelector(selectOrderStatus);
  const orders = useSelector(selectOrders);
  const user = useSelector(selectUser);
  const search = useSelector(searchFilter);
  const [selection, setSelection] = useState([]);

  const fetch = async (params = filters) => {
    setSelection([]);

    await dispatch(fetchOrders(params));
  };

  useEffect(() => {
    if (!orders.length && !loading) {
      fetch();
    }
  }, [orders]);

  const mapTableData = (data) => {
    return data
      .map((o) => {
        return {
          ...o,
          firstArticleProcessorCashflow: o.firstArticleProcessorCashflow
            ? t(`${o.firstArticleProcessorCashflow} Processor`)
            : '',
          firstArticleSourceCashflow: o.firstArticleSourceCashflow
            ? t(`${o.firstArticleSourceCashflow} Source`)
            : '',
          tableData: {
            checked: selection.includes(o._id),
          },
        };
      });
  };

  const onCopySubmit = async ({ copies }) => {
    await dispatch(copyOrders({ copies, orders: selection }));

    if (filters.noStatus) {
      fetch();
    }

    setSelection([]);
  };

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(async () => {
      await dispatch(deleteOrder(rowData._id));
      fetch();
    }, {
      description: 'Are you sure?',
    });
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.orders.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );

  return (
    <div className={'custom-table-styles'}>
      <SearchForm initialValues={{ ...filters }} onSubmit={fetch} />
      {
        !!selection.length && (
          <CopyOrdersForm onSubmit={onCopySubmit} />
        )
      }
      {
        loading && (
          <Box p={2} mb={2} textAlign="center">
            <CircularProgress color="secondary" size={60} />
          </Box>
        )
      }
      <MaterialTable
        data={mapTableData(orders)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={getColumns(ORDER_TABLE_COLUMNS, t)}
        options={{
          ...defaultTableOptions,
          selection: true,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.orders.edit.replace(':id', rowData._id),
        )}
        localization={getLocalization(t)}
        onSelectionChange={(rows) => {
          setSelection(rows.map((r) => r._id));
        }}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />

    </div>
  );
};

export default withConfirm(OrdersList);
