import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import clsx from 'clsx';

// Actions
import { getBank } from 'redux/slices/bankSlice';
import { fetchPartnerById } from 'redux/slices/partnerSlice';
import { fetchPersonById } from 'redux/slices/personSlice';
import { fetchContractById, sendContractDocument } from 'redux/slices/contractsSlice';
import { getApiUrl } from 'util/environment-utils';
import axios from 'axios';

// Components
import { Grid, Paper, FormGroup, FormControlLabel, Checkbox, Button, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import OnContractAddPopup from 'components/Contracts/onContractAddPopup';
import PreviewContractDocument from 'components/Modals/previewContractDocument';
import download from 'downloadjs';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 12,
    height: '100%',
    width: '100%',
    marginRight: '25px',
  },
  formGroup: {
    display: 'inline',
    margin: 10,
  },
  signedContractText: {
    margin: '0 20px',
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  dropzoneGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteIcon: {
    color: 'red',
    backgroundColor: 'white',
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));


function SentDocDate({ name, date, component }) {
  return (
    <p className={'flex ml-12 align-center'}>
      {`${name} (${moment(date).format('DD.MM.YYYY')})`}&nbsp; {component}
    </p>
  );
}

// contract = current contract when editing
const SendDocs = ({
  contract,
  required = false,
  createContractDocumentProps,
  userSettings,
  companies,
}) => {
  const { t, i18n: { language } } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({ sales: { status: false, date: null } });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [sendPopupOpen, setSendPopupOpen] = useState(false);
  const [signedContractName, setSignedContractName] = useState('');
  const [sendOrderDocument, setSendOrderDocument] = useState(false);

  useEffect(() => {
    if (!!contract && !loading && !data.bankDetails) {
      getInfo();
    }
  }, []);

  function handleCheckboxesChange(event) {
    let values = checked;
    values[event.target.name]['status'] = event.target.checked;
    setChecked({ ...values });
  }

  function checkIfDocAlreadySent(name) {
    return (
      !!contract &&
      contract.docStatus &&
      contract.docStatus[name] &&
      contract.docStatus[name]['status']
    );
  }

  function docSentDate(name) {
    let date = false;

    if (
      contract.docStatus &&
      contract.docStatus[name] &&
      contract.docStatus[name]['date']
    ) {
      date = moment(contract.docStatus[name]['date']).format('DD.MM.YYYY');
    }

    return date ? `(${date})` : date;
  }

  function isDisabled() {
    return ((checked || {}).sales || {}).status || false;
  }

  const getInfo = async () => {
    setLoading(true);
    try {
      const bankDetails = await dispatch(getBank(contract.bankId));
      let buyerPartner = await dispatch(fetchPartnerById(contract.buyerId));
      let commercials = null;

      if (contract.commercialId) {
        commercials = await dispatch(fetchPersonById(contract.commercialId));
      }

      setData({
        bankDetails,
        company: companies,
        commercials,
        buyerPartner,
        contract,
      });
    } catch (err) {
      console.log(err);
    }

    setChecked({
      sales: { status: false, date: null },
    });

    if (contract.docStatus && contract.docStatus.length > 0) {
      setChecked({ ...checked, ...contract.docStatus });
    }
    setLoading(false);
  };

  const handleDropContractors = (files) => {
    let data = new FormData();
    let BASE_URL = getApiUrl();
    data.append('files', files[0]);

    axios.post(`${BASE_URL}/contracts/upload`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(
      (res) => {
        let fileName = res.data.fileName;
        setSignedContractName(fileName);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const handleSignedContractDownload = (name) => {
    let BASE_URL = getApiUrl();
    axios({
      url: `${BASE_URL}/contracts/download-file/${name}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then((response) => {
      download(response.data, name);
    });
  };

  if (!contract) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <Grid container spacing={3} className={'ml-0 mt-16 mb-16'}>
      <Paper className={classes.paper}>
        <Grid container spacing={3} style={{ width: '100%' }} className='ml-0 mt-16 mb-16'>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            {!checkIfDocAlreadySent('sales') ? (
              <div className='flex align-center'>
                <FormGroup row className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(checked.sales && checked.sales.status) || false}
                        onChange={handleCheckboxesChange}
                        name={'sales'}
                        disabled={((contract || {}).articles || []).length === 0}
                      />
                    }
                    label={`${t('Sales Contract')}${required ? ' *' : ''} ${
                      docSentDate('sales') ? docSentDate('sales') : ''
                    }`}
                  />
                </FormGroup>
                <div className='preview'>
                  <PreviewContractDocument
                    data={data}
                    contract={contract}
                    {...createContractDocumentProps(contract)}
                  />
                </div>
              </div>
            ) : (
              <SentDocDate
                name={t('Sales Contract')}
                date={contract.docStatus.sales.date}
                component={
                  <PreviewContractDocument
                    data={data}
                    contract={contract}
                    {...createContractDocumentProps(contract)}
                  />
                }
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<SaveIcon />}
              style={{
                margin: 10,
              }}
              disabled={!isDisabled()}
              onClick={() => setSendPopupOpen(true)}
            >
              {t('Send')}
            </Button>
          </Grid>

          <Grid className={classes.dropzoneGrid} item xs={12} sm={12} md={3} lg={9}>
            <p className={classes.signedContractText}>{t('Signed Contract')}:</p>
            <Dropzone
              onDrop={(e) => handleDropContractors(e)}
              maxSize={10 * 1024 * 1024}
              multiple={false}
              onDropRejected={() => alert(t('file_size_limit'))}
              style={{ display: 'flex' }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className={clsx('dropzone-container', classes.dropzoneContainer)}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop images here</p>
                  </div>
                  {signedContractName && (
                    <React.Fragment>
                      <aside className='display-flex align-center'>
                        <ul className='display-flex' style={{ margin: 0 }}>
                          <div className='display-flex'>
                            <li className='list-style-none'>
                              <div className='display-flex align-center justify-space-between-between'>
                                <p
                                  className='file-link'
                                  onClick={() => handleSignedContractDownload(signedContractName)}
                                >
                                  {signedContractName}
                                </p>
                              </div>
                            </li>
                          </div>
                        </ul>
                        <>
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() => setSignedContractName(null)}
                          />
                        </>
                      </aside>
                    </React.Fragment>
                  )}
                </section>
              )}
            </Dropzone>
          </Grid>
        </Grid>
      </Paper>

      {sendPopupOpen && (
        <OnContractAddPopup
          data={data}
          closeDialogCancel={async (reset) => {
            reset();
            setSendPopupOpen(false);
          }}
          closeDialogOk={async (data, reset) => {
            reset();
            setLoading(true);
            await dispatch(sendContractDocument(contract._id, data, language));

            setTimeout(() => {
              dispatch(fetchContractById(contract._id));
              setLoading(false);
              setSendPopupOpen(false);
            }, 300);
          }}
          loading={loading}
          setLoading={setLoading}
          checked={checked}
          contract={contract}
          checkIfDocAlreadySent={checkIfDocAlreadySent}
          sendOrderDocument={sendOrderDocument}
          sendPopupOpen={sendPopupOpen}
          userSettings={userSettings}
          companies={companies}
          createContractDocumentProps={createContractDocumentProps}
        />
      )}
    </Grid>
  );
};

export default SendDocs;
