import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';
import moment from 'moment';

const baseUrl = '/orders';
const initialState = {
  filters: {
    yellow: 1,
    orange: 1,
    green: 0,
    checkbox: 1,
    noStatus: 1,
    onlyAllStatuses: '0',
    created_from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    created_to: null,
  },
  orders: {},
  order: null,
  orderLocations: null,
  loading: false,
  search: ""
};

const ordersSlice = createSlice({
  name: 'newOrders',
  initialState,
  reducers: {
    setCurrentOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderLocations: (state, action) => {
      state.orderLocations = action.payload;
    },
    setOrdersLoading: state => {
      state.loading = true;
    },
    setOrdersReady: state => {
      state.loading = false;
    },
    resetOrders: (state, action) => {
      state.orders = {};
      state.order = null;
      // state.filters = initialState.filters;
    },
    setOrders: (state, { payload }) => {
      state.orders = payload.reduce((acc, cur) => {
        acc[cur._id] = cur;

        return acc;
      }, {});
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setCurrentOrder,
  setOrdersLoading,
  setOrdersReady,
  setOrders,
  resetOrders,
  setFilters,
  setSearch
} = ordersSlice.actions;
export default ordersSlice.reducer;

export const fetchOrderById = id => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setOrdersLoading());

  try {
    const res = await coreApi.fetch(url);

    dispatch(setCurrentOrder(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setOrdersReady());
  }
};

export const fetchOrders = (filters = {}) => async (dispatch) => {
  dispatch(setOrdersLoading());

  try {
    const data = await coreApi.fetch(baseUrl, 'GET', {}, null, filters);

    dispatch(setOrders(data));
    dispatch(setFilters(filters));

    return data;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setOrdersReady());
  }
};

export const fetchOrderLocations = id => async dispatch => {
  const url = baseUrl + `/get_locations/${id}`;

  try {
    const res = await coreApi.fetch(url);

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const copyOrders = (payload) => async (dispatch) => {
  dispatch(setOrdersLoading());

  try {
    const res = await coreApi.fetch(`${baseUrl}/copy`, 'POST', payload);

    dispatch(setShowMessage({
      description: 'The orders were copied successfully',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed to copy the orders',
      type: 'error',
    }));
  } finally {
    dispatch(setOrdersReady());
  }
};

export const editOrder = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setOrdersLoading());

  try {
    const res = await coreApi.fetch(url, 'PUT', payload);

    dispatch(resetOrders());
    dispatch(setShowMessage({
      description: 'Order edited successfully!',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed editing Order. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setOrdersReady());
  }
};

export const deleteOrder = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setOrdersLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(resetOrders());
      dispatch(setShowMessage({
        description: 'Order Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Order. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setOrdersReady());
  }
};

export const createOrder = (payload) => async dispatch => {
  dispatch(setOrdersLoading());

  try {
    const order = await coreApi.post(baseUrl, payload);
    dispatch(resetOrders());
    dispatch(setShowMessage({
      description: 'Order created successfully',
      type: 'success',
    }));

    return order;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed to create order',
      type: 'error',
    }));
  } finally {
    dispatch(setOrdersReady());
  }
};

export const sendDocuments = (id, data, language) => async (dispatch) => {
  try {
    await coreApi.post(`${baseUrl}/senddocument/${id}/${language}`, data);

    dispatch(setShowMessage({
      description: 'Sent Successfully',
      type: 'success',
    }));
    dispatch(resetOrders());
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed to send documents',
      type: 'error',
    }));
  }
};

export const correctInvoice = (data) => async (dispatch) => {
  try {
    await coreApi.post(`${baseUrl}/correct/invoice`, data);

    dispatch(setShowMessage({
      description: 'Correction created',
      type: 'success',
    }));
    dispatch(resetOrders());
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed to create correction',
      type: 'error',
    }));
  }
};

export const datev = (params) => async (dispatch) => {
  try {
    dispatch(setOrdersLoading());
    await coreApi.fetch('/datev/invoices', 'GET', null, null, params);

    dispatch(resetOrders());
  } finally {
    dispatch(setOrdersReady());
  }
};

const currentOrderSelector = ({ newOrders }) => newOrders.order;
const orderLocationsSelector = ({ newOrders }) => newOrders.orderLocations;
const orderStatusSelector = ({ newOrders }) => newOrders.loading;
const ordersSelector = ({ newOrders }) => newOrders.orders;
const filtersSelector = ({ newOrders }) => newOrders.filters;
const searchSelector = ({ newOrders }) => newOrders.search;

export const selectCurrentOrder = createSelector(currentOrderSelector, order => order);
export const selectOrderLocations = createSelector(orderLocationsSelector, orderLocations => orderLocations);
export const selectOrderStatus = createSelector(orderStatusSelector, loading => loading);
export const selectOrders = createSelector(ordersSelector, orders => Object.values(orders));
export const selectFilters = createSelector(filtersSelector, f => f);
export const searchFilter = createSelector(searchSelector, search => search);
