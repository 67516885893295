import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from 'util/appConstants';
import { selectArticleStatus, addArticle } from 'redux/slices/articleSlice';
import ArticleForm from 'components/System/Articles/form';


const currentAction = 'ADD';

const AddArticle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectArticleStatus);

  const handleAddArticle = async (payload) => {
    await dispatch(addArticle(payload));

    history.push(PATHS.system.articles.root);
  };

  if (loading) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <ArticleForm
      onSubmit={handleAddArticle}
      action={currentAction}
    />
  );
};

export default AddArticle;
