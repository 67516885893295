import { createSlice, createSelector } from '@reduxjs/toolkit';
import { setShowMessage } from 'redux/slices/uiSlice';
import { coreApi } from 'api/core';

const baseUrl = '/persons';

const initialState = {
  allPersons: {},
  allTeamMembers: {},
  buyerContractPersons: {},
  accountManagers: {},
  currentPerson: null,
  currentTeamMember: null,
  loading: false,
  accountManagersLoading: false,
  accountManagersTimestamp: null,
  search: ""
};

const personSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    setAllPersons: (state, action) => {
      const allPersons = {};
      action.payload.forEach(person => {
        allPersons[person._id] = person;
      });
      state.allPersons = allPersons;
    },
    setAllTeamMembers: (state, action) => {
      const allTeamMembers = {};
      action.payload.forEach(teamMember => {
        allTeamMembers[teamMember._id] = teamMember;
      });
      state.allTeamMembers = allTeamMembers;
    },
    setContractPersons: (state, action) => {
      const buyerContractPersons = {};
      action.payload.forEach(contractPerson => {
        buyerContractPersons[contractPerson._id] = contractPerson;
      });
      state.buyerContractPersons = buyerContractPersons;
    },
    setAccountManagers: (state, { payload }) => {
      state.accountManagers = payload.reduce((acc, cur) => {
        acc[cur._id] = cur;

        return acc;
      }, {});
      state.accountManagersTimestamp = +new Date();
    },
    setCurrentTeamMember: (state, action) => {
      state.currentTeamMember = action.payload;
    },
    clearCurrentTeamMember: (state) => {
      state.currentTeamMember = null;
    },
    setCurrentPerson: (state, action) => {
      state.currentPerson = action.payload;
    },
    clearCurrentPerson: (state) => {
      state.currentPerson = null;
    },
    setUpdatedPerson: (state, action) => {
      const { id } = action.payload;
      state.allPersons[id] = action.payload;
      state.currentPerson = action.payload;
    },
    setDeletePerson: (state, action) => {
      delete state.allPersons[action.payload];
    },
    setPersonLoading: state => {
      state.loading = true;
    },
    setPersonReady: state => {
      state.loading = false;
    },
    setAccountManagerLoading: state => {
      state.accountManagersLoading = true;
    },
    setAccountManagerReady: state => {
      state.accountManagersLoading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setAllPersons,
  setAllTeamMembers,
  setContractPersons,
  setCurrentPerson,
  clearCurrentPerson,
  setCurrentTeamMember,
  clearCurrentTeamMember,
  setUpdatedPerson,
  setDeletePerson,
  setPersonLoading,
  setPersonReady,
  setAccountManagers,
  setAccountManagerLoading,
  setAccountManagerReady,
  setSearch
} = personSlice.actions;
export default personSlice.reducer;

export const fetchPersonById = id => async dispatch => {
  dispatch(setPersonLoading());
  const url = baseUrl + `/${id}`;

  try {
    const person = await coreApi.fetch(url);

    if (person.type === 'Intern') {
      dispatch(setCurrentTeamMember(person));
    }
    if (person.type === 'Extern') {
      dispatch(setCurrentPerson(person));
    }

    return person;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchAllPersons = () => async dispatch => {
  dispatch(setPersonLoading());

  try {
    const persons = await coreApi.fetch(baseUrl);
    dispatch(setAllPersons(persons));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchAllTeamMembers = () => async dispatch => {
  dispatch(setPersonLoading());
  const url = baseUrl + '/teams';

  try {
    const teamMembers = await coreApi.fetch(url);
    dispatch(setAllTeamMembers(teamMembers));

    return teamMembers;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchAccountManagers = () => async dispatch => {
  dispatch(setAccountManagerLoading());

  try {
    const res = await coreApi.fetch(`${baseUrl}/account-managers`);

    dispatch(setAccountManagers(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setAccountManagerReady());
  }
};

export const fetchContractPersons = partnerId => async dispatch => {
  const url = baseUrl + `/contracts/persons/${partnerId}`;
  dispatch(setPersonLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setContractPersons(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchContractUsers = id => async dispatch => {
  dispatch(setPersonLoading());
  const url = baseUrl + `/doc/contracts/email/users/${id}`;

  try {
    const res = await coreApi.fetch(url);

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchEmailUsers = (data) => async dispatch => {
  dispatch(setPersonLoading());

  try {
    const res = await coreApi.fetch(`${baseUrl}/doc/email/users`, 'POST', data);

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPersonReady());
  }
};

export const addPerson = payload => async dispatch => {
  dispatch(setPersonLoading());

  try {
    const res = await coreApi.post(baseUrl, payload);

    if (res.error) {
      return dispatch(setShowMessage({
        description: res.error ?? 'Failed Adding Person. Missing required information.',
        type: 'error',
      }));
    }

    dispatch(setShowMessage({
      description: 'Person Added Successfully!',
      type: 'success',
    }));

    return res;
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Adding Person. Please try again later.',
      type: 'error',
    }));
  } finally {
    dispatch(setPersonReady());
    await fetchAllPersons()(dispatch);
  }
};

export const editPerson = (id, payload) => async dispatch => {
  dispatch(setPersonLoading());
  const url = baseUrl + `/${id}`;

  try {
    const person = { ...payload };
    delete person.documents;
    delete person.tile0;

    const res = await coreApi.put(url, person);

    if (res.error) {
      return dispatch(setShowMessage({
        description: res.error ?? 'Failed Updating Person. Missing required information.',
        type: 'error',
      }));
    }

    dispatch(setUpdatedPerson({ ...person, id }));
    dispatch(setShowMessage({
      description: 'Person Updated Successfully!',
      type: 'success',
    }));

    return res;
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Updating Person. Please try again later.',
      type: 'error',
    }));
  } finally {
    dispatch(setPersonReady());
  }
};

export const deletePerson = (personId, partnerId) => async dispatch => {
  dispatch(setPersonLoading());
  const url = baseUrl + `/${personId}`;

  try {
    await coreApi.delete(url);

    if (!partnerId) {
      dispatch(fetchAllTeamMembers());
    }

    dispatch(setDeletePerson(personId));
    dispatch(setShowMessage({
      description: 'Person Deleted Successfully!',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Person. Please try again later.',
      type: 'error',
    }));
  } finally {
    dispatch(setPersonReady());
  }
};

export const fetchTeams = () => async dispatch => {
  const url = baseUrl + '/teams';

  try {
    return await coreApi.fetch(url);
  } catch (err) {
    console.log(err);
  }
};

const currentPersonSelector = ({ newPersons }) => newPersons.currentPerson;
const currentTeamMemberSelector = ({ newPersons }) => newPersons.currentTeamMember;
const allPersonsSelector = ({ newPersons }) => newPersons.allPersons;
const buyerContractPersonsSelector = ({ newPersons }) => newPersons.buyerContractPersons;
const allTeamMembersSelector = ({ newPersons }) => newPersons.allTeamMembers;
const personLoadingSelector = ({ newPersons }) => newPersons.loading;
const accountManagerSelector = ({ newPersons }) => newPersons.accountManagers;
const searchSelector = ({ newPersons }) => newPersons.search;

export const selectCurrentPerson = createSelector(currentPersonSelector, currentPerson => currentPerson);
export const selectCurrentTeamMember = createSelector(currentTeamMemberSelector, currentTeamMember => currentTeamMember);
export const selectAllPersons = createSelector(allPersonsSelector, allPersons => Object.values(allPersons));
export const selectBuyerContractPersons = createSelector(buyerContractPersonsSelector, buyerContractPersons => Object.values(buyerContractPersons));
export const selectAllTeamMembers = createSelector(allTeamMembersSelector, allTeamMembers => Object.values(allTeamMembers));
export const selectPersonLoading = createSelector(personLoadingSelector, loading => loading);
export const selectAccountManagers = createSelector(accountManagerSelector, am => Object.values(am));
export const selectAccountManagerStatus = createSelector(({ newPersons }) => newPersons.accountManagersLoading, l => l);
export const selectAccountManagerTimestamp = createSelector(({ newPersons }) => newPersons.accountManagersTimestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
