import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/companies';
const initialState = {
  companies: {},
  company: null,
  loading: false,
  timestamp: null,
  search: ""
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    addNewCompany: (state, action) => {
      const { _id } = action.payload;
      state.companies[_id] = action.payload;
      state.company = action.payload;
    },
    setEditCompany: (state, action) => {
      const { id } = action.payload;
      state.companies[id] = {
        ...state.companies[id],
        ...action.payload,
      };
      state.company = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    resetCompanies: (state) => {
      state.companies = {};
      state.timestamp = null;
    },
    setCompanies: (state, action) => {
      const companies = {};
      action.payload.forEach(company => {
        companies[company._id] = company;
      });
      state.companies = companies;
      state.timestamp = +new Date();
    },
    removeCompany: (state, action) => {
      delete state.companies[action.payload];
    },
    setCompanyLoading: (state) => {
      state.loading = true;
    },
    setCompanyReady: (state) => {
      state.loading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setCompany,
  setCompanies,
  addNewCompany,
  setEditCompany,
  removeCompany,
  setCompanyLoading,
  setCompanyReady,
  resetCompanies,
  setSearch
} = companySlice.actions;
export default companySlice.reducer;

export const getCompany = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCompanyLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setCompany(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setCompanyReady());
  }
};

export const getCompanies = () => async dispatch => {
  dispatch(setCompanyLoading());

  try {
    const companies = await coreApi.fetch(baseUrl);
    dispatch(setCompanies(companies));

    return companies;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setCompanyReady());
  }
};

export const addCompany = (payload) => async dispatch => {
  dispatch(setCompanyLoading());

  try {
    const company = await coreApi.post(baseUrl, payload);
    dispatch(resetCompanies());
    dispatch(setShowMessage({
      description: 'Company Added Successfully!',
      type: 'success',
    }));

    return company;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding company. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCompanyReady());
  }
};

export const editCompany = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCompanyLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res) {
      dispatch(setEditCompany({ ...payload, id }));
      dispatch(resetCompanies());
      dispatch(setShowMessage({
        description: 'Company Updated Successfully',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed updating company. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCompanyReady());
  }
};

export const deleteCompany = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setCompanyLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeCompany(id));
      dispatch(setShowMessage({
        description: 'Company Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Company. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setCompanyReady());
  }
};

const companySelector = ({ companies }) => companies.company;
const companiesSelector = ({ companies }) => companies.companies;
const companyStatusSelector = ({ companies }) => companies.loading;
const searchSelector = ({ companies }) => companies.search;

export const selectCompany = createSelector(companySelector, company => company);
export const selectCompanies = createSelector(companiesSelector, companies => Object.values(companies));
export const selectCompanyStatus = createSelector(companyStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ companies }) => companies.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);

