import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';

// Helpers
import { CARRIER_TABLE_COLUMNS } from 'constants/ui-constants';
import { defaultTableOptions, getLocalization, getColumns, getActions, mapTableData } from 'util/table-utils';
import { PATHS } from 'util/appConstants';

// Actions
import {
  selectCarriers,
  selectCarrierStatus,
  getCarriers,
  deleteCarrier,
  searchFilter,
  setSearch
} from 'redux/slices/carrierSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'CARRIERS';

const CarriersList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectCarrierStatus);
  const carriers = useSelector(selectCarriers);
  const user = useSelector(selectUser);
  const search = useSelector(searchFilter);

  const fetchCarriers = useCallback(async () => {
    return await dispatch(getCarriers());
  }, [dispatch, carriers]);

  useEffect(() => {
    if (!carriers.length && !loading) {
      fetchCarriers();
    }
  }, [carriers]);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(
      () => dispatch(deleteCarrier(rowData._id)),
      {
        description: 'Are you sure?',
      },
    );
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.carriers.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );

  if (loading) return <div>Loading..</div>;
  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapTableData(carriers)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={getColumns(CARRIER_TABLE_COLUMNS, t)}
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.carriers.edit.replace(':id', rowData.id),
        )}
        localization={getLocalization(t)}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(CarriersList);
