import { hasPermission } from 'util/user-utils';
import { PERMISSIONS_CREATE, PERMISSIONS_DELETE } from 'constants/ui-constants';

export const pageSizeOptions = [100, 200, 300, 400, 500];

/**
 * Map redux data to a new array compatible
 * with material-table props.
 * https://stackoverflow.com/questions/65396030/using-material-table-with-redux-state
 * @param data
 */
export const mapTableData = (data) => {
  if (!data || !data.length) return [];

  return data.map(item => Object.assign({}, item));
};

export const getColumns = (columns, t) => {
  let updatedColumns = [];
  for (let column of columns) {
    updatedColumns.push({
      ...column,
      title: t(column.title),
    });
  }

  return updatedColumns;
};

export const getLocalization = (t) => {
  return {
    pagination: {
      labelRowsSelect: t('rows'),
      labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
    },
    toolbar: {
      nRowsSelected: `{0} ${t('row(s)')} ${t('selected')}`,
      searchTooltip: t('Search'),
      searchPlaceholder: t('Search'),
    },
    header: {
      actions: t('Actions'),
    },
    body: {
      emptyDataSourceMessage: t('No records to display'),
      filterRow: {
        filterTooltip: t('Filter'),
      },
    },
  };
};

export const getPageSize = (data) => {
  if (!data) return 10;
  let finalRange = 100;

  const dataLength = data.length.toString();

  if (dataLength.length === 2) {
    const range = parseInt(dataLength[0]) * 10;
    if (parseInt(dataLength) > range) {
      finalRange = parseInt(dataLength[0]) + 10;
    } else {
      finalRange = range;
    }
  } else if (dataLength.length < 2) {
    finalRange = 10;
  }

  return finalRange;
};

export const defaultTableOptions = {
  selection: false,
  pageSize: 100,
  pageSizeOptions,
  actionsColumnIndex: -1,
  emptyRowsWhenPaging: false
};

export const getActions = (user, tableTitle, callbackOnAdd, callbackOnDelete) => {
  if (!user) return;
  let actions = [];

  if (!PERMISSIONS_CREATE[tableTitle] || hasPermission(user, PERMISSIONS_CREATE[tableTitle])) {
    actions.push({
      icon: 'add',
      tooltip: 'Add',
      isFreeAction: true,
      onClick: callbackOnAdd,
    });
  }

  if (!PERMISSIONS_DELETE[tableTitle] || hasPermission(user, PERMISSIONS_DELETE[tableTitle])) {
    actions.push({
      icon: 'delete',
      tooltip: 'Delete',
      position: 'row',
      onClick: callbackOnDelete,
    });
  }

  return actions;
};
