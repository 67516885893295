import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Helpers
import { useAuthUser } from 'hooks';
import { defaultTableOptions, getActions, getLocalization, mapTableData } from 'util/table-utils';
import { PATHS } from 'util/appConstants';

// Actions
import { selectPersonLoading, selectAllTeamMembers, deletePerson, fetchAllTeamMembers, searchFilter, setSearch } from 'redux/slices/personSlice';

// Components
import MaterialTable from 'material-table';
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'TEAMS';

const TeamsList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useAuthUser();
  const teamMembers = useSelector(selectAllTeamMembers);
  const loading = useSelector(selectPersonLoading);
  const search = useSelector(searchFilter);

  useEffect(() => {
    dispatch(fetchAllTeamMembers());
  }, []);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(
      () => {
        dispatch(deletePerson(rowData._id));
      },
      {
        description: 'Are you sure?',
      },
    );
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.system.team.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );

  const mapAndTranslateData = (teamMembers) => {
    const personData = mapTableData(teamMembers);
    let updatedPersons = [];

    for (let d of personData) {
      if (d.role) {
        let roles = [];
        for (let role of d.role) {
          roles.push(t(role));
        }

        d.salutation = t(d.salutation);
        d.role = roles;
      }

      if (d.itemType) {
        d.itemType = t(d.itemType);
      }

      updatedPersons.push(d);
    }

    return updatedPersons;
  };

  if (loading) return <div>Loading..</div>;

  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapAndTranslateData(teamMembers)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={[
          { title: t('Salutation'), field: 'salutation' },
          { title: t('First Name'), field: 'firstName' },
          { title: t('Surname'), field: 'surname' },
          {
            title: t('Role'),
            field: 'role',
            render: (rowData) =>
              rowData.role ? rowData.role.join(', ') : rowData.role,
          },
          { title: t('Department'), field: 'department' },
          { title: t('Phone'), field: 'phone' },
          { title: t('E-mail'), field: 'emails' },
        ]}
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.system.team.edit.replace(':id', rowData._id),
        )}
        localization={getLocalization(t)}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(TeamsList);
