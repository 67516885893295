import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import {
  usePartners,
  useDeviatingPartners,
  useCarriers,
  useArticles,
  useAccountManagers,
  useWarehouses,
} from 'hooks';

import {
  selectOrderStatus,
  fetchOrderLocations,
  editOrder,
  fetchOrderById,
  selectCurrentOrder,
  sendDocuments,
  correctInvoice,
} from 'redux/slices/ordersSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import OrdersForm from 'components/Orders/Form';
import ErrorBoundary from 'components/Shared/ErrorBoundary';

const OrderEdit = () => {
  const [tab, setTab] = React.useState(0);
  const { id } = useParams();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectOrderStatus);
  const fullOrderResponse = useSelector(selectCurrentOrder);
  const user = useSelector(selectUser);
  const { partners, loading: partnersLoading } = usePartners();
  const { deviatings, loading: deviatingsLoading } = useDeviatingPartners();
  const { carriers, loading: carriersLoading } = useCarriers();
  const { articles, loading: articlesLoading } = useArticles();
  const { warehouses, loading: warehousesLoading } = useWarehouses();
  const { accountManagers, loading: amLoading } = useAccountManagers();
  const [sourceLocations, setSourceLocations] = useState([]);
  const [processorLocations, setProcessorLocations] = useState([]);

  const depsLoading = partnersLoading || deviatingsLoading || carriersLoading || articlesLoading || warehousesLoading || amLoading;

  const onSubmit = async (values) => {
    await dispatch(editOrder(id, values));

    dispatch(fetchOrderById(id));
  };

  const onSendDocuments = async (values, language) => {
    await dispatch(sendDocuments(id, values, language));
    dispatch(fetchOrderById(id));
  };

  const onCreateCorrection = async (values) => {
    await dispatch(correctInvoice(values));

    dispatch(fetchOrderById(id));
  };

  const onSourceChange = async (selected) => {
    if (!selected) {
      return;
    }

    const data = await dispatch(fetchOrderLocations(selected._id));

    setSourceLocations(data);
  };

  const onProcessorChange = async (selected) => {
    if (!selected) {
      return;
    }

    const data = await dispatch(fetchOrderLocations(selected._id));

    setProcessorLocations(data);
  };

  useEffect(() => {
    if ((!fullOrderResponse || fullOrderResponse.order._id !== id) && id && !loading) {
      dispatch(fetchOrderById(id));
    }
  }, [id]);

  useEffect(() => {
    if (fullOrderResponse) {
      if (fullOrderResponse.order.source) {
        onSourceChange(fullOrderResponse.order.source[0]);
      }

      if (fullOrderResponse.order.processor) {
        onProcessorChange(fullOrderResponse.order.processor[0]);
      }
    }
  }, [fullOrderResponse]);

  if (loading || !fullOrderResponse || depsLoading) {
    return (
      <div>Loading...</div>
    );
  }

  if (
    !partners.length ||
    !deviatings.length ||
    !carriers.length ||
    !articles.length ||
    !accountManagers.length
  ) {
    return (
      <Alert severity="info">
        {t('order_missing_info_message')}
      </Alert>
    );
  };

  const { order, companyOfficer, tax } = fullOrderResponse;
  const objID = (obj) => obj && obj.length ? obj[0]?._id : '';

  return (
    <ErrorBoundary message={t("order_loading_error")}>
      <OrdersForm
        onSubmit={onSubmit}
        onSendDocuments={onSendDocuments}
        onCreateCorrection={onCreateCorrection}
        onSourceChange={onSourceChange}
        onProcessorChange={onProcessorChange}
        partners={partners}
        deviatings={deviatings}
        carriers={carriers}
        articles={articles}
        warehouses={warehouses}
        accountManagers={accountManagers}
        sourceLocations={sourceLocations}
        processorLocations={processorLocations}
        showTabs={true}
        companyOfficer={companyOfficer}
        tax={tax}
        user={user}
        tab={tab}
        setTab={setTab}
        order={{
          ...order,
          source: order.source[0] || null,
          carrier: order.carrier[0] || null,
          processor: order.processor[0] || null,
          deviatingInvoiceAddressSource: order.deviatingInvoiceAddressSource[0] || null,
          deviatingInvoiceAddressProcessor: order.deviatingInvoiceAddressProcessor[0] || null,
          fieldAgent: order.fieldAgent[0] || null,
          accountManagerProcessor: order.accountManagerProcessor[0] || null,
        }}
        initialValues={{
          ...order,
          accountManagerProcessor: objID(order.accountManagerProcessor),
          fieldAgent: objID(order.fieldAgent),
          carrier: objID(order.carrier),
          source: objID(order.source),
          processor: objID(order.processor),
          deviatingInvoiceAddressSource: objID(order.deviatingInvoiceAddressSource),
          deviatingInvoiceAddressProcessor: objID(order.deviatingInvoiceAddressProcessor),
          commissionArticles: order.commissionArticles.map((ca) => ({
            ...ca,
            commissionPartner: ca.commissionPartner ? ca.commissionPartner._id : '',
          })),
          weightslips: order.weightslips && order.weightslips.length
            ? {
              carrierEntries: (order.weightslips[0].carrierEntries || []).concat({ invoiceDate: '', invoiceNumber: '', files: [] }),
              processorEntries: (order.weightslips[0].processorEntries || []).concat({ invoiceDate: '', invoiceNumber: '', files: [] }),
              sourceEntries: (order.weightslips[0].sourceEntries || []).concat({ invoiceDate: '', invoiceNumber: '', files: [] }),
            }
            : {
              carrierEntries: [{ invoiceDate: '', invoiceNumber: '', files: [] }],
              processorEntries: [{ invoiceDate: '', invoiceNumber: '', files: [] }],
              sourceEntries: [{ invoiceDate: '', invoiceNumber: '', files: [] }],
            }
        }}
      />
    </ErrorBoundary>
  );
};

export default OrderEdit;
