import useBaseHook from './useBaseHook';
import { selectWarehouses, getWarehouses, selectWarehouseStatus, selectTimestamp } from 'redux/slices/warehouseSlice';

const useWarehouses = () => {
  const {
    records: warehouses,
    loading,
    revalidate,
  } = useBaseHook({
    fetchRecords: getWarehouses,
    timestampSelector: selectTimestamp,
    loadingSelector: selectWarehouseStatus,
    recordsSelector: selectWarehouses,
  });

  return { warehouses, loading, revalidate };
};

export default useWarehouses;
