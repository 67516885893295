import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, fetchUserInfo } from 'redux/slices/userSlice';

const useAuthUser = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    if (!currentUser) {
      const getAuthUser = async () => {
        return await dispatch(fetchUserInfo());
      };

      return getAuthUser();
    }
  }, [currentUser]);

  return currentUser;
};

export default useAuthUser;
