import React from 'react';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'components/Shared/mui-formik-inputs';
import { ArticleFormAllowedFields, ArticleSchema } from './constants';

const ArticlesForm = ({ onSubmit, initialValues, action }) => {
  const { t } = useTranslation('common');

  const formik = useFormik({
    initialValues: merge({
      itemType: 'Service',
      descriptionInGerman: '',
      descriptionInEnglish: '',
      hsCode: '',
      basalAnnexIX: '',
      basalCodeEu: '',
      avv: '',
      descriptionOfWaste: '',
    }, initialValues || {}),
    validationSchema: ArticleSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(pick(values, ArticleFormAllowedFields));
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, handleChange, handleSubmit, submitCount } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <Box boxShadow={3} p={2}>
      <Typography variant='h4'>{t('Article Information')}</Typography>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <Select
                name="itemType"
                value={values.itemType}
                label={'Article type'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                options={[
                  { label: t('Service'), value: 'Service' },
                  { label: t('Waste'), value: 'Waste' },
                  { label: t('Product'), value: 'Product' },
                ]}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="descriptionInGerman"
                value={values.descriptionInGerman}
                label={'Description in German'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="descriptionInEnglish"
                value={values.descriptionInEnglish}
                label={'Description in English'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="hsCode"
                value={values.hsCode}
                label={'HS Code'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required={values.itemType !== 'Service'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="basalCodeEu"
                value={values.basalCodeEu}
                label={'Basel Code EU'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required={values.itemType === 'Waste'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="basalAnnexIX"
                value={values.basalAnnexIX}
                label={'Basel Code Non EU'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required={values.itemType === 'Waste'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="avv"
                value={values.avv}
                label={'AVV Number'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required={values.itemType === 'Waste'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Input
                name="descriptionOfWaste"
                value={values.descriptionOfWaste}
                label={'Usual desc of the waste'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
            type='submit'
          >
            {t('Article') + ' ' + t(`${action}`)}
          </Button>
        </form>
    </Box>
  );
};

export default ArticlesForm;
