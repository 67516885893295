import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Helpers
import { useBanks, usePartners, useCompanies, useHarbors } from 'hooks';
import { defaultValues } from 'components/Contracts/constants';
import { PATHS } from 'util/appConstants';

// Actions
import {
  editContract,
  selectCurrentContract,
  fetchContractById,
  selectContractStatus,
} from 'redux/slices/contractsSlice';
import {
  selectBuyerContractPersons,
  selectAllTeamMembers,
  fetchContractPersons,
  fetchAllTeamMembers,
} from 'redux/slices/personSlice';
import { selectUserSettings } from 'redux/slices/userSlice';

// Components
import ContractForm from 'components/Contracts/ContractForm';
import SendDocs from 'components/Contracts/SendDocs';

const EditContract = () => {
  const { i18n: { language } } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: contractId } = useParams();
  const { banks } = useBanks();
  const { partners } = usePartners();
  const { companies } = useCompanies();
  const { harbors } = useHarbors();
  const loading = useSelector(selectContractStatus);
  const contract = useSelector(selectCurrentContract);
  const userSettings = useSelector(selectUserSettings);
  const teamMembers = useSelector(selectAllTeamMembers);
  const buyerContractPersons = useSelector(selectBuyerContractPersons);
  const [initValues, setInitValues] = useState(defaultValues);
  const [podOptions, setPodOptions] = useState([]);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [commercialOptions, setCommercialOptions] = useState([]);

  useEffect(() => {
    if (contractId && !loading && (!contract || contract._id !== contractId)) {
      dispatch(fetchContractById(contractId));
      dispatch(fetchAllTeamMembers());
    }
  }, [contractId]);

  useEffect(() => {
    if (contract) {
      // Setup the names based on language for the articles in the fetched contract
      let articles = [];

      if (!!contract.articles.length) {
        contract.articles.forEach(article => {
          let adjustedArticle = {
            ...article,
            article_name: language === 'en'
              ? article.articleDetails.descriptionInEnglish
              : article.articleDetails.descriptionInGerman,
          };
          articles.push(adjustedArticle);
        });
      }

      const adjustedContract = { ...contract, articles };
      setInitValues({ ...defaultValues, ...adjustedContract });

      setPodOptions((contract.podOptions || []).map((p) => ({ label: p, value: p })));
    }
  }, [contract]);

  useEffect(() => {
    if (!!partners.length && !buyerOptions.length) {
      const filteredList = partners.map(partner => ({
        value: partner.name2,
        label: partner.name2,
        partnerId: partner._id,
        pod: partner.pod,
      }));
      setBuyerOptions(filteredList);
    }
  }, [partners]);

  useEffect(() => {
    if (partners.length && !partnerOptions.length) {
      const mappedPartners = partners.map(partner => ({
        value: partner.name2,
        label: partner.name2,
        ...partner,
      }));
      setPartnerOptions([{ value: 'Same as buyer', label: 'Same as buyer' }, ...mappedPartners]);
    }
  }, [partners, partnerOptions]);

  useEffect(() => {
    if (!!partners.length && !shipperOptions.length) {
      const shippers = partners.map(shipper => ({
        value: shipper.name2,
        label: shipper.name2,
        shipperId: shipper._id,
      }));
      setShipperOptions(shippers);
    }
  }, [partners, shipperOptions]);

  useEffect(() => {
    if (!!teamMembers.length && !commercialOptions.length) {
      const mappedList = teamMembers
        .filter((member) => member.role.includes('Account Manager'))
        .map(item => ({ value: item.fullName, label: item.fullName, ...item }));

      setCommercialOptions(mappedList);
    }
  }, [teamMembers, commercialOptions]);


  const createContractDocumentProps = (currentOrder) => {
    return {
      companyOfficer: currentOrder.companyOfficer,
      language: language,
      accountManagerProcessor: currentOrder.accountManagerProcessor
        ? currentOrder.accountManagerProcessor[0]
          ? currentOrder.accountManagerProcessor[0]
          : currentOrder.accountManagerProcessor
        : null,

      companyData: companies[0],
    };
  };
  const handleEditContract = async payload => {
    const res = await dispatch(editContract(contractId, payload));

    if (typeof res !== 'string' || res && !res.errors) {
      return history.push(PATHS.contracts.root);
    }
  };

  const buyerCallback = useCallback((buyer) => {
    const { partnerId, pod } = buyer;

    if (pod && !!pod.length) {
      const options = pod.split(',');
      setPodOptions(options.map(option => ({ value: option, label: option })));
    }

    dispatch(fetchContractPersons(partnerId));
  }, [dispatch]);

  if (
    loading ||
    !buyerOptions ||
    !buyerContractPersons ||
    !partners.length ||
    !companies.length ||
    !banks.length ||
    !shipperOptions.length ||
    !commercialOptions.length
  ) return <div>Loading..</div>;

  return (
    <>
      <SendDocs
        contract={contract}
        required={false}
        createContractDocumentProps={createContractDocumentProps}
        userSettings={userSettings}
        companies={companies}
      />
      <ContractForm
        initialValues={initValues}
        onSubmit={handleEditContract}
        buyerCallback={buyerCallback}
        podOptions={podOptions}
        banks={banks}
        buyerOptions={buyerOptions}
        partnerOptions={partnerOptions}
        shipperOptions={shipperOptions}
        commercialOptions={commercialOptions}
        buyerContractPersons={buyerContractPersons}
        harbors={harbors}
      />
    </>
  );
};

export default EditContract;
