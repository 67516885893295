import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useStyles } from 'components/Layout/SystemTableNav/styles/TableNav.styles';

import { AntTab, AntTabs } from 'components/Layout/SystemTableNav/AntTabs';

const SystemTableNav = ({ tableNavCallback, currentTab }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    tableNavCallback(newValue);
  };

  const renderTabs = useCallback(() => {
    return (
      <AntTabs value={currentTab} onChange={handleChange} aria-label='system sub tabs'>
        <AntTab label={t('Banking')} />
        <AntTab label={t('Tax Matrix')} />
        <AntTab label={t('Articles')} />
        <AntTab label={t('Team')} />
        <AntTab label={t('Warehouse')} />
        <AntTab label={t('Harbors')} />
      </AntTabs>
    );
  }, [currentTab]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <div className={classes.themedBg}>
        {renderTabs()}
      </div>
    </div>
  );
};

SystemTableNav.propTypes = {
  tableNavCallback: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
};

export default SystemTableNav;
