import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import './languageSelectBox.scss';

export default function LanguageSelectBox() {
  const [isOpened, setIsOpened] = useState(false);
  const { i18n } = useTranslation('common');
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const toggleContainer = createRef();

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  const handleClick = (language) => {
    setCurrentLanguage(language);
    setIsOpened(!isOpened);

    i18n.changeLanguage(language);
  };

  const onClickOutsideHandler = (event) => {
    if (isOpened && toggleContainer.current && !toggleContainer.current.contains(event.target)) {
      setIsOpened(false);
    }
  };

  console.log(currentLanguage);

  return (
    <div className='language-selectbox' ref={toggleContainer}>
      <img
        className='flag'
        src={`/assets/flags/${currentLanguage === 'de' ? 'de' : 'gb'}.png`}
        alt='flag'
        onClick={() => setIsOpened(!isOpened)}
      />
      {isOpened && (
        <ul className='language-menu'>
          <li className='language-item' onClick={() => handleClick('en')}>
            <img className='flag' src='/assets/flags/gb.png' alt='gb-flag' />
            <span>English</span>
          </li>
          <li className='language-item' onClick={() => handleClick('de')}>
            <img className='flag' src='/assets/flags/de.png' alt='gb-flag' />
            <span>Germany</span>
          </li>
        </ul>
      )}
    </div>
  );
}
