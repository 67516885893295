import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RequireAuth from 'components/hoc/require-auth';
import {
  PartnersList,
  AddPartner,
  EditPartner,
  CompaniesList,
  AddCompany,
  EditCompany,
  ContractsList,
  AddContract,
  EditContract,
  System,
  Login,
  CarriersAdd,
  CarriersEdit,
  CarriersList,
  OrdersList,
  OrdersAdd,
  OrdersEdit,
  Stocks,
  Datev,
} from 'views';
import { PATHS } from 'util/appConstants';

const AppRouter = () => (
  <Switch>
    <Route exact path={PATHS.login} component={Login} />
    <Route exact path="/" component={RequireAuth(PartnersList)} />
    <Route exact path={PATHS.partners.root} component={RequireAuth(PartnersList)} />
    <Route exact path={PATHS.partners.add} component={RequireAuth(AddPartner)} />
    <Route exact path={PATHS.partners.edit} component={RequireAuth(EditPartner)} />
    <Route exact path={PATHS.companies.root} component={RequireAuth(CompaniesList)} />
    <Route exact path={PATHS.companies.add} component={RequireAuth(AddCompany)} />
    <Route exact path={PATHS.companies.edit} component={RequireAuth(EditCompany)} />
    <Route exact path={PATHS.carriers.root} component={RequireAuth(CarriersList)} />
    <Route exact path={PATHS.carriers.add} component={RequireAuth(CarriersAdd)} />
    <Route exact path={PATHS.carriers.edit} component={RequireAuth(CarriersEdit)} />
    <Route path={PATHS.system.root} component={RequireAuth(System)} />
    <Route exact path={PATHS.contracts.root} component={RequireAuth(ContractsList)} />
    <Route exact path={PATHS.contracts.add} component={RequireAuth(AddContract)} />
    <Route exact path={PATHS.contracts.edit} component={RequireAuth(EditContract)} />
    <Route exact path={PATHS.orders.root} component={RequireAuth(OrdersList)} />
    <Route exact path={PATHS.orders.add} component={RequireAuth(OrdersAdd)} />
    <Route exact path={PATHS.orders.edit} component={RequireAuth(OrdersEdit)} />
    <Route exact path={PATHS.stocks} component={RequireAuth(Stocks)} />
    <Route exact path="/datev-test" component={RequireAuth(Datev)} />
    <Redirect from={PATHS.root} to={PATHS.login} />
  </Switch>
);

export default AppRouter;
