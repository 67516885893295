import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  currencyOptions,
  paymentTermsOptions,
  basisForInvoiceOptions,
  documentLanguageOptions,
  invoiceDeliveryMethodOptions,
} from 'components/Shared/selectInputOptions';

import { Input, Select, IbanInput, ChipsInput } from 'components/Shared/mui-formik-inputs';

const useStyles = makeStyles(() => ({
  title: {
    paddingBottom: 30,
  },
  paperRoot: {
    maxHeight: '400px',
  },
  chipInput: {
    minWidth: 300,
    margin: '8px 0',
  },
}));

const BankingForm = ({
  values,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const isDebitor = values.partnerType === 'Debitor/Kreditor';
  const none = [{ label: '', value: '' }];

  return (
    <Box boxShadow={3} p={2} my={4}>
      <Typography className={classes.title} variant='h4'>
        {t('Banking Details')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label={'Payment Terms'}
            name='paymentTerms'
            value={values.paymentTerms}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={none.concat(paymentTermsOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required
          />
        </Grid>
        {
          values.paymentTerms === 'Other' && (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Input
                label='Payment Days'
                name='paymentDays'
                value={values.paymentDays}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                required={values.paymentTerms === 'Other'}
              />
            </Grid>
          )
        }
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label={'Invoice Delivery Method'}
            name='invoiceDeliveryMethod'
            value={values.invoiceDeliveryMethod}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={none.concat(invoiceDeliveryMethodOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required={true}
          />
        </Grid>
        {values.invoiceDeliveryMethod === 'Email' && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <ChipsInput
              name="invoiceEmail"
              value={values.invoiceEmail || []}
              label="Invoice Email"
              errors={errors}
              required={false}
              setFieldValue={setFieldValue}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label={'Choose Document Language'}
            name='documentLanguage'
            value={values.documentLanguage}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={none.concat(documentLanguageOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label={'basis_invoice'}
            name='basisForInvoice'
            value={values.basisForInvoice}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={none.concat(basisForInvoiceOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required={isDebitor}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label={'Currency'}
            name='currency'
            value={values.currency}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={none.concat(currencyOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required={isDebitor}
          />
        </Grid>
        <IbanInput
          bic={values.bic}
          label='IBAN'
          value={values.iban}
          bankName={values.bankName}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          errors={errors}
        />
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label='Our Client No At Partner'
            name='clientNumberAtClient'
            value={values['clientNumberAtClient']}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label='VAT ID'
            name='taxID'
            value={values['taxID']}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={isDebitor}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label='National Tax Number'
            name='nationalTaxID'
            value={values['nationalTaxID']}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

BankingForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default BankingForm;
