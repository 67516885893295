import React from 'react';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import DropzoneFileUpload from 'components/Shared/DropzoneFileUpload';
import { coreApi } from 'api/core';

const FileUploadFormSection = ({
  values,
  setFieldValue,
}) => {
  const onDrop = async (files) => {
    const form = new FormData();
    form.append('files', files[0]);

    const data = await coreApi.post('/companies/assets/upload', form);

    return data.fileName;
  };

  const handleFileDownload = async (name) => {
    const data = await coreApi.fetch(
      `/companies/image/${name}`,
      'GET',
      {},
      { responseType: 'blob' },
    );

    return download(data, name);
  };

  return (
    <>
      <Box boxShadow={3} p={2} mb={2}>
        <DropzoneFileUpload
          name='signatures'
          values={values}
          label='Upload Signature'
          onDrop={async (files) => {
            const filename = await onDrop(files);

            setFieldValue('signatures', filename);
          }}
          handleFileDownload={() => handleFileDownload(values.signatures)}
          handleFileDelete={() => setFieldValue('signatures', null)}
        />
      </Box>
      <Box boxShadow={3} p={2} mb={2}>
        <DropzoneFileUpload
          name='companyLogo'
          values={values}
          label='Upload Logo'
          onDrop={async (files) => {
            const filename = await onDrop(files);

            setFieldValue('companyLogo', filename);
          }}
          handleFileDownload={() => handleFileDownload(values.companyLogo)}
          handleFileDelete={() => setFieldValue('companyLogo', null)}
        />
      </Box>
      <Box boxShadow={3} p={2} mb={2}>
        <DropzoneFileUpload
          name='companyAgb'
          values={values}
          label='Upload AGB'
          onDrop={async (files) => {
            const filename = await onDrop(files);

            setFieldValue('companyAgb', filename);
          }}
          handleFileDownload={() => handleFileDownload(values.companyAgb)}
          handleFileDelete={() => setFieldValue('companyAgb', null)}
        />
      </Box>
    </>
  );
};

FileUploadFormSection.propTypes = {
  values: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default FileUploadFormSection;
