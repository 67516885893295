import * as yup from 'yup';

export const TeamSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().nullable(),
  role: yup.array().min(1).required('Required'),
  salutation: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  surname: yup.string().required('Required'),
  position: yup.string().nullable(),
  responsibility: yup.string().nullable(),
  department: yup.string().nullable(),
  phone: yup.string().required('Required'),
  mobileNumber: yup.string().nullable(),
  fax: yup.string().optional(),
  comments: yup.string().optional(),
  emails: yup.array().test('invoiceEmail', 'Required. Valid emails only', (value) => {
    return value.length && value.every((v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v));
  }),
  permissions: yup.object().shape({
    carriers_create: yup.boolean().nullable(),
    carriers_delete: yup.boolean().nullable(),
    carriers_read: yup.boolean().nullable(),
    carriers_update: yup.boolean().nullable(),
    invoice_create: yup.boolean().nullable(),
    invoice_read: yup.boolean().nullable(),
    finance_read: yup.boolean().nullable(),
    orders_create: yup.boolean().nullable(),
    orders_delete: yup.boolean().nullable(),
    orders_read: yup.boolean().nullable(),
    orders_update: yup.boolean().nullable(),
    orders_restrictToAssignedOrders: yup.boolean().nullable(),
    partners_create: yup.boolean().nullable(),
    partners_delete: yup.boolean().nullable(),
    partners_read: yup.boolean().nullable(),
    partners_update: yup.boolean().nullable(),
    tuning_delete: yup.boolean().nullable(),
    tuning_read: yup.boolean().nullable(),
    tuning_update: yup.boolean().nullable(),
    weightSlips_delete: yup.boolean().nullable(),
    weightSlips_read: yup.boolean().nullable(),
    weightSlips_update: yup.boolean().nullable(),
    system_read: yup.boolean().nullable(),
    stocks_read: yup.boolean().nullable(),
    company_read: yup.boolean().nullable(),
    contracts_read: yup.boolean().nullable(),
    contracts_update: yup.boolean().nullable(),
    contracts_create: yup.boolean().nullable(),
    contracts_delete: yup.boolean().nullable(),
  }).required('Required'),
  teamSignature: yup.string().nullable(),
});

export const defaultValues = {
  email: '',
  password: '',
  role: [],
  salutation: 'Mr',
  firstName: '',
  surname: '',
  position: '',
  responsibility: '',
  department: '',
  phone: '',
  mobileNumber: '',
  fax: '',
  comments: '',
  emails: [],
  permissions: {
    carriers_create: false,
    carriers_delete: false,
    carriers_read: false,
    carriers_update: false,
    invoice_create: false,
    invoice_read: false,
    finance_read: false,
    orders_create: false,
    orders_delete: false,
    orders_read: false,
    orders_update: false,
    partners_create: false,
    partners_delete: false,
    partners_read: false,
    partners_update: false,
    tuning_delete: false,
    tuning_read: false,
    tuning_update: false,
    weightSlips_delete: false,
    weightSlips_read: false,
    weightSlips_update: false,
    system_read: false,
    stocks_read: false,
    company_read: false,
    contracts_read: false,
    contracts_update: false,
    contracts_create: false,
    contracts_delete: false,
  },
  teamSignature: '',
};
