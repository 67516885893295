import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithRedirect } from 'redux/slices/authSlice';
import { PATHS } from 'util/appConstants';
import { fetchUserInfo, fetchUserSettings } from 'redux/slices/userSlice';
import { getCompanies  } from 'redux/slices/companySlice';

import LoginComponent from 'components/Login';

const Login = () => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const loginCallback = () => {
    dispatch(fetchUserInfo());
    dispatch(fetchUserSettings());
    dispatch(getCompanies());

    history.push(PATHS.partners.root);
  };

  const onSubmit = async (values) => {
    try {
      const success = await dispatch(loginWithRedirect(values, loginCallback));

      if (!success) {
        setError('invalid_auth');
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.error);
      }
    }
  };

  return (
    <LoginComponent onSubmit={onSubmit} error={error} />
  );
};

export default Login;
