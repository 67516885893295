export const PATHS = {
  root: '/',
  login: '/login',
  stocks: '/stocks',
  system: {
    root: '/system',
    banks: {
      root: '/system/banks',
      add: '/system/banks/add',
      edit: '/system/banks/edit/:id',
    },
    taxMatrix: {
      root: '/system/tax-matrix',
    },
    articles: {
      root: '/system/articles',
      add: '/system/articles/add',
      edit: '/system/articles/edit/:id',
    },
    harbors: {
      root: '/system/harbors',
      add: '/system/harbors/add',
      edit: '/system/harbors/edit/:id',
    },
    team: {
      root: '/system/team',
      add: '/system/team/add',
      edit: '/system/team/edit/:id',
    },
    warehouses: {
      root: '/system/warehouses',
      add: '/system/warehouses/add',
      edit: '/system/warehouses/edit/:id',
    },
  },
  orders: {
    root: '/orders',
    add: '/orders/add',
    edit: '/orders/edit/:id',
  },
  carriers: {
    root: '/carriers',
    add: '/carriers/add',
    edit: '/carriers/edit/:id',
  },
  partners: {
    root: '/partner',
    add: '/partner/add',
    edit: '/partner/edit/:id',
  },
  companies: {
    root: '/companies',
    add: '/companies/add',
    edit: '/companies/edit/:id',
  },
  contracts: {
    root: '/contracts',
    add: '/contracts/add',
    edit: '/contracts/edit/:id',
  },
  dashboard: '/dashboard',
  transporters: '/transporters',
};

export const SYSTEM_SUBTABS = {
  0: 'banks',
  1: 'taxMatrix',
  2: 'articles',
  3: 'team',
  4: 'warehouses',
  5: 'harbors',
};

export const modalLookup = {
  AddPerson: 'AddPerson',
  EditPerson: 'EditPerson',
};

export const formActions = {
  add: 'ADD',
  edit: 'EDIT',
};
