import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from 'util/appConstants';
import { selectWarehouseStatus, addWarehouse } from 'redux/slices/warehouseSlice';
import WarehouseForm from 'components/System/Warehouses/Form';


const currentAction = 'ADD';

const AddWarehouse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectWarehouseStatus);

  const handleAddWarehouse = async (payload) => {
    await dispatch(addWarehouse(payload));

    history.push(PATHS.system.warehouses.root);
  };

  if (loading) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <WarehouseForm
      onSubmit={handleAddWarehouse}
      action={currentAction}
    />
  );
};

export default AddWarehouse;
