import React from 'react';
import { Box } from '@material-ui/core';
import DropzoneFileUpload from 'components/Shared/DropzoneFileUpload';
import { coreApi } from 'api/core';
import download from 'downloadjs';
import PropTypes from 'prop-types';

const UploadSignature = ({
  values,
  setFieldValue,
}) => {
  const onDrop = async (files) => {
    const form = new FormData();
    form.append('files', files[0]);

    const data = await coreApi.post('/persons/team/signature/upload', form);

    return data.fileName;
  };

  const handleFileDownload = async (name) => {
    const data = await coreApi.fetch(
      `/persons/team/download-file/${name}`,
      'GET',
      {},
      { responseType: 'blob' },
    );

    return download(data, name);
  };

  return (
    <Box boxShadow={3} p={2} mb={2}>
      <DropzoneFileUpload
        name='teamSignature'
        values={values}
        label='Upload Signature'
        onDrop={async (files) => {
          const filename = await onDrop(files);

          setFieldValue('teamSignature', filename);
        }}
        handleFileDownload={() => handleFileDownload(values.teamSignature)}
        handleFileDelete={() => setFieldValue('teamSignature', null)}
      />
    </Box>
  );
};

UploadSignature.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default UploadSignature;
