import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';

export const APP_NAMESPACE = 'mkrn-starter';
const userPrefix = `${APP_NAMESPACE}/user`;
const usersPrefix = `${APP_NAMESPACE}/users`;
const authUserPrefix = `${APP_NAMESPACE}/auth`;

const initialState = {
  messages: {
    [userPrefix]: '',
    [usersPrefix]: '',
  },
  errors: {
    [userPrefix]: [],
    [usersPrefix]: [],
    [authUserPrefix]: [],
  },
  loading: {
    [userPrefix]: false,
    [usersPrefix]: false,
    [authUserPrefix]: false,
  },
  user: null,
  settings: {
    billingConfirmationEmail: '',
    client: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const {
  setUser,
  setUserSettings,
} = userSlice.actions;
export default userSlice.reducer;

export const fetchUserInfo = () => async dispatch => {
  const url = '/user/info';

  try {
    const { user } = await coreApi.fetch(url);
    dispatch(setUser(user));
  } catch (err) {
    console.log(err);
  }
};

export const fetchUserSettings = () => async dispatch => {
  const url = '/user/settings';

  try {
    const { data } = await coreApi.fetch(url);
    dispatch(setUserSettings(data));
  } catch (err) {
    console.log(err);
  }
};

const userSelector = state => state.newUser.user;
const userSettingsSelector = state => state.newUser.settings;

export const selectUser = createSelector(userSelector, user => user);
export const selectUserSettings = createSelector(userSettingsSelector, settings => settings);
