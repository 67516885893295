import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from 'util/appConstants';
import { selectHarborStatus, addHarbor } from 'redux/slices/harborSlice';
import HarborsForm from 'components/System/Harbors/Form';

const Add = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectHarborStatus);

  const handleAdd = async (payload) => {
    await dispatch(addHarbor(payload));

    history.push(PATHS.system.harbors.root);
  };

  if (loading) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <HarborsForm
      onSubmit={handleAdd}
    />
  );
};

export default Add;
