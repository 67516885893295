import React from 'react';
import pick from 'lodash/pick';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IBAN from 'iban';

// Helpers
import { BankSchema, addBankFields, addressFields, allowedFields } from './constants';
import { PATHS } from 'util/appConstants';

// Components
import { Box, Grid, Button, Typography, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Input, CountriesAutocomplete } from 'components/Shared/mui-formik-inputs';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 12,
    height: '100%',
  },
  title: {
    paddingBottom: 30,
  },
  button: {
    margin: 10,
    backgroundColor: 'white',

  },
  buttonSubmit: {
    color: 'green',
  },
  buttonCancel: {
    color: 'red',
  },
}));

const BankForm = ({
  initialValues,
  handleAddBank,
  handleEditBank,
  action,
  companies,
  currentTab = 'BANK',
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: BankSchema,
    initialValues,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (action === 'ADD') {
          const validIban = await IBAN.isValid(values.iban);

          if (validIban) {
            return await handleAddBank(pick(values, allowedFields));
          } else {
            setErrors({ iban: 'Invalid IBAN' });
          }
        }

        if (action === 'EDIT') {
          return await handleEditBank(pick(values, allowedFields));
        }
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const { values, handleChange, setFieldValue, errors, handleSubmit, submitCount } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Button
          className={clsx(classes.button, classes.buttonSubmit)}
          variant='contained'
          color='primary'
          type='submit'
          startIcon={<SaveIcon />}
        >
          {`${t('Save')} ${t(currentTab)}`}
        </Button>
        <Button
          className={clsx(classes.button, classes.buttonCancel)}
          variant='contained'
          startIcon={<CancelIcon />}
          onClick={() => history.push(PATHS.system.banks.root)}
        >
          {t('Cancel')}
        </Button>
        <Box boxShadow={3} p={2}>
          <Typography className={classes.title} variant='h4'>
            {t('Add Bank')}
          </Typography>
          <Grid container spacing={2}>
            {addBankFields.map(field => (
              <Grid key={field.value} item xs={12} sm={6} md={4} lg={2}>
                <Input
                  label={field.label}
                  name={field.value}
                  value={values[field.value]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  required={field.required}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Autocomplete
                id='company-banks'
                name='company'
                disableClearable
                options={companies}
                onChange={(event, newValue) => {
                  setFieldValue('company', newValue?.name1 ?? newValue?.name2);
                }}
                getOptionLabel={(option) => option.name1 ?? ''}
                getOptionSelected={(option) => option.name1 === values.company}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Company')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `new-password`,
                    }}
                    margin='none'
                    error={errors.company}
                    helperText={errors.company ? t('Required') : null}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box boxShadow={3} p={2} my={4}>
          <Typography className={classes.title} variant='h4'>
            {t('Address')}
          </Typography>
          <Grid container spacing={2}>
            {addressFields.map(field => (
              <Grid key={field.value} item xs={12} sm={6} md={4} lg={2}>
                <Input
                  label={field.label}
                  name={field.value}
                  value={values[field.value]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  required={field.required}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountriesAutocomplete
                name="country"
                label={'Country'}
                setFieldValue={setFieldValue}
                value={values.country}
                errors={errors}
                required
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

BankForm.propTypes = {
  handleAddBank: PropTypes.func,
  handleEditBank: PropTypes.func,
  action: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  currentTab: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
};

export default BankForm;
