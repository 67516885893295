import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';

// Helpers
import { COMPANY_TABLE_COLUMNS } from 'constants/ui-constants';
import { defaultTableOptions, getLocalization, getColumns, getActions, mapTableData } from 'util/table-utils';
import { PATHS } from 'util/appConstants';
import { useCompanies } from 'hooks';

// Actions
import { selectCompanyStatus, deleteCompany, searchFilter, setSearch } from 'redux/slices/companySlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'COMPANIES';

const CompaniesList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectCompanyStatus);
  const { companies, revalidate } = useCompanies();
  const user = useSelector(selectUser);
  const search = useSelector(searchFilter);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(
      () => {
        dispatch(deleteCompany(rowData._id));
        revalidate();
      },
      {
        description: 'Are you sure?',
      },
    );
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.companies.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );

  if (loading) return <div>Loading..</div>;

  console.log(companies);

  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapTableData(companies)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={getColumns(COMPANY_TABLE_COLUMNS, t)}
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.companies.edit.replace(':id', rowData._id),
        )}
        localization={getLocalization(t)}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(CompaniesList);
