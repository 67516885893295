import React from 'react';
import { FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { CountriesAutocomplete, Input } from 'components/Shared/mui-formik-inputs';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { handleZipcodeChange } from 'util/index';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
  },
  title: {
    paddingBottom: 30,
  },
  addCircleIcon: {
    fontSize: 28,
    position: 'relative',
    top: -2,
    marginLeft: 15,
    cursor: 'pointer',
  },
  removeCircleIcon: {
    color: '#FF3B2F',
    backgroundColor: 'white',
    marginTop: '20px',
    cursor: 'pointer',
  },
}));

const AdditionalLocationsForm = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  return (
    <Box boxShadow={3} p={2} my={4}>
      <FieldArray name='locations'>
        {({ remove, push }) => (
          <>
            <div className={classes.flex}>
              <Typography className={classes.title} variant='h4'>
                {t('additional_locations')}
              </Typography>
              <AddCircleOutlineIcon
                className={classes.addCircleIcon}
                onClick={() => push({
                  name: '',
                  street: '',
                  houseNumber: '',
                  city: '',
                  zipcode: '',
                  country: '',
                })}
                color='primary'
              />
            </div>

            {values.locations.length > 0 && values.locations.map((loc, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name={`locations.${index}.name`}
                    value={values.locations[index].name}
                    errors={errors}
                    label={'Name'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name={`locations.${index}.street`}
                    value={values.locations[index].street}
                    errors={errors}
                    label={'Street'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name={`locations.${index}.houseNumber`}
                    value={values.locations[index].houseNumber}
                    errors={errors}
                    label={'House Number'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1}>
                  <Input
                    name={`locations.${index}.zipcode`}
                    value={values.locations[index].zipcode}
                    errors={errors}
                    label={'Zipcode'}
                    onChange={handleZipcodeChange(handleChange, setFieldValue, i18n.language, `locations.${index}.`)}
                    onBlur={handleBlur}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Input
                    name={`locations.${index}.city`}
                    value={values.locations[index].city}
                    label={'City'}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={10}>
                      <CountriesAutocomplete
                        name={`locations.${index}.country`}
                        value={values.locations[index].country}
                        errors={errors}
                        label={'Country'}
                        required={true}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Box textAlign="right">
                        <span
                          className={clsx('material-icons', classes.removeCircleIcon)}
                          onClick={() => remove(index)}
                        >
                          remove_circle
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

AdditionalLocationsForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default AdditionalLocationsForm;
