import useBaseHook from './useBaseHook';
import { selectArticles, selectArticleStatus, getArticles, selectTimestamp } from 'redux/slices/articleSlice';

const useArticles = () => {
	const {
		records: articles, loading, revalidate,
	} = useBaseHook({
		recordsSelector: selectArticles,
		loadingSelector: selectArticleStatus,
		timestampSelector: selectTimestamp,
		fetchRecords: getArticles,
	});

	return { articles, loading, revalidate };
};

export default useArticles;
