import * as yup from 'yup';

export const BankSchema = yup.object().shape({
  bankName: yup.string().required('Required'),
  bankDescription: yup.string(),
  accountNumber: yup.string().required('Required'),
  bankCode: yup.string().required('Required'),
  iban: yup.string().required('Required'),
  bic: yup.string().required('Required'),
  company: yup.string().nullable(),
  street: yup.string().required('Required'),
  houseNumber: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  city: yup.string().required('Required'),
  country: yup.string().required('Required'),
});

export const initialValues = {
  bankName: '',
  bankDescription: '',
  accountNumber: '',
  bankCode: '',
  iban: '',
  bic: '',
  company: '',
  street: '',
  houseNumber: '',
  zipcode: '',
  city: '',
  country: '',
};

export const addBankFields = [
  { label: 'Bank Name', value: 'bankName', required: true },
  { label: 'Bank Description', value: 'bankDescription', required: false },
  { label: 'Account Number', value: 'accountNumber', required: true },
  { label: 'Bank Code', value: 'bankCode', required: true },
  { label: 'IBAN', value: 'iban', required: true },
  { label: 'BIC', value: 'bic', required: true },
];

export const addressFields = [
  { label: 'Street', value: 'street', required: true },
  { label: 'House Number', value: 'houseNumber', required: true },
  { label: 'Zipcode', value: 'zipcode', required: true },
  { label: 'City', value: 'city', required: true },
];

export const allowedFields = [
  'bankDescription',
  'accountNumber',
  'bankCode',
  'iban',
  'bic',
  'company',
  'bankName',
  'address',
  'houseNumber',
  'city',
  'street',
  'zipcode',
  'country',
];
