import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { HoursSelect, TimeSelect } from 'components/Shared/mui-formik-inputs';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  title: {
    paddingBottom: 30,
  },
}));

const OpeningHoursForm = ({
  values,
  errors,
  handleChange,
  hideTitle,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <Box boxShadow={3} p={2} my={hideTitle ? 0 : 4}>
      {
        !hideTitle && (
          <Typography className={classes.title} variant='h4'>
            {t('Opening Hours')}
          </Typography>
        )
      }

      <FieldArray name='openingHours'>
        {() => (
          <Grid container spacing={2}>
            {values.openingHours.length > 0 && values.openingHours.map((row, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <HoursSelect
                    name={`openingHours.${index}.start_day`}
                    value={values.openingHours[index].start_day}
                    label='Start Day'
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <HoursSelect
                    name={`openingHours.${index}.end_day`}
                    value={values.openingHours[index].end_day}
                    label={'End Day'}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <TimeSelect
                    name={`openingHours.${index}.start_time`}
                    value={values.openingHours[index].start_time}
                    label={'Start Time'}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <TimeSelect
                    name={`openingHours.${index}.end_time`}
                    value={values.openingHours[index].end_time}
                    label={'End Time'}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <TimeSelect
                    name={`openingHours.${index}.start_time1`}
                    value={values.openingHours[index].start_time1}
                    label={'Start Time'}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={4} lg={2}>
                  <TimeSelect
                    name={`openingHours.${index}.end_time1`}
                    label={'End Time'}
                    value={values.openingHours[index].end_time1}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </FieldArray>
    </Box>
  );
};

OpeningHoursForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default OpeningHoursForm;
