import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  modalType: '',
  modalProps: null,
};

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      const { modalType, modalProps } = action.payload;
      state.modalType = modalType;
      state.modalProps = modalProps;
    },
    setModalClosed: (state) => {
      state.modalType = '';
      state.modalProps = null;
    },
  },
});

export const {
  setModalOpen,
  setModalClosed,
} = modalSlice.actions;
export default modalSlice.reducer;

export const closeModal = (modalType) => dispatch => {
  dispatch(setModalClosed(modalType));
};

const modalSelector = state => state.modals;

export const selectModal = createSelector(modalSelector, props => {
  return { ...props };
});
