import React from 'react';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'components/Shared/mui-formik-inputs';
import { allowedFields, HarborSchema } from './constants';

const HarborForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation('common');

  const formik = useFormik({
    initialValues: merge({
      name: '',
      code: '',
    }, initialValues || {}),
    validationSchema: HarborSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(pick(values, allowedFields));
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, handleChange, handleSubmit, submitCount } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <Box boxShadow={3} p={2}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Input
              name="name"
              value={values.name}
              label="Name"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Input
              name="code"
              value={values.code}
              label="Code"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<CloudUploadIcon />}
              style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
              type='submit'
            >
              {t('Save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default HarborForm;
