import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Select as MaterialSelect,
  Checkbox as MaterialCheckbox,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
  Grid, Typography, FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ChipInput from 'material-ui-chip-input';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Countries from 'assets/countries/countries.json';
import TimeField from 'react-simple-timefield';
import { permissionsList } from 'helpers/permissions';
import IBAN from 'iban';

export const getErrorForName = (errors, name) => {
  if (!errors) {
    return null;
  }

  if (errors[name]) {
    return errors[name];
  }

  if (name.includes('.')) {
    const split = name.split('.');

    if (errors[split[0]]) {
      let tmp = errors[split[0]];
      for (let i = 1; i < split.length; i += 1) {
        if (tmp[split[i]]) {
          tmp = tmp[split[i]];
        } else {
          tmp = null;
          break;
        }
      }

      if (tmp) {
        return tmp;
      }
    }
  }

  return null;
};

export const getHelpOrError = (help, errors, name, t) => {
  const error = getErrorForName(errors, name);

  if (error) {
    return t(error);
  }

  if (help) {
    return help;
  }

  return null;
};

export const Password = (props) => {
  const [showPwd, setShowPwd] = React.useState(false);
  const { type, label, name, errors, value, help, ...rest } = props;
  const { t } = useTranslation('common');

  const changeShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <TextField
      label={t(label || '')}
      margin='normal'
      name={name}
      type={showPwd ? 'text' : 'password'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      InputProps={{
        endAdornment:
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={changeShowPwd}
            >
              {showPwd ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>,

      }}
      {...rest}
    />
  );
};

export const IbanInput = ({
  label,
  value,
  help,
  errors,
  required,
  bic: valuesBic,
  bankName: valuesBankName,
  setFieldValue,
  onBlur,
}) => {
  const { t } = useTranslation('common');

  const handleBlur = async (e) => {
    e.persist();
    const { value: fieldValue } = e.target;

    if (IBAN.isValid(fieldValue)) {
      const headers = new Headers();
      headers.append('Authorization', 'Basic ' + btoa('infens_GmbH' + ':' + 'zetzaf-Nyqvim-xibcu7'));

      fetch(`https://rest.sepatools.eu/validate_iban/${fieldValue}`, { headers: headers })
        .then((response) => response.json())
        .then(async (data) => {
          const { bic } = data['bic_candidates'][0];
          setFieldValue('bic', bic);
          setFieldValue('bankName', data.bank);

          if (onBlur) {
            onBlur({
              ...e,
              target: {
                ...e.target,
                name: 'bic',
              }
            });
            onBlur({
              ...e,
              target: {
                ...e.target,
                name: 'bankName',
              }
            });
          }
        });
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Grid item container wrap='nowrap' spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        {IBAN.isValid(value) || value === '' ? (
          <TextField
            name='iban'
            value={value}
            label={t(label) || ''}
            onChange={(e) => setFieldValue('iban', e.target.value)}
            onBlur={handleBlur}
            required={required}
            helperText={getHelpOrError(help, errors, 'iban', t)}
            error={!!getErrorForName(errors, 'iban')}
            fullWidth
          />
        ) : (
          <TextField
            id='iban-error-helper-text'
            error
            name={value}
            value={value}
            defaultValue={value}
            label={t(label) || ''}
            onChange={(e) => setFieldValue('iban', e.target.value)}
            onBlur={handleBlur}
            helperText={t('Invalid') + ' ' + t('IBAN') + '.'}
            required={required}
            fullWidth
          />
        )}
      </Grid>

      {IBAN.isValid(value) && !!valuesBic && (
        <>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextField
              name='bic'
              value={valuesBic}
              label={t('BIC')}
              onChange={e => setFieldValue('bic', e.target.value)}
              helperText={getHelpOrError(help, errors, 'bic', t)}
              error={!!getErrorForName(errors, 'bic')}
              required={required}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextField
              name='bankName'
              value={valuesBankName}
              label={t('Bank Name')}
              onChange={e => setFieldValue('bankName', e.target.value)}
              helperText={getHelpOrError(help, errors, 'bankName', t)}
              error={!!getErrorForName(errors, 'bankName')}
              required={required}
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const ChipsInput = ({
  name,
  value,
  label,
  margin,
  errors,
  touched,
  required,
  fullWidth,
  setFieldValue,
}) => {
  const { t } = useTranslation('common');

  return (
    <ChipInput
      value={value}
      onAdd={(chip) => {
        setFieldValue(name, value.concat([chip]));
      }}
      onDelete={(chip, index) => {
        setFieldValue(name, value.filter((e, i)  => i !== index));
      }}
      fullWidth={fullWidth}
      placeholder={t(label)}
      newChipKeyCodes={[9, 13, 188]}
      margin="normal"
      alwaysShowPlaceholder
      blurBehavior="add"
      error={errors && !!errors[name]}
      helperText={getHelpOrError(null, errors, name, t)}
      required={!!required}
    />
  );
};

export const Input = (props) => {
  const { type, label, name, errors, value, onBlur, help, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <TextField
      label={t(label) || ''}
      margin='dense'
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      onBlur={onBlur}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      autoComplete="off"
      inputProps={{
        autoComplete: 'off',
      }}
      {...rest}
    />
  );
};

export const InputOnlyNumbers = (props) => {
  const { type, label, name, errors, value, help, onChange, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <TextField
      label={t(label || '')}
      margin='dense'
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      onChange={(e) => {
        if (!/^[0-9+-\s]*$/.test(e.target.value)) {
          return;
        }

        return onChange(e);
      }}
      autoComplete="off"
      inputProps={{
        autoComplete: 'off',
      }}
      {...rest}
    />
  );
};

export const MultiSelectChips = ({
  name,
  value,
  label,
  help,
  errors,
  options,
  required,
  setFieldValue,
}) => {
  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth margin="none" style={{ marginTop: '5.69px' }}>
      <Autocomplete
        multiple
        id='select-autocomplete-chips'
        fullWidth
        value={value}
        disableCloseOnSelect
        options={options}
        onChange={(event, newValue) => setFieldValue(name, newValue)}
        getOptionLabel={(option) => t(option)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <MaterialCheckbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              checked={selected}
            />
            {t(option)}
          </React.Fragment>
        )}
        required={required}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(label)}
            value={value}
            inputProps={{
              ...params.inputProps,
              autoComplete: `off`,
            }}
            margin="none"
          />
        )}
      />
      <FormHelperText error={!!getErrorForName(errors, name)}>
        <span>{getHelpOrError(help, errors, name, t)}</span>
      </FormHelperText>
    </FormControl>
  );
};

/**
 * Select Autocomplete
 * @param name {string}
 * @param value {string | object}
 * @param label {string}
 * @param help {object}
 * @param errors {object}
 * @param margin {string}
 * @param options {[{ label: string, value: string }]}
 * @param required {boolean}
 * @param setFieldValue {function}
 * @param onChange {function}
 * @param callback {function}
 * @returns {JSX.Element}
 */
export const SelectAC = ({
  name,
  value,
  label,
  help,
  errors,
  margin,
  options,
  required,
  setFieldValue,
  onChange,
  callback,
  disableClearable,
}) => {
  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth margin={margin || 'none'} style={{ marginTop: '5.69px' }}>
      <Autocomplete
        id='select-autocomplete'
        fullWidth
        value={value ? { value, label: value } : null}
        options={options}
        disableClearable={!!disableClearable}
        onChange={(event, newValue) => {
          const val = newValue ? newValue['value'] : '';
          if (onChange) {
            onChange(val);
          } else {
            setFieldValue(name, val);
          }

          if (callback) {
            callback(newValue);
          }
        }}
        getOptionLabel={(option) => t(option.label ? option.label : option.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(label)}
            required={required}
            inputProps={{
              ...params.inputProps,
              autoComplete: `off`,
            }}
            margin="none"
            error={!!getHelpOrError(null, errors, name, t)}
            helperText={getHelpOrError(null, errors, name, t)}
          />
        )}
      />
    </FormControl>
  );
};

export const Select = (props) => {
  const { label, name, errors, value, options, help, margin, paperRoot, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth margin={props.margin || 'none'} style={{ marginTop: '8px' }} required={rest.required} error={!!getErrorForName(errors, name)}>
      {!!label && <InputLabel>{t(label) || ''}</InputLabel>}
      <MaterialSelect
        classes={{
          root: paperRoot,
        }}
        native={true}
        margin={margin || 'dense'}
        name={name}
        value={value || ''}
        fullWidth={true}
        error={!!getErrorForName(errors, name)}
        {...rest}
      >
        {
          options.map((o, i) => <option key={i} value={o.value}>{o.label}</option>)
        }
      </MaterialSelect>
      <FormHelperText error={!!getErrorForName(errors, name)}>
        <span>{getHelpOrError(help, errors, name, t)}</span>
      </FormHelperText>
    </FormControl>
  );
};

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const HoursSelect = props => {
  const {
    label,
    name,
    errors,
    value,
    help,
    margin,
    onBlur,
    handleChange,
  } = props;
  const { t } = useTranslation('common');

  return (
    <FormControl variant='outlined' fullWidth>
      {!!label && <InputLabel>{t(label) || ''}</InputLabel>}
      <MaterialSelect
        value={value}
        onChange={handleChange}
        label={t(label) || ''}
        name={name}
        onBlur={onBlur}
        margin={margin || 'normal'}
        style={{ margin: '10px 0' }}
      >
        <MenuItem value=''>
          <em>{t('None')}</em>
        </MenuItem>
        {days.map((day) => (
          <MenuItem key={day} value={day}>{t(day)}</MenuItem>
        ))}
      </MaterialSelect>
      <FormHelperText error={!!getErrorForName(errors, name)}>
        <span>{getHelpOrError(help, errors, name, t)}</span>
      </FormHelperText>
    </FormControl>
  );
};

export const TimeSelect = ({
  name,
  value,
  label,
  errors,
  help,
  margin,
  handleChange,
}) => {
  const { t } = useTranslation('common');

  return (
    <FormControl variant='outlined' fullWidth margin={margin || 'none'}>
      <TimeField
        name={name}
        value={value}
        onChange={handleChange}
        input={
          <TextField
            name={name}
            value={value}
            label={t(label)}
          />
        }
        style={{ margin: '10px 0' }}
      />
      <FormHelperText error={!!getErrorForName(errors, name)}>
        <span>{getHelpOrError(help, errors, name, t)}</span>
      </FormHelperText>
    </FormControl>
  );
};

export const Textarea = (props) => {
  const { label, name, value, help, errors, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <TextField
      label={t(label || '')}
      margin='normal'
      name={name}
      type={'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      {...rest}
      multiline={true}
      rows={3}
    />
  );
};

export const Checkbox = (props) => {
  const { label, name, value, ...rest } = props;

  return (
    <MaterialCheckbox
      checked={value}
      value={value}
      name={name}
      {...rest}
    />
  );
};

export const CheckboxWithLabel = ({ label, name, value, ...rest }) => {
  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          checked={value}
          value={value}
          name={name}
          {...rest}
        />
      }
      label={label}
    />
  );
};

export function ColorInput(props) {
  const { type, label, name, errors, value, help, onChange, ...rest } = props;
  const { t } = useTranslation('common');

  function handleChange(e) {
    e.preventDefault();
    e.persist();

    if (e.target.value) {
      onChange(e);
    }
  }

  return (
    <TextField
      label={t(label || '')}
      margin='dense'
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(help, errors, name, t)}
      error={!!getErrorForName(errors, name)}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position='end'>
            <input
              value={value || ''}
              type='color'
              name={name}
              onChange={handleChange}
            />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

export const CountriesAutocomplete = ({ name, value, setFieldValue, errors, label }) => {
  const { t } = useTranslation('common');

  const options = React.useCallback(() => {
    return Countries.map((c) => ({ ...c, name: t(c.name) }));
  }, [Countries]);

  return (
    <Autocomplete
      id='combo-box-demo'
      name={name}
      value={value ? { name: value } : null}
      options={Countries}
      disableClearable
      onChange={(event, newValue) => {
        setFieldValue(name, newValue.name);
      }}
      getOptionLabel={(option) => t(option.name)}
      getOptionSelected={(option) => option.name === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(label)}
          inputProps={{
            ...params.inputProps,
            autoComplete: `off`,
          }}
          margin='dense'
          error={!!getHelpOrError(null, errors, name, t)}
          helperText={getHelpOrError(null, errors, name, t)}
          required
        />
      )}
    />
  );
};

export const PermissionsPicker = ({
  name,
  values,
  label,
  setFieldValue,
  ...rest
}) => {
  const { t } = useTranslation('common');

  function handlePermissionChange(e) {
    const { name, checked } = e.target;
    setFieldValue(`permissions[${name}]`, checked);
  }

  function changeLabel(name) {
    const label = name.split('%%');
    return t(label[0]) + ' ' + t(label[1]);
  }

  return (
    <div>
      <Typography variant='h4' style={{ paddingTop: 30, paddingBottom: 15 }}>
        Permissions
      </Typography>
      <Grid container spacing={3}>
        {permissionsList.map((permission, index) => (
          <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
            <FormControlLabel
              control={
                <MaterialCheckbox
                  checked={values[permission.value]}
                  onChange={handlePermissionChange}
                  name={permission.value}
                  color='primary'
                />
              }
              label={changeLabel(permission.label)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

// attempt to have a more flexible autocomplete select input
// while not breaking the SelectAC
export const AutocompleteSelect = ({
  name,
  value,
  label,
  help,
  errors,
  margin,
  options,
  required,
  onChange,
  settings,
  onBlur,
}) => {
  const { t } = useTranslation('common');
  const opts = {
    disableClearable: false,
    freeSolo: false,
    valueProp: 'value',
    labelProp: 'label',
    filterOptions: null,
    ...settings,
  };

  const getValue = (o) => {
    if (typeof opts.valueProp === 'function') {
      return opts.valueProp(o);
    }

    return o[opts.valueProp];
  };

  const getLabel = (o) => {
    if (typeof opts.labelProp === 'function') {
      return opts.labelProp(o);
    }

    return o[opts.labelProp];
  };

  return (
    <FormControl fullWidth margin={margin || 'none'}>
      <Autocomplete
        id='new-select-autocomplete'
        fullWidth
        value={(!!value) ? options.find((o) => getValue(o) === value) : null}
        options={options}
        disableClearable={opts.disableClearable}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onBlur={onBlur}
        freeSolo={opts.freeSolo}
        getOptionLabel={(option) => getLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(label)}
            required={required}
            inputProps={{
              ...params.inputProps,
              autoComplete: `off`,
            }}
            margin="dense"
            error={!!getHelpOrError(null, errors, name, t)}
            helperText={getHelpOrError(help, errors, name, t)}
          />
        )}
        {...(opts.filterOptions ? { filterOptions: opts.filterOptions } : {})}
      />
    </FormControl>
  );
};

export const ToggleInput = ({
  value,
  onChange,
  options,
}) => {
  const handleChange = (event, value) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
    >
      {
        options.map((option, i) => (
          <ToggleButton key={i} value={option.value} aria-label={option.value} size="small">
            {option.label}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}
