import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PATHS } from 'util/appConstants';
import { selectCarrierStatus, addCarrier } from 'redux/slices/carrierSlice';
import CarrierForm from 'components/Carriers/Form';

const currentAction = 'ADD';

const AddCarrier = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectCarrierStatus);

  const handleAddCarrier = async (payload) => {
    await dispatch(addCarrier(payload));

    history.push(PATHS.carriers.root);
  };

  if (loading) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <CarrierForm
      onSubmit={handleAddCarrier}
      action={currentAction}
    />
  );
};

export default AddCarrier;
