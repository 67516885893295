import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { initialValues } from 'components/Partners/constants';
import { PATHS } from 'util/appConstants';

// Actions
import { selectPartnersStatus, addPartner } from 'redux/slices/partnerSlice';

// Components
import PartnerForm from 'components/Partners/PartnerForm';


const currentAction = 'ADD';

const AddPartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectPartnersStatus);

  const handleAddPartner = async payload => {
    const res = await dispatch(addPartner(payload));

    if (typeof res !== 'string' || res && !res.errors) {
      return history.push(PATHS.partners.root);
    }
  };

  if (loading) return <div>Loading..</div>;
  return (
    <PartnerForm
      action={currentAction}
      initialValues={initialValues}
      handleAddPartner={handleAddPartner}
    />
  );
};

export default AddPartner;
