import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { useStyles } from './styles/Navbars.styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/slices/userSlice';
import { BOTTOM_NAVIGATION_ROUTES, TOP_NAVIGATION_ROUTES } from '../../../constants/ui-constants';
import { PATHS } from '../../../util/appConstants';
import LanguageSelectBox from '../../language-box/languageSelectBox';


const Navbars = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('common');
  const user = useSelector(selectUser);
  const [currentTab, setCurrentTab] = useState('');

  const getTabFromLocation = (loc) => {
    if (loc.includes('/companies')) {
      return BOTTOM_NAVIGATION_ROUTES[1];
    }
    if (loc.includes('/system')) {
      return BOTTOM_NAVIGATION_ROUTES[0];
    }
    if (loc.includes('/partner')) {
      return TOP_NAVIGATION_ROUTES[0];
    }
    if (loc.includes('/carriers')) {
      return TOP_NAVIGATION_ROUTES[1];
    }
    if (loc.includes('/contracts')) {
      return TOP_NAVIGATION_ROUTES[6];
    }
    if (loc.includes('/orders')) {
      return TOP_NAVIGATION_ROUTES[3];
    }

    if (loc.includes('/stocks')) {
      return TOP_NAVIGATION_ROUTES[5];
    }

    return TOP_NAVIGATION_ROUTES[0];
  };

  useEffect(() => {
    if (location.pathname) {
      const tab = getTabFromLocation(location.pathname);
      setCurrentTab(tab);
    }
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSetMenu = (text) => {
    switch (text) {
      case TOP_NAVIGATION_ROUTES[0]:
        return history.push(PATHS.partners.root);
      case TOP_NAVIGATION_ROUTES[1]:
        return history.push(PATHS.carriers.root);
      case TOP_NAVIGATION_ROUTES[3]:
        return history.push(PATHS.orders.root);
      case TOP_NAVIGATION_ROUTES[5]:
        return history.push(PATHS.stocks);
      case TOP_NAVIGATION_ROUTES[6]:
        return history.push(PATHS.contracts.root);
      case BOTTOM_NAVIGATION_ROUTES[0]:
        return history.push(PATHS.system.banks.root);
      case BOTTOM_NAVIGATION_ROUTES[1]:
        return history.push(PATHS.companies.root);
    }
  };

  const getIconName = (navItem) => {
    switch (navItem) {
      case 'partner':
        return 'partner.svg';
      case 'carriers':
        return 'carrier.svg';
      case 'orders':
        return 'order.svg';
      case 'system':
        return 'system.svg';
      case 'user management':
        return 'user.svg';
      case 'companies':
        return 'company.svg';
      case 'stocks':
        return 'stock.png';
      case 'contracts':
        return 'contracts.svg';
      default:
        return '';
    }
  };

  const hasPermission = (name) => {
    if (!user) return;

    return (
      (name === 'orders' && user.permissions.orders_read) ||
      (name === 'carriers' && user.permissions.carriers_read) ||
      (name === 'partner' && user.permissions.partners_read) ||
      (name === 'system' && user.permissions.system_read) ||
      (name === 'stocks' && user.permissions.stocks_read) ||
      (name === 'companies' && user.permissions.company_read) ||
      (name === 'contracts' && user.permissions.contracts_read)
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="secondary"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <LanguageSelectBox />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
            classes.paper,
          ),
        }}
      >
        <div className={classes.toolbar}>
          <div style={{ marginRight: '24px' }}>
            <img src={'/assets/img/logo.png'} alt='' width={'100px'} />
          </div>

          <IconButton onClick={handleDrawerClose} style={{ color: '#FFFFFF' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={'uppercase-span'}>
          {TOP_NAVIGATION_ROUTES.map(
            (text, index) =>
              !['person', 'warehouse'].includes(text.toLowerCase()) && (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSetMenu(text)}
                  className={
                    currentTab.toLowerCase() === text.toLowerCase()
                      ? 'active-nav'
                      : 'inactive-nav'
                  }
                  style={{
                    backgroundColor:
                      currentTab.toLowerCase() === text.toLowerCase()
                        ? theme.palette.primary.main
                        : 'transparent',
                  }}
                >
                  <img
                    src={`/assets/img/icons/${getIconName(text.toLowerCase())}`}
                    alt=''
                    className={`mr-12 h-25 ${text.toLowerCase() === 'contracts' ? 'w-24' : 'w-25'}`}
                  />
                  <ListItemText primary={t(text)} className={'bold-title'} />
                </ListItem>
              ),
          )}
        </List>

        <Divider />
        <List className={'uppercase-span'}>
          {BOTTOM_NAVIGATION_ROUTES.map(
            (text, index) =>
              hasPermission(text.toLowerCase()) && (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSetMenu(text)}
                  className={
                    currentTab.toLowerCase() === text.toLowerCase()
                      ? 'active-nav'
                      : 'inactive-nav'
                  }
                  style={{
                    backgroundColor:
                      currentTab.toLowerCase() === text.toLowerCase()
                        ? theme.palette.primary.main
                        : 'transparent',
                  }}
                >
                  <img
                    src={`/assets/img/icons/${getIconName(text.toLowerCase())}`}
                    alt=''
                    width={25}
                    className={'mr-12'}
                  />
                  <ListItemText primary={t(text)} className={'bold-title'} />
                </ListItem>
              ),
          )}
        </List>
      </Drawer>
    </div>
  );
};

Navbars.propTypes = {
  navColor: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  parentCallback: PropTypes.func.isRequired,
  activeNavColor: PropTypes.string.isRequired,
};

export default Navbars;
