import React, { useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { PATHS, SYSTEM_SUBTABS } from 'util/appConstants';

// Components
import { BanksList, AddBank, EditBank } from 'components/System/Banks';
import { ArticlesList, ArticlesAdd, ArticlesEdit } from 'views/Articles';
import { WarehousesList, WarehousesAdd, WarehousesEdit } from 'views/Warehouses';
import { HarborsAdd, HarborsEdit, HarborsList } from 'views/Harbors';
import { TeamsList, AddTeamMember, EditTeamMember, TaxMatrix } from 'views';
import SystemTableNav from 'components/Layout/SystemTableNav';
import RequireAuth from 'components/hoc/require-auth';

const systemSubRoutes = [
  PATHS.system.banks.root,
  PATHS.system.taxMatrix.root,
  PATHS.system.articles.root,
  PATHS.system.team.root,
  PATHS.system.warehouses.root,
  PATHS.system.harbors.root,
];

const getActiveTab = (loc) => {
  let index = 0;

  systemSubRoutes.forEach((n, i) => {
    if (loc.includes(n)) {
      return index = i;
    }
  });

  return index;
};

const System = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(getActiveTab(history.location.pathname));

  const tableNavCallback = (value) => {
    setCurrentTab(value);

    history.push(PATHS.system[SYSTEM_SUBTABS[value]].root);
  };

  return (
    <>
      <SystemTableNav
        tableNavCallback={tableNavCallback}
        currentTab={currentTab}
      />
      <Switch>
        <Route exact path={PATHS.system.banks.root} component={RequireAuth(BanksList)} />
        <Route exact path={PATHS.system.banks.add} component={RequireAuth(AddBank)} />
        <Route exact path={PATHS.system.banks.edit} component={RequireAuth(EditBank)} />
        <Route exact path={PATHS.system.articles.root} component={RequireAuth(ArticlesList)} />
        <Route exact path={PATHS.system.articles.add} component={RequireAuth(ArticlesAdd)} />
        <Route exact path={PATHS.system.articles.edit} component={RequireAuth(ArticlesEdit)} />
        <Route exact path={PATHS.system.warehouses.root} component={RequireAuth(WarehousesList)} />
        <Route exact path={PATHS.system.warehouses.add} component={RequireAuth(WarehousesAdd)} />
        <Route exact path={PATHS.system.warehouses.edit} component={RequireAuth(WarehousesEdit)} />
        <Route exact path={PATHS.system.team.root} component={RequireAuth(TeamsList)} />
        <Route exact path={PATHS.system.team.add} component={RequireAuth(AddTeamMember)} />
        <Route exact path={PATHS.system.team.edit} component={RequireAuth(EditTeamMember)} />
        <Route exact path={PATHS.system.taxMatrix.root} component={RequireAuth(TaxMatrix)} />
        <Route exact path={PATHS.system.harbors.root} component={RequireAuth(HarborsList)} />
        <Route exact path={PATHS.system.harbors.add} component={RequireAuth(HarborsAdd)} />
        <Route exact path={PATHS.system.harbors.edit} component={RequireAuth(HarborsEdit)} />
        <Redirect exact from={PATHS.system.root} to={PATHS.system.banks.root} />
      </Switch>
    </>
  );
};

export default System;
