import React from 'react';
import { getCountry } from '../../helpers/documents';

function Footer(props) {
  const { language } = props;
  //-------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {props.client === 'belgium' ? (
        <div className="margin-top-20 hideFooter">
          <div className="footer-title-color_1 bold"> {props.companyName} </div>
          <div className="footer">
            <div className="footer-widget">
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className=" text-right footer-title_background white footer_text"
                >
                  TEL
                </span>
                <span style={{ width: '55%' }} className="footer-title-color">
                  {' '}
                  {props.companyData.phone1}{' '}
                </span>
              </div>
              <br />
              <br />
              <div className="display-flex align-start justify-space-between ">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  ADDRESS
                </span>
                <span style={{ width: '55%' }} className="footer-title-color">
                  {' '}
                  {props.companyAddress}
                </span>
              </div>
            </div>
            <div className="footer-widget">
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  EMAIL
                </span>
                <span style={{ width: '55%' }} className="footer-title-color">
                  {props.companyData.email}
                </span>
              </div>
              <br />
              <br />
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  WEB
                </span>{' '}
                <span style={{ width: '55%' }} className="footer-title-color">
                  {props.companyData.website}
                </span>
              </div>
            </div>
            <div className="footer-widget">
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  VAT
                </span>{' '}
                <span style={{ width: '55%' }} className="footer-title-color">
                  {props.companyData.ustID}
                </span>
              </div>
              <br />
              <br />
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  IBAN
                </span>{' '}
                <span style={{ width: '55%' }} className="footer-title-color">
                  {' '}
                  {props.companyData.bankId[0] ? props.companyData.bankId[0].iban : 'N/V'}
                </span>
              </div>
            </div>
            <div className="footer-widget">
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  BANK
                </span>{' '}
                <span style={{ width: '55%' }} className="footer-title-color">
                  {props.companyData.bankId[0] ? props.companyData.bankId[0].bankName : 'N/V'}
                </span>
              </div>
              <br />
              <br />
              <div className="display-flex align-start justify-space-between">
                <span
                  style={{ width: '40%', display: 'inline-block' }}
                  className="text-right footer-title_background white footer_text"
                >
                  BIC
                </span>{' '}
                <span style={{ width: '55%' }} className="footer-title-color">
                  {' '}
                  {props.companyData.bankId[0] ? props.companyData.bankId[0].bic : 'N/V'}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="footer hideFooter">
            <div className="footer-widget">
              <p>
                {props.companyName} <br />
                {props.companyData.street} {props.companyData.houseNumber} <br />
                {props.companyData.zipcode} {props.companyData.city} <br />
                {getCountry(props.companyData.country, props.language)}
              </p>
            </div>
            <div className="footer-widget">
              <p>
                {props.companyData.bankId[0] ? props.companyData.bankId[0].bankName : 'N/V'} <br />
                IBAN:{' '}
                <span className="white-space-no">
                  {props.companyData.bankId[0] ? props.companyData.bankId[0].iban : 'N/V'}
                </span>
                <br />
                BIC: {props.companyData.bankId[0] ? props.companyData.bankId[0].bic : 'N/V'}
              </p>
            </div>
            <div className="footer-widget">
              <p>
                {language === 'de' ? 'St.-Nr.' : 'Tax ID.'}: {props.companyData.taxNumber} <br />
                {language === 'de' ? 'HR-Nr.' : 'CR no.'}:{' '}
                {props.companyData.commercialRegisterNumber} {props.companyData.commercialRegister}{' '}
                <br />
                {language === 'de' ? 'Ust-ID' : 'Ust-ID'}: {props.companyData.ustID} <br />
                {language === 'de' ? 'Geschäftsführer' : 'Managing Partner'}:{' '}
                {props.companyData.executiveDirector}
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Footer;
