import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@material-ui/icons/Save';
import OnAddOrderPopup from 'components/popup/on_order_add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PreviewAnnexDocument from 'components/Modals/previewAnnexDocument';
import PreviewInvoiceCorrectionSource from 'components/Modals/previewInvoiceCorrectionSource';
import PreviewInvoiceCorrectionProcessor from 'components/Modals/previewInvoiceCorrectionProcessor';
import PreviewInvoiceCancellationSource from 'components/Modals/previewInvoiceCancellationSource';
import PreviewInvoiceCancellationProcessor from 'components/Modals/previewInvoiceCancellationProcessor';
import PreviewPurchaseConfirmation from 'components/Modals/previewPurchaseConfirmation';
import PreviewSalesConfirmation from 'components/Modals/previewSalesConfirmation';
import PreviewTransportInformation from 'components/Modals/previewTransportInformation';
import PreviewInvoiceSource from 'components/Modals/previewInvoiceSource';
import PreviewInvoiceProcessor from 'components/Modals/previewInvoiceProcessor';
import PreviewTransportOrder from 'components/Modals/previewTransportationOrder';
import CommissionInvoiceDialog from 'components/Modals/previewCommissionInvoice';
import CommissionConfirmationDialog from 'components/Modals/previewCommissionConfirmation';
import PreviewBillingConfirmation from 'components/Modals/previewBillingConfirmation';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getCompanyAddress } from 'helpers/documents';
import { useCompanies } from 'hooks';
import { selectUserSettings } from 'redux/slices/userSlice';

const SentDate = ({ label, date, previewComponent }) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <Typography component="p" style={{ display: 'inline' }}>
      {`${label} (${moment(date).format('DD.MM.YYYY')})`} <div style={{ display: 'inline-block' }}>{previewComponent}</div>
    </Typography>
  </Grid>
);

const SendableItem = ({
  attr,
  checked,
  order,
  disabled,
  label,
  previewComponent,
  handleCheckboxesChange,
}) => {
  const { t } = useTranslation('common');

  const docSentDate = () => {
    if (!order.docStatus[attr] || !order.docStatus[attr].date) {
      return '';
    }

    return `(${moment(order.docStatus[attr]['date']).format('DD.MM.YYYY')})`;
  };

  if (order.docStatus[attr] && order.docStatus[attr].status) {
    return (
      <SentDate label={t(label)} previewComponent={previewComponent} date={order.docStatus[attr].date} />
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <FormGroup row style={{ display: 'inline' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked[attr] || false}
              onChange={handleCheckboxesChange}
              name={attr}
              disabled={disabled}
            />
          }
          label={`${t(label)} ${docSentDate()}`}
        />
      </FormGroup>
      <div style={{ display: 'inline-block' }}>
        {previewComponent}
      </div>
    </Grid>
  );
};

const SendableItemInvoice = ({
  attr,
  type,
  entity,
  checked,
  order,
  disabled,
  label,
  previewComponent,
  handleCheckboxesChange,
}) => {
  const { t } = useTranslation('common');

  if (!order[type] || !order[type][entity]) {
    return null;
  }

  if (order[type][`sent_${entity}`]) {
    return (
      <SentDate
        label={t(label)}
        date={order[type].updatedAt}
        previewComponent={previewComponent}
      />
    );
  }

  return (
    <SendableItem
      attr={attr}
      checked={checked}
      order={order}
      disabled={disabled}
      label={label}
      previewComponent={previewComponent}
      handleCheckboxesChange={handleCheckboxesChange}
    />
  );
};

const SendDocs = ({
  order,
  sendDocuments,
  createCorrection,
  partners,
  deviatings,
  carriers,
  accountManagers,
  companyOfficer,
  tax,
  user,
}) => {
  const { i18n, t } = useTranslation('common');
  const { companies } = useCompanies();
  const settings = useSelector(selectUserSettings);
  const [sendingDocs, setSendingDocs] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [checked, setChecked] = useState({
    annex: false,
    purchase_confirmation: false,
    sales_confirmation: false,
    transport_information: false,
    transport_order: false,
    assessment: false,
    preliminary_purchase_confirmation: false,
    commission_confirmation: false,
    commission_invoice: false,
    billing_confirmation: false,
    invoice_source: false,
    invoice_processor: false,
    invoice_correction_processor: false,
    invoice_correction_source: false,
    invoice_cancellation_source: false,
    invoice_cancellation_processor: false,
  });
  const [displaySourceInvoice, setDisplaySourceInvoice] = React.useState(false);
  const [displayProcessorInvoice, setDisplayProcessorInvoice] = React.useState(false);

  useEffect(() => {
    const orderCheckedStatuses = Object.keys(order.docStatus).reduce((acc, key) => {
      acc[key] = order.docStatus[key].status;

      return acc;
    }, checked);

    setChecked(orderCheckedStatuses);

    setDisplaySourceInvoice(order.articles.some((a) => a.source_cashflow === 'Supplementary Payment'));
    setDisplayProcessorInvoice(order.articles.some((a) => a.processor_cashflow === 'Compensation'));
  }, []);

  // temporary shitshow until we refactor the fucking send documents piece of shit
  const company = companies[0] || {};

  const handleSendClick = () => {
    setOpen(false);
    setSending(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChecked({
      ...checked,
      invoice_source: false,
      invoice_processor: false,
    });
  };

  const handleCheckboxesChange = (event) => {
    const sourceInvoiceStatus =
      order.docStatus.invoice_source &&
      order.docStatus.invoice_source.date &&
      order.invoiceNumberSource;

    const processorInvoiceStatus =
      order.docStatus.invoice_processor &&
      order.docStatus.invoice_processor.date &&
      order.invoiceNumberProcessor;

    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });

    if (event.target.name === 'invoice_source' && sourceInvoiceStatus && event.target.checked) {
      setOpen(true);
    }

    if (event.target.name === 'invoice_processor' && processorInvoiceStatus && event.target.checked) {
      setOpen(true);
    }
  };
  const isDisabled = Object.keys(checked)
    .every((k) => !checked[k] || (order.docStatus[k] && order.docStatus[k].date && order.docStatus[k].status));

  // TODO: refactor this crap
  const documentsProps = {
    companyOfficer,
    language: i18n.language,
    accountManagerProcessor: order.accountManagerProcessor,
    fieldAgent: order.fieldAgent,
    companyAddress: getCompanyAddress(company, i18n.language),
    companyName: company.name1,
    phone: order?.fieldAgent?.phone,
    email: order.fieldAgent && order.fieldAgent.emails.length ? order.fieldAgent.emails[0] : 'N/A',
    firstName: order?.fieldAgent?.firstName,
    surName: order?.fieldAgent?.surname,
    loadingReferenceNumber: order.loadingReferenceSource,
    dispositionNumber: order.orderNumber,
    carrierData: order.carrier,
    currentEntity: order,
    sourceData: order.source,
    processorData: order.processor,
    companyData: company,
    zipcode: company.zipcode,
    city: company.city,
    countries: order.countries,
    tax,
    articles: order.articles,
    billingConfirmationEmail: settings.billingConfirmationEmail,
    client: settings.client,
    transportRemark: order.transportRemark || '',
    oldInvoiceNumber: order.invoiceNumber,
  };

  const handleCreateCorrection = async () => {
    const { billingConfirmationEmail, ...rest } = documentsProps;

    const payload = {
      orderId: order._id,
      documentData: rest,
      processor: checked.invoice_processor,
      source: checked.invoice_source,
    };

    setSending(true);

    return createCorrection(payload);
  };

  return (
    <>
      <Grid container spacing={2}>
        <SendableItem
          attr="annex"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="Annex Document"
          previewComponent={(
            <PreviewAnnexDocument {...documentsProps} />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItem
          attr="purchase_confirmation"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label={order.destinationType === 'warehouse' ? 'Final Purchase Confirmation' : 'Purchase Confirmation'}
          previewComponent={(
            <PreviewPurchaseConfirmation
              {...documentsProps}
              title={order.destinationType === 'warehouse' ? 'Final Purchase Confirmation' : 'Purchase Confirmation'}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItem
          attr="sales_confirmation"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="Sales Confirmation"
          previewComponent={(
            <PreviewSalesConfirmation
              {...documentsProps}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItem
          attr="transport_information"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="Transportation Information"
          previewComponent={(
            <PreviewTransportInformation
              {...documentsProps}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        {
          displaySourceInvoice && (
            <SendableItem
              attr="invoice_source"
              checked={checked}
              order={order}
              disabled={!order.articles.length || !user.permissions.invoice_create || !order.articles.some((a) => a.source_weightslip_date)}
              label="Loading Place Invoice"
              previewComponent={(
                <PreviewInvoiceSource
                  {...documentsProps}
                  permission={user.permissions.invoice_read}
                />
              )}
              handleCheckboxesChange={handleCheckboxesChange}
            />
          )
        }
        {
          displayProcessorInvoice && (
            <SendableItem
              attr="invoice_processor"
              checked={checked}
              order={order}
              disabled={!order.articles.length || !user.permissions.invoice_create || !order.articles.some((a) => a.processor_weightslip_date)}
              label="Unloading Place Invoice"
              previewComponent={(
                <PreviewInvoiceProcessor
                  {...documentsProps}
                  permission={user.permissions.invoice_read}
                />
              )}
              handleCheckboxesChange={handleCheckboxesChange}
            />
          )
        }
        <SendableItemInvoice
          attr="invoice_cancellation_source"
          type="cancellationInvoice"
          entity="source"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="invoice_cancellation_loading_place"
          previewComponent={(
            <PreviewInvoiceCancellationSource
              {...order.cancellationInvoice?.order_details_source}
              newInvoiceNumber={order.cancellationInvoiceNumberSource}
              tax={tax}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItemInvoice
          attr="invoice_cancellation_processor"
          type="cancellationInvoice"
          entity="processor"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="invoice_cancellation_unloading_place"
          previewComponent={(
            <PreviewInvoiceCancellationProcessor
              {...order.cancellationInvoice?.order_details_processor}
              newInvoiceNumber={order.cancellationInvoiceNumberProcessor}
              tax={tax}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItemInvoice
          attr="invoice_correction_source"
          type="correctionInvoice"
          entity="source"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="initial_invoice_loading_place"
          previewComponent={(
            <PreviewInvoiceCorrectionSource
              {...order.correctionInvoice?.order_details_source}
              tax={tax}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItemInvoice
          attr="invoice_correction_processor"
          type="correctionInvoice"
          entity="processor"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="initial_invoice_unloading_place"
          previewComponent={(
            <PreviewInvoiceCorrectionProcessor
              {...order.correctionInvoice?.order_details_processor}
              tax={tax}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItem
          attr="transport_order"
          checked={checked}
          order={order}
          disabled={!order.articles.length}
          label="Transportation Order"
          previewComponent={(
            <PreviewTransportOrder
              {...documentsProps}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        <SendableItem
          attr="billing_confirmation"
          checked={checked}
          order={order}
          disabled={!order.articles.length || order.articles.some((a) => !a.wastecode)}
          label="Billing confirmation"
          previewComponent={(
            <PreviewBillingConfirmation
              {...documentsProps}
            />
          )}
          handleCheckboxesChange={handleCheckboxesChange}
        />
        {
          !!order.commissionArticles.length && (
            <SendableItem
              attr="commission_invoice"
              checked={checked}
              order={order}
              disabled={!order.articles.length}
              label="Commission Invoice"
              previewComponent={(
                <CommissionInvoiceDialog
                  {...documentsProps}
                />
              )}
              handleCheckboxesChange={handleCheckboxesChange}
            />
          )
        }
        {
          !!order.commissionArticles.length && (
            <SendableItem
              attr="commission_confirmation"
              checked={checked}
              order={order}
              disabled={!order.articles.length}
              label="Commission Confirmation"
              previewComponent={(
                <CommissionConfirmationDialog
                  title={t('Commission Confirmation')}
                  {...documentsProps}
                />
              )}
              handleCheckboxesChange={handleCheckboxesChange}
            />
          )
        }
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={isDisabled}
            onClick={() => handleSendClick()}
          >
            {t('Send')}
          </Button>
        </Grid>
        {
          sending && (
            <OnAddOrderPopup
              closeDialogCancel={async () => {
                setSending(false);
              }}
              closeDialogOk={async (data) => {
                try {
                  setSendingDocs(true);

                  await sendDocuments(data, i18n.language);
                } catch (err) {
                  console.log(err);

                  setSendingDocs(false);
                }
              }}
              loading={sendingDocs}
              setLoading={setSendingDocs}
              state={{ sendorderdocument: true }}
              checked={checked}
              createDocumentProps={() => documentsProps}
              currentEntity={documentsProps.currentEntity}
              checkIfDocAlreadySent={(key) => order.docStatus[key] && order.docStatus[key].status}
            />
          )

        }
      </Grid>
      <Dialog fullWidth open={open} aria-labelledby="draggable-dialog-title">
        <AppBar position="static">
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              {t('Attention')}
            </Typography>
          </Toolbar>
        </AppBar>
        {sendingDocs && <LinearProgress color="secondary" />}
        <DialogContent>{t('invoice_correction_message')}</DialogContent>
        <DialogActions>
          <Button style={{ color: 'red' }} onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            color="secondary"
            className="anti-bootstrap-button"
            onClick={() => handleCreateCorrection()}
            autoFocus={true}
          >
            {t('Correct invoice')}
          </Button>
          {/*<Button
            color="secondary"
            className="anti-bootstrap-button"
            onClick={() => handleSendClick()}
            autoFocus={true}
          >
            {t('Send anyway')}
          </Button>*/}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendDocs;
