import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// Helpers
import { useBanks } from 'hooks';
import { PATHS } from 'util/appConstants';

// Actions
import { selectCompany, selectCompanyStatus, getCompany, editCompany } from 'redux/slices/companySlice';

// Components
import CompanyForm from 'components/company/form';


const currentAction = 'EDIT';

const EditCompany = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectCompanyStatus);
  const company = useSelector(selectCompany);
  const { banks } = useBanks();

  useEffect(() => {
    if (!company || (id && company.id !== id && !loading)) {
      dispatch(getCompany(id));
    }
  }, []);

  const handleEditCompany = async (_id, payload) => {
    await dispatch(editCompany(id, payload));

    history.push(PATHS.companies.root);
  };

  if (loading || !company) return <div>Loading..</div>;
  return (
    <CompanyForm
      initialValues={company}
      handleEditCompany={handleEditCompany}
      banks={banks}
      action={currentAction}
    />
  );
};

export default EditCompany;
