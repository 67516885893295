import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import defaultTheme from 'config/theme';
import { PATHS } from 'util/appConstants';

// Actions
import { fetchUserInfo, fetchUserSettings } from 'redux/slices/userSlice';
import { selectAuthenticated } from 'redux/slices/authSlice';
import { selectCompanies, getCompanies  } from 'redux/slices/companySlice';
import { selectModal } from 'redux/slices/modalSlice';

// Components
import Navbars from 'components/Layout/Navbars';
import Snackbar from 'components/Snackbar';
import AppRouter from 'routes/AppRouter';
import ModalManager from 'components/Modals/ModalManager';

const useStyles = makeStyles(() => ({
  appWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  appRoot: {
    flexGrow: 1,
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authenticated = useSelector(selectAuthenticated);
  const companies = useSelector(selectCompanies);
  const currentModal = useSelector(selectModal);
  const [theme, setTheme] = useState(defaultTheme);
  const isModalNull = currentModal.modalType === '';

  const fetchStuff = async () => {
    dispatch(fetchUserInfo());
    await dispatch(fetchUserSettings());
    dispatch(getCompanies());
  };

  useEffect(() => {
    if (location.pathname !== '/login') {
      fetchStuff();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!authenticated) {
      history.push(PATHS.login);
    }
  }, [authenticated, history]);

  const replaceDefaultTheme = (primary, secondary) => {
    const newTheme = createMuiTheme({
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
      },
    });
    setTheme(newTheme);
  };

  useLayoutEffect(() => {
    if (companies && companies.length) {
      if (!!companies[0].primaryColor?.length || !!companies[0].secondaryColor?.length) {
        replaceDefaultTheme(companies[0].primaryColor, companies[0].secondaryColor);

        localStorage.setItem('custom-primary-color', companies[0].primaryColor);
        localStorage.setItem('custom-secondary-color', companies[0].secondaryColor);
      }
    }
  }, [companies]);

  const token = !!localStorage.getItem('token') && location.pathname !== '/login';

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {!isModalNull ? <ModalManager /> : null}
      <div className='app-container'>
        {token && (
          <div style={{ flex: 1, display: 'flex' }}>
            { /* TODO: remove this shit, causes padding issues */ }
            <header className="clearfix">
              <strong className="logo left">&nbsp;</strong>
              <nav className={'main-navigation right'}>&nbsp;</nav>
            </header>
          </div>
        )}
        <main className={token ? '' : 'main-no-margin-padding'}>
          {
            token && (
              <div className={classes.appWrapper}>
                <Navbars {...navProps} />
                <main className={classes.appRoot}>
                  <AppRouter />
                  <Snackbar />
                </main>
              </div>
            )
          }
          {
            !token && (
              <>
                <AppRouter />
                <Snackbar />
              </>
            )
          }

        </main>
      </div>
    </MuiThemeProvider>
  );
};

// Temp props. This will be handled in redux
const navProps = {
  navColor: 'transparent',
  currentTab: 'SYSTEM',
  setCurrentTab: () => null,
  parentCallback: () => null,
  activeNavColor: 'rgb(86, 227, 159)',
};

export default App;
