import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, Editor, RichUtils } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { InlineStyleControls, styleMap } from './rich-text-editor-styled-button';
import { getErrorForName } from './mui-formik-inputs';

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
};

const styles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  box: {
    border: '1px solid #b7b7b7',
  },
  error: {
    borderColor: 'red',
    color: 'red',
  },
  errorBorder: {
    borderCollapse: 'red',
  },
}));

const RichTextEditor = ({ name, onChange, value, label, errors, ...props }) => {
  const classes = styles();
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  React.useEffect(() => {
    if (value && value !== '' && value !== '<p><br></p>') {
      const s = EditorState.createWithContent(
        convertFromHTML(value.replace('<p><br></p>', '<p></p>')),
      );

      setEditorState(s);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [name]);

  const handleEditorChange = (value) => {
    setEditorState(value);
  };

  const toggleInlineStyle = (inlineStyle) => {
    const styled = RichUtils.toggleInlineStyle(editorState, inlineStyle);

    handleEditorChange(styled);
  };

  const triggerOnChange = () => {
    const html = stateToHTML(editorState.getCurrentContent());

    onChange(name, html);
  };

  return (
    <Box className={classes.container}>
      {
        !!label &&
        <Typography className={getErrorForName(errors, name) ? classes.error : ''}>{label || ''}</Typography>
      }
      <Box className={[classes.box, getErrorForName(errors, name) ? classes.errorBorder : ''].join(' ')}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <hr />
        <Editor
          editorState={editorState}
          onChange={handleEditorChange}
          customStyleMap={styleMap}
          blockStyleFn={getBlockStyle}
          onBlur={triggerOnChange}
        />
      </Box>
    </Box>
  );
};

export default RichTextEditor;
