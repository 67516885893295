import useBaseHook from './useBaseHook';
import { selectCarriers, getCarriers, selectCarrierStatus, selectTimestamp } from 'redux/slices/carrierSlice';

const useCarriers = () => {
  const {
    records: carriers,
    loading,
    revalidate,
  } = useBaseHook({
    recordsSelector: selectCarriers,
    fetchRecords: getCarriers,
    loadingSelector: selectCarrierStatus,
    timestampSelector: selectTimestamp,
  });

  return { carriers, loading, revalidate };
};

export default useCarriers;
