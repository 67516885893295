import * as yup from 'yup';

export const ArticleSchema = yup.object().shape({
  itemType: yup.string().required('Required'),
  descriptionInGerman: yup.string().required('Required'),
  descriptionInEnglish: yup.string().required('Required'),
  hsCode: yup.string().when('itemType', {
    is: 'Service',
    then: yup.string().nullable(),
    otherwise: yup.string().required('Required'),
  }),
  basalAnnexIX: yup.string().when('itemType', {
    is: 'Waste',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  basalCodeEu: yup.string().when('itemType', {
    is: 'Waste',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  avv: yup.string().when('itemType', {
    is: 'Waste',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  descriptionOfWaste: yup.string().nullable(),
});

export const ArticleFormAllowedFields = [
  'itemType',
  'descriptionInGerman',
  'descriptionInEnglish',
  'hsCode',
  'basalCodeEu',
  'basalAnnexIX',
  'avv',
  'descriptionOfWaste',
];
