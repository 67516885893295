import * as yup from 'yup';

export const CompanySchema = yup.object().shape({
  document_type_hack: yup.string().required('Required'),
  companyCode: yup.string().required('Required'),
  name1: yup.string().required('Required'),
  name2: yup.string().nullable(),
  phone1: yup.string().required('Required'),
  phone2: yup.string().nullable(),
  fax: yup.string().nullable(),
  email: yup.string().required('Required'),
  pinColor: yup.string().required('Required'),
  executiveDirector: yup.string().required('Required'),
  commercialRegisterNumber: yup.string().required('Required'),
  commercialRegister: yup.string().required('Required'),
  ustID: yup.string().required('Required'),
  taxNumber: yup.string().required('Required'),
  houseNumber: yup.string().required('Required'),
  city: yup.string().required('Required'),
  street: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  country: yup.string().required('Required'),
  website: yup.string().required('Required'),
  docEmail: yup.string().email('Invalid email').required('Required'),
  bccDocsEmail: yup.string().email('Invalid email').nullable(),
  purchaseEmailAddress: yup.string().email('Invalid email').nullable(),
  salesEmailAddress: yup.string().email('Invalid email').nullable(),
  transportEmailAddress: yup.string().email('Invalid email').nullable(),
  purchaseInvoiceEmailAddress: yup.string().email('Invalid email').nullable(),
  salesInvoiceEmailAddress: yup.string().email('Invalid email').nullable(),
  disponumber: yup.string().required('Required'),
  articleNumber: yup.string().required('Required'),
  username: yup.string().required('Required'),
  password: yup.string().required('Required'),
  host: yup.string().required('Required'),
  port: yup.number().required('Required'),
  security: yup.string().required('Required'),
  senderName: yup.string().required('Required'),
  invoiceNumber: yup.string().required('Required'),
  invoiceNumberPrefix: yup.string().nullable(),
  invoiceNumberSuffix: yup.string().nullable(),
  primaryColor: yup.string().nullable(),
  secondaryColor: yup.string().nullable(),
  scNumber: yup.string().nullable(),
  scNumberPrefix: yup.string().nullable(),
  scNumberSuffix: yup.string().nullable(),
  complementary: yup.string().nullable(),
  bank: yup.string().required('Required'),
  bankId: yup.string().required('Required'),
  signatures: yup.string().nullable(),
  companyLogo: yup.string().nullable(),
  companyAgb: yup.string().nullable(),
  documents: yup.object().shape({
    default: yup.object().shape({
      subject: yup.string().required('Required'),
      description: yup.string().required('Required'),
    }).required(),
    annex: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    purchase_confirmation: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    sales_confirmation: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    transport_information: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    transport_order: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    invoice: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    billing_confirmation: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    invoice_cancellation: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
    sales_contract: yup.object().shape({
      subject: yup.string().nullable(),
      description: yup.string().nullable(),
    }).required(),
  }).required(),
});

export const initialValues = {
  document_type_hack: 'default',
  companyCode: '',
  name1: '',
  name2: '',
  phone1: '',
  phone2: '',
  fax: '',
  email: '',
  pinColor: 'Red',
  executiveDirector: '',
  commercialRegisterNumber: '',
  commercialRegister: '',
  ustID: '',
  taxNumber: '',
  houseNumber: '',
  city: '',
  street: '',
  zipcode: '',
  country: '',
  website: '',
  docEmail: '',
  purchaseEmailAddress: '',
  salesEmailAddress: '',
  transportEmailAddress: '',
  purchaseInvoiceEmailAddress: '',
  salesInvoiceEmailAddress: '',
  disponumber: '',
  articleNumber: '',
  username: '',
  password: '',
  host: '',
  port: 3000,
  security: 'SSL',
  senderName: '',
  invoiceNumber: '',
  invoiceNumberPrefix: '',
  invoiceNumberSuffix: '',
  primaryColor: '',
  secondaryColor: '',
  scNumber: '',
  scNumberPrefix: '',
  scNumberSuffix: '',
  complementary: '',
  bank: '',
  bankId: '',
  signatures: '',
  companyLogo: '',
  companyAgb: '',
};

/**
 * fields that the api accepts
 * @type {string[]}
 */
export const CompanyFormAllowedFields = [
  'companyCode', 'name1', 'name2', 'phone1', 'phone2', 'fax', 'email', 'pinColor', 'executiveDirector',
  'commercialRegisterNumber', 'commercialRegister', 'ustID', 'taxNumber', 'houseNumber', 'city',
  'street', 'zipcode', 'country', 'website', 'docEmail', 'purchaseEmailAddress', 'salesEmailAddress',
  'transportEmailAddress', 'purchaseInvoiceEmailAddress', 'salesInvoiceEmailAddress', 'disponumber',
  'articleNumber', 'username', 'password', 'host', 'port', 'security', 'senderName', 'invoiceNumber',
  'invoiceNumberPrefix', 'invoiceNumberSuffix', 'primaryColor', 'secondaryColor', 'scNumber', 'scNumberPrefix',
  'scNumberSuffix', 'complementary', 'bank', 'bankId', 'signatures', 'companyLogo', 'companyAgb',
  'documents', 'bccDocsEmail',
];
