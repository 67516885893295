import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PATHS } from 'util/appConstants';
import { selectHarbor, selectHarborStatus, getHarbor, editHarbor } from 'redux/slices/harborSlice';
import HarborsForm from 'components/System/Harbors/Form';

const Edit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectHarborStatus);
  const harbor = useSelector(selectHarbor);

  useEffect(() => {
    if ((!harbor || harbor._id !== id) && id && !loading) {
      dispatch(getHarbor(id));
    }
  }, [harbor, id]);

  const handleEdit = async (payload) => {
    await dispatch(editHarbor(id, payload));

    history.push(PATHS.system.harbors.root);
  };

  if (loading || !harbor) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <HarborsForm
      initialValues={harbor}
      onSubmit={handleEdit}
    />
  );
};

export default Edit;
