export const basisForInvoiceOptions = [
  { label: 'Standard', value: 'Standard' },
  { label: 'Weightslip from Source', value: 'Weightslip from Source' },
  { label: 'Weightslip from Processor', value: 'Weightslip from Processor' },
];

export const partnerTypeOptions = [
  { label: 'Lade/Entladestelle', value: 'Lade/Entladestelle' },
  { label: 'Debitor/Kreditor', value: 'Debitor/Kreditor' },
];

export const salutation = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Dr', value: 'Dr' },
  { label: 'Other', value: 'Other' },
];

export const invoiceDeliveryMethodOptions = [
  { label: 'Email', value: 'Email' },
  { label: 'Mail', value: 'Mail' },
];

export const documentLanguageOptions = [
  { label: 'English', value: 'English' },
  { label: 'German', value: 'German' },
];

export const currencyOptions = [
  { label: 'EUR', value: 'EUR' },
  { label: 'CHF', value: 'CHF' },
  { label: 'DKK', value: 'DKK' },
  { label: 'USD', value: 'USD' },
];

export const personType = [
  { label: 'Intern', value: 'Intern' },
  { label: 'Extern', value: 'Extern' },
];

export const certifiedRecoveryOptions = [
  { label: 'R1', value: 'R1' },
  { label: 'R2', value: 'R2' },
  { label: 'R3', value: 'R3' },
  { label: 'R4', value: 'R4' },
  { label: 'R5', value: 'R5' },
  { label: 'R6', value: 'R6' },
  { label: 'R7', value: 'R7' },
  { label: 'R8', value: 'R8' },
  { label: 'R9', value: 'R9' },
  { label: 'R10', value: 'R10' },
  { label: 'R11', value: 'R11' },
  { label: 'R12', value: 'R12' },
  { label: 'R13', value: 'R13' },
];

export const paymentTermsOptions = [
  { label: 'Immediately', value: 'Immediately' },
  { label: '3 net', value: '3 net' },
  { label: '7 net', value: '7 net' },
  { label: '8 net', value: '8 net' },
  { label: '10 net', value: '10 net' },
  { label: '14 net', value: '14 net' },
  { label: '21 net', value: '21 net' },
  { label: '30 net', value: '30 net' },
  { label: 'Upfront', value: 'Upfront' },
  { label: 'Other', value: 'Other' },
];

export const personRole = [
  {
    internOptions: [
      { label: 'Office Staff', value: 'Office Staff' },
      { label: 'Account Manager', value: 'Account Manager' },
    ],
  },
  {
    externOptions: [
      { label: 'Contact For Field Staff', value: 'Contact For Field Staff' },
      { label: 'Waste Officer', value: 'Waste Officer' },
      { label: 'Finance', value: 'Finance' },
      { label: 'Official Truck Notification', value: 'Official Truck Notification' },
    ],
  },
];
