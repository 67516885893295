import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';

// Helpers
import { HARBORS_TABLE_COLUMNS } from 'constants/ui-constants';
import { defaultTableOptions, getLocalization, getColumns, getActions, mapTableData } from 'util/table-utils';
import { PATHS } from 'util/appConstants';
import { useHarbors } from 'hooks';

// Actions
import { deleteHarbor, searchFilter, setSearch } from 'redux/slices/harborSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'HARBORS';

const ArticlesList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, harbors, revalidate } = useHarbors();
  const user = useSelector(selectUser);
  const search = useSelector(searchFilter);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(
      async () => {
        await dispatch(deleteHarbor(rowData._id));
        revalidate();
      },
      {
        description: 'Are you sure?',
      },
    );
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.system.harbors.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );

  if (loading) return <div>Loading..</div>;
  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapTableData(harbors)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={getColumns(HARBORS_TABLE_COLUMNS, t)}
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.system.harbors.edit.replace(':id', rowData._id),
        )}
        localization={getLocalization(t)}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(ArticlesList);
