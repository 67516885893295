import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import momentLocaleEn from 'moment/locale/en-gb';
import {
  selectOrderStatus,
  datev,
} from 'redux/slices/ordersSlice';
import {
  ChipsInput,
} from 'components/Shared/mui-formik-inputs';
import { coreApi } from 'api/core';

const Form = ({ onSubmit }) => {
  const { t } = useTranslation('common');

  const formik = useFormik({
    initialValues: {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('moment').toDate(),
      orderIds: [],
    },
    onSubmit: async (values) => {
      onSubmit({
        from: moment(values.from).format('YYYY-MM-DD'),
        to: moment(values.to).format('YYYY-MM-DD'),
        orderIds: (values.orderIds || []).join(','),
      });
    },
    validate: (values) => {
      const errors = {};

      if (!values.orderIds.length) {
        if (!values.from) {
          errors.from = 'Required';
        }

        if (!values.to) {
          errors.to = 'Required';
        }
      }

      return errors;
    },
    // validateOnMount: true,
    validateOnChange: true,
  });

  const { values, errors, setFieldValue, handleSubmit, isSubmitting, isValid } = formik;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={momentLocaleEn}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD.MM.YYYY"
              margin="dense"
              label={t('Invoices sent from')}
              value={values.from || null}
              onChange={(date) => {
                setFieldValue('from', date ? date.toDate() : null);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD.MM.YYYY"
              margin="dense"
              label={t('Invoices sent to')}
              value={values.to || null}
              onChange={(date) => {
                setFieldValue('to', date ? date.toDate() : null);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChipsInput
              name="orderIds"
              value={values.orderIds}
              label="Order ID"
              errors={errors}
              required={false}
              setFieldValue={setFieldValue}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="outlined"
          size="small"
          disabled={isSubmitting || !isValid}
        >
          Export invoices
        </Button>
      </form>
    </MuiPickersUtilsProvider>
  );
};

const Datev = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectOrderStatus);

  const onSubmit = async (values) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    // a.download = '';
    a.target = '_blank';
    a.href = `${process.env.REACT_APP_API_URL}/datev/invoices?from=${values.from}&to=${values.to}&orderIds=${values.orderIds}`;
    a.click();
  };

  if (loading) {
    return (<div>Loading...</div>)
  }

  return (
    <div>
      <Form onSubmit={onSubmit} />
      <hr />
      <Box my={4}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          component="a"
          href={`${process.env.REACT_APP_API_URL}/datev/partners`}
          download
        >
          Download partners CSV
        </Button>
        &nbsp;
        <Button
          type="button"
          variant="contained"
          color="primary"
          component="a"
          href={`${process.env.REACT_APP_API_URL}/datev/carriers`}
          download
        >
          Download carriers CSV
        </Button>
      </Box>
    </div>
  );
};

export default Datev;
