import useBaseHook from './useBaseHook';
import {
  selectAccountManagers,
  fetchAccountManagers,
  selectAccountManagerStatus,
  selectAccountManagerTimestamp,
} from 'redux/slices/personSlice';

export const useAccountManagers = () => {
  const {
    records: accountManagers,
    loading,
    revalidate,
  } = useBaseHook({
    recordsSelector: selectAccountManagers,
    timestampSelector: selectAccountManagerTimestamp,
    loadingSelector: selectAccountManagerStatus,
    fetchRecords: fetchAccountManagers,
  });

  return { accountManagers, loading, revalidate };
};
