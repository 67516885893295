import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/contracts';
const initialState = {
  contracts: {},
  contract: null,
  loading: false,
  timestamp: null,
  search: ""
};

const contractsSlice = createSlice({
  name: 'newContracts',
  initialState,
  reducers: {
    setContracts: (state, action) => {
      const contracts = {};
      action.payload.forEach(contract => {
        contracts[contract.id] = contract;
      });
      state.contracts = contracts;
      state.timestamp = +new Date();
    },
    removeContract: (state, action) => {
      delete state.contracts[action.payload];
    },
    setCurrentContract: (state, action) => {
      state.contract = action.payload;
    },
    clearCurrentContract: state => {
      state.contract = null;
    },
    setContractsLoading: state => {
      state.loading = true;
    },
    setContractsReady: state => {
      state.loading = false;
    },
    resetContracts: (state) => {
      state.contracts = {};
      state.contract = null;
      state.timestamp = null;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setContracts,
  removeContract,
  setCurrentContract,
  clearCurrentContract,
  setContractsLoading,
  setContractsReady,
  resetContracts,
  setSearch
} = contractsSlice.actions;
export default contractsSlice.reducer;

export const fetchContractById = id => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setContractsLoading());

  try {
    const res = await coreApi.fetch(url);

    dispatch(setCurrentContract(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setContractsReady());
  }
};

export const fetchContracts = () => async dispatch => {
  dispatch(setContractsLoading());

  try {
    const contracts = await coreApi.fetch(baseUrl);
    dispatch(setContracts(contracts));

    return contracts;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setContractsReady());
  }
};

export const addContract = payload => async dispatch => {
  dispatch(setContractsLoading());
  const url = baseUrl + '/';

  try {
    const contract = await coreApi.post(url, payload);
    dispatch(resetContracts());
    dispatch(setShowMessage({
      description: 'Contract Added Successfully!',
      type: 'success',
    }));

    return contract;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding contract. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setContractsReady());
  }
};

export const editContract = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setContractsLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res.errors) {
      return res;
    }

    dispatch(resetContracts());
    dispatch(setShowMessage({
      description: 'Contract Updated Successfully',
      type: 'success',
    }));

    return res;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed updating contract. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setContractsReady());
  }
};

export const deleteContract = id => async dispatch => {
  dispatch(setContractsLoading());
  const url = baseUrl + `/${id}`;

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeContract(id));
      dispatch(setShowMessage({
        description: 'Contract Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed deleting contract. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setContractsReady());
  }
};

export const sendContractDocument = (contractId, payload, language) => async dispatch => {
  dispatch(setContractsLoading());
  const url = baseUrl + `/senddocument/${contractId}/${language}`;

  try {
    const res = await coreApi.post(url, payload);

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setContractsReady());
  }
};

const currentContractSelector = ({ newContracts }) => newContracts.contract;
const contractsSelector = ({ newContracts }) => newContracts.contracts;
const contractStatusSelector = ({ newContracts }) => newContracts.loading;
const searchSelector = ({ newContracts }) => newContracts.search;

export const selectCurrentContract = createSelector(currentContractSelector, contract => contract);
export const selectContracts = createSelector(contractsSelector, contracts => Object.values(contracts));
export const selectContractStatus = createSelector(contractStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ newContracts }) => newContracts.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
