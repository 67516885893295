import React from 'react';
import pick from 'lodash/pick';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Formik, Form, FieldArray } from 'formik';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import useArticles from 'hooks/useArticles';
import {
  Input,
  CountriesAutocomplete,
  AutocompleteSelect,
  Select,
  InputOnlyNumbers,
} from 'components/Shared/mui-formik-inputs';
import { WarehouseFormAllowedFields, WarehouseSchema } from './constants';
import OpeningHoursForm from 'components/Partners/components/OpeningHoursForm';
import { handleZipcodeChange } from 'util/index';

const WarehousesForm = ({ onSubmit, initialValues, action }) => {
  const { t, i18n } = useTranslation('common');
  const { articles } = useArticles();
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (e, v) => setTab(v);

  return (
    <Box>
      <Formik
        initialValues={{
          openingHours: [{
            end_day: '',
            end_time: '',
            end_time1: '',
            start_day: '',
            start_time: '',
            start_time1: '',
          }, {
            end_day: '',
            end_time: '',
            end_time1: '',
            start_day: '',
            start_time: '',
            start_time1: '',
          }],
          contactPersons: [],
          stocks: [],
          ...initialValues,
        }}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={WarehouseSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit(pick(values, WarehouseFormAllowedFields));
          } catch (err) {
            console.error(err);
            setSubmitting(false);
          }
        }}
      >
        {({
          values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, submitCount
        }) => {
          if (!submitCount) {
            handleBlur = null;
          }

          return (
            <Form noValidate>
              <Box py={2}>
                <AppBar position="static" color="inherit">
                  <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" textColor="secondary" indicatorColor="secondary">
                    <Tab label={t('Basic Data')} />
                    <Tab label={t('Opening Hours')} />
                    <Tab label={t('Stocks')} />
                  </Tabs>
                </AppBar>
              </Box>
              {
                tab === 0 && (
                  <Box p={2} boxShadow={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={3}>
                        <Input
                          name="name1"
                          value={values.name1}
                          label={'Name'}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Input
                          name="alias"
                          value={values.alias}
                          label={'Alias'}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Input
                          label='Street'
                          name='street'
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Input
                          label='House Number'
                          name='houseNumber'
                          value={values.houseNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Input
                          label='Zipcode'
                          name='zipcode'
                          value={values.zipcode}
                          onChange={handleZipcodeChange(handleChange, setFieldValue, i18n.language)}
                          onBlur={handleBlur}
                          errors={errors}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Input
                          label='City'
                          name='city'
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <CountriesAutocomplete
                          name="country"
                          label={'Country'}
                          setFieldValue={setFieldValue}
                          value={values.country}
                          errors={errors}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )
              }
              {
                tab === 1 && (
                  <OpeningHoursForm
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    hideTitle
                  />
                )
              }
              {
                tab === 2 && (
                  <Box p={2} boxShadow={3}>
                    <FieldArray
                      name="stocks"
                      render={({ push, remove }) => {
                        console.log(errors)
                        return (
                          <Box>
                            {
                              values.stocks.map((stock, i) => {
                                return (
                                  <Box key={i} display="flex">
                                    <Box flex={4} px={1}>
                                      <AutocompleteSelect
                                        onBlur={handleBlur}
                                        name={`stocks.${i}.article_id`}
                                        label={'Article'}
                                        errors={errors}
                                        value={stock.article_id}
                                        settings={{
                                          disableClearable: true,
                                          valueProp: '_id',
                                          labelProp: (o) => {
                                            if (i18n.language.toLowerCase() === 'en') {
                                              return `${o.descriptionInEnglish} ${o.avv ? `(AVV ${o.avv})` : ''}`;
                                            }

                                            return `${o.descriptionInGerman} ${o.avv ? `(AVV ${o.avv})` : ''}`;
                                          },
                                        }}
                                        onChange={(selected) => {
                                          setFieldValue(`stocks.${i}.article_id`, selected._id);
                                        }}
                                        options={articles}
                                        required
                                      />
                                    </Box>
                                    <Box flex={4} px={1}>
                                      <InputOnlyNumbers
                                        name={`stocks.${i}.amount`}
                                        value={stock.amount}
                                        label={'Amount'}
                                        errors={errors}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                      />
                                    </Box>
                                    <Box flex={4} px={1}>
                                      <Select
                                        name={`stocks.${i}.unit`}
                                        value={stock.unit}
                                        label={'Unit'}
                                        errors={errors}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        options={[{
                                          label: t('kg'),
                                          value: 'kg',
                                        }]}
                                        disabled
                                        required
                                      />
                                    </Box>
                                    <Box flex={1} px={1} pt={2} textAlign="center">
                                      <IconButton
                                        onClick={() => remove(i)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                )
                              })
                            }
                            <Box textAlign="right" mt={2}>
                              <IconButton
                                onClick={() => push({
                                  article_id: '',
                                  amount: '',
                                  unit: 'kg',
                                  total_price_operations: 0,
                                  total_price_sum: 0,
                                })}
                                color="primary"
                                size="medium"
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                )
              }
              <Button
                variant='contained'
                color='primary'
                startIcon={<CloudUploadIcon />}
                style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
                type='submit'
              >
                {t(`${action}`) + ' ' + t('Warehouse')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default WarehousesForm;
