import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(3),
  },
  themedBg: {
    backgroundColor: theme.palette.background.paper,
  },
}));
