import * as yup from 'yup';

export const ROLES_FOR_EXTERNAL_PERSON_CARRIER = [
  'Contact Accounting',
  'Contact Walking Floor',
  'Contact Tautliner',
  'Contact Tipper',
  'Contact Sea Container',
  'Waste Officer',
  'Container vehicle',
  'Silo'
];

export const ROLES_FOR_EXTERNAL_PERSON_PARTNER = [
  'Contact Purchase / Sale',
  'Waste Officer',
  'Finance',
  'Transportation Order'
];

export const PersonSchema = yup.object().shape({
  role: yup.array().min(1).required('Required'),
  salutation: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  surname: yup.string().required('Required'),
  position: yup.string().nullable(),
  responsibility: yup.string().nullable(),
  department: yup.string().nullable(),
  phone: yup.string().required('Required'),
  mobileNumber: yup.string().nullable(),
  fax: yup.string().optional(),
  comments: yup.string().optional(),
  emails: yup.array().test('emails', 'Required. Valid emails only', (value) => {
    return value.length && value.every((v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v));
  }),
});

export const defaultValues = {
  role: [],
  salutation: 'Mr',
  firstName: '',
  surname: '',
  position: '',
  responsibility: '',
  department: '',
  phone: '',
  mobileNumber: '',
  fax: '',
  comments: '',
  emails: [],
};
