import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import ContractDocConf from '../pdfToHtml/contractDocument';
import _ from 'lodash';

import { fetchContractUsers } from 'redux/slices/personSlice';

// Components
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
  Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input';

const defaultData = {
  contractConfirmation: { emails: [] },
};

const emailError = 'E-mail addresses are required';

const OnContractAddPopup = ({
  checked,
  loading,
  setLoading,
  contract,
  closeDialogCancel,
  closeDialogOk,
  sendOrderDocument,
  sendPopupOpen,
  data,
  createContractDocumentProps,
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('common');
  const [form, setForm] = useState(defaultData);
  const [users, setUsers] = useState({});
  const [errors, setErrors] = useState({});
  const [usersLoading, setUsersLoading] = useState(true);

  useEffect(() => {
    if (sendOrderDocument) {
      setForm({ ...form, ...defaultData });
    }
  }, [sendOrderDocument]);

  useEffect(() => {
    if (Object.keys(users).length === 0 && usersLoading) {
      const getUsers = async () => {
        const users = await dispatch(fetchContractUsers(contract._id));
        setUsers(users);
        setUsersLoading(false);
      };

      return getUsers();
    }
  }, [dispatch, users, setUsers]);

  const handleClose = () => {
    closeDialogCancel(reset);
  };

  const handleCloseOk = async () => {
    setLoading(true);
    let data = { ...form };
    let errors = validate(data);

    if (Object.keys(errors).length === 0) {
      await addDocs();
    } else {
      setLoading(false);
      setErrors(errors);
    }
  };

  const addDocs = async () => {
    let data = { ...form };
    let userData = data.form ? data.form : data;

    for (let key of Object.keys(userData)) {
      const docContent = await getDocContent(key);
      userData[key].doc = JSON.stringify(docContent);
    }

    setForm({ ...form, ...defaultData });
    closeDialogOk(userData, reset);
  };

  const validate = (data) => {
    let userData = data.form ? data.form : data;
    let errors = {};
    for (let key of Object.keys(userData)) {
      if (
        (!data[key].emails || data[key].emails.length === 0) &&
        checked[_.snakeCase(key)] &&
        checked[_.snakeCase(key)].status
      ) {
        errors[key] = emailError;
      }
    }

    return errors;
  };

  function reset() {
    setForm({ ...form, ...defaultData });
  }

  function handleAutocompleteChange(event, newValue, type) {
    let data = form;

    if (newValue.length > 0) {
      const docContent = getDocContent(type);
      let emails = data[type] && data[type].emails ? data[type].emails : [];
      let partnerEmails = data[type].partnerEmails ? data[type].partnerEmails : []; //adding an entry partner emails to group partner emails later

      let payload = {
        doc: JSON.stringify(docContent),
      };

      newValue.map((value) => {
        if (emails.length === 0) {
          emails = value.emails;
          partnerEmails = value.emails;
        } else {
          for (let email of value.emails) {
            emails.push(email);
            partnerEmails.push(email);
          }
        }
      });

      // removing duplicates email entries
      emails = new Set(emails);
      payload.emails = [...emails];
      partnerEmails = new Set(partnerEmails);
      payload.partnerEmails = [...partnerEmails];
      data[type] = payload;
    } else {
      data[type] = {};
      data[type]['emails'] = [];
      data[type]['partnerEmails'] = [];
    }

    setForm({ ...form, ...data });
  }

  async function getDocContent() {
    let html = '';
    html = await ReactDOMServer.renderToStaticMarkup(
      <ContractDocConf
        data={data}
        contract={contract}
        {...createContractDocumentProps(contract)}
      />,
    );
    return html;
  }

  function handleChange(chip, type) {
    let data = form;
    let emails = data[type]['emails'] ? data[type]['emails'] : [];
    emails.push(chip);
    data[type]['emails'] = emails;
    setForm({ ...form, ...data });
  }

  function handleDelete(chip, index, type) {
    let data = form;
    let emails = data[type]['emails'];
    emails.splice(index, 1);

    data[type]['emails'] = emails;
    setForm({ ...form, ...data });
  }

  return (
    <Dialog open={sendPopupOpen} onClose={handleClose} maxWidth={'lg'}>
      <AppBar position='static'>
        <Toolbar className='flex w-full'>
          <Typography variant='subtitle1' color='inherit'>
            {t('Select Person')}
          </Typography>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress color='secondary' />}

      <DialogContent style={{ minHeight: '400px', minWidth: '700px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Autocomplete
              style={{
                margin: 10,
                width: '100%',
                display: 'inline-flex',
              }}
              multiple
              limitTags={1}
              value={form.contractConfirmation.personNameWithResponsibility}
              options={users || []}
              onChange={(event, newValue) => {
                handleAutocompleteChange(event, newValue, 'contractConfirmation');
              }}
              getOptionLabel={(option) =>
                `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Contract Confirmation')}
                  autoComplete={'off'}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: `off`,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ChipInput
              classes={{
                root: 'w-100',
                input: 'mg-top-20',
                chipContainer: 'mg-top-20',
              }}
              value={form.contractConfirmation.emails}
              onAdd={(chip) => handleChange(chip, 'contractConfirmation')}
              onDelete={(chip, index) => handleDelete(chip, index, 'contractConfirmation')}
              placeholder={t('E-mail addresses*')}
              error={errors.contractConfirmation !== undefined}
            />
            {errors.contractConfirmation !== undefined && (
              <p style={{ color: 'red', margin: 0 }}>{t(errors.contractConfirmation)}</p>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'red' }}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleCloseOk} color='primary' disabled={loading}>
          {t('Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnContractAddPopup;
