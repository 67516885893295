import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PATHS } from 'util/appConstants';
import { selectArticle, selectArticleStatus, getArticle, editArticle } from 'redux/slices/articleSlice';
import CompanyForm from 'components/System/Articles/form';

const currentAction = 'EDIT';

const EditArticle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectArticleStatus);
  const article = useSelector(selectArticle);

  useEffect(() => {
    if (!article && id) {
      dispatch(getArticle(id));
    }
  }, [article, id]);

  const handleEditArticle = async (payload) => {
    await dispatch(editArticle(id, payload));

    history.push(PATHS.system.articles.root);
  };

  if (loading || !article) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <CompanyForm
      initialValues={article}
      onSubmit={handleEditArticle}
      action={currentAction}
    />
  );
};

export default EditArticle;
