import Api from './core';

class TaxApi extends Api {
  fetchTaxes = async callback => {
    try {
      const data = await this.fetch('/tax');

      return callback(data);
    } catch (err) {
      console.log(err);
    }
  };

  importTax = async (payload, callback) => {
    const data = await this.post('/tax/import', payload);

    return callback(data);
  };
}

export const taxApi = new TaxApi(process.env.REACT_APP_API_URL || '');
