import moment from 'moment';
import German from '../translations/de-DE.json';
import { formatNumberToEn, formatDecimals } from './common';

function getGermanTermValue(term, language) {
  const german = {
    Immediately: 'sofort',
    '8 net': '8 Tage netto',
    '10 net': '10 Tage netto',
    '14 net': '14 Tage netto',
    '21 net': '21 Tage netto',
    '30 net': '30 Tage netto',
    Upfront: 'im Voraus',
    Other: 'Andere',
  };

  return language === 'en' ? term : german[term];
}

export function getPaymentTerms(partner, language) {
  const terms = partner.paymentTerms;

  if (language !== 'de') {
    if (!terms || terms.toLowerCase() === 'immediately') {
      return `Payment term immediately after invoice date, without deduction.`;
    }

    if (terms.toLowerCase() === 'upfront') {
      return 'Payment term: 100% in advance';
    }

    if (terms.toLowerCase() === 'other') {
      return `Payment term ${partner.paymentDays || '1'} days after invoice date, without deduction.`;
    }

    const [days] = terms.split(' ');

    return `Payment term ${days} days net after invoice date, without deduction.`;
  }

  if (language === 'de') {
    if (!terms || terms.toLowerCase() === 'immediately') {
      return `Zahlungsziel sofort nach Rechnungsdatum, ohne Abzug.`;
    }

    if (terms.toLowerCase() === 'upfront') {
      return 'Zahlungsziel 100% im Voraus.';
    }

    if (terms.toLowerCase() === 'other') {
      return `Zahlungsziel ${partner.paymentDays || '1'} Tage netto nach Rechnungsdatum, ohne Abzug.`;
    }

    const [days] = terms.split(' ');

    return `Zahlungsziel ${days} Tage netto nach Rechnungsdatum, ohne Abzug.`;
  }

  return 'N/A';
}

export function getIncotermAddress(incoterm, source, processor) {
  let address = '';
  if (incoterm === 'EXW') {
    address = `${incoterm} ${source.street} ${source.houseNumber}, ${source.zipcode} ${source.city}, ${source.country}`;
  } else if (incoterm === 'DAP' || incoterm === 'FAS' || incoterm === 'DDP') {
    address = `${incoterm} ${processor.street} ${processor.houseNumber}, ${processor.zipcode} ${processor.city}, ${processor.country}`;
  }

  return address;
}

export function getOpeningHours(data, language) {
  let openingHours = "<p class={'mg-0 padding-top-5'}>";
  let and = language === 'de' ? 'und' : 'and';
  if (data) {
    if (data.start_day !== '' || data.end_day !== '') {
      openingHours += `<b>${language === 'de' ? getGermanDay(data.start_day) : data.start_day}  ${
        data.end_day !== ''
          ? `- ${language === 'de' ? getGermanDay(data.end_day) : data.end_day}`
          : ''
      }:&nbsp;</b>`;
    }

    if (data.start_time !== '00:00' || data.end_time !== '00:00') {
      openingHours += `${data.start_time !== '00:00' ? data.start_time : ''} ${
        data.end_time !== '00:00'
          ? data.start_time != '00:00'
            ? `- ${data.end_time}`
            : data.end_time
          : ''
      } `;
    }

    if (data.start_time1 !== '00:00' || data.end_time1 !== '00:00') {
      openingHours += `${and} ${data.start_time1 !== '00:00' ? data.start_time1 : ''} ${
        data.end_time1 !== '00:00'
          ? data.start_time1
            ? `- ${data.end_time1}`
            : data.end_time1
          : ''
      } `;
    }
  }
  openingHours += '</p>';

  return openingHours;
}

export function getGermanDay(day) {
  let days = {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  };

  return days[day.toLowerCase()];
}

export function getRecipient(props, type, language) {
  let address = '';
  let deviatingInvoiceAddressProcessor = props.currentEntity.deviatingInvoiceAddressProcessor
    ? props.currentEntity.deviatingInvoiceAddressProcessor
    : {};
  let deviatingInvoiceAddressSource = props.currentEntity.deviatingInvoiceAddressSource
    ? props.currentEntity.deviatingInvoiceAddressSource
    : {};

  let sourceDeviatingAddress =
    '<h4>' +
    `${deviatingInvoiceAddressSource.name1}` +
    ' <br />' +
    `${deviatingInvoiceAddressSource.street} ${deviatingInvoiceAddressSource.houseNumber}` +
    ' <br />' +
    `${deviatingInvoiceAddressSource.zipcode} ${deviatingInvoiceAddressSource.city}` +
    '</h4>' +
    '<h4>' +
    `${getCountry(deviatingInvoiceAddressSource.country, language)}` +
    '</h4>';

  let processorDeviatingAddress =
    '<h4>' +
    `${deviatingInvoiceAddressProcessor.name1}` +
    ' <br />' +
    `${deviatingInvoiceAddressProcessor.street} ${deviatingInvoiceAddressProcessor.houseNumber}` +
    ' <br />' +
    `${deviatingInvoiceAddressProcessor.zipcode} ${deviatingInvoiceAddressProcessor.city}` +
    '</h4>' +
    '<h4>' +
    `${getCountry(deviatingInvoiceAddressProcessor.country, language)}` +
    '</h4>';

  let sourceAddress =
    '<h4>' +
    `${props.sourceData.locationName ? props.sourceData.locationName : props.sourceData.name1}` +
    ' <br />' +
    `${props.sourceData.street} ${props.sourceData.houseNumber}` +
    ' <br />' +
    `${props.sourceData.zipcode} ${props.sourceData.city}` +
    '</h4>' +
    '<h4>' +
    `${getCountry(props.sourceData.country, language)}` +
    '</h4>';

  let processorAddress =
    '<h4>' +
    `${
      props.processorData.locationName
        ? props.processorData.locationName
        : props.processorData.name1
    }` +
    ' <br />' +
    `${props.processorData.street} ${props.processorData.houseNumber}` +
    ' <br />' +
    `${props.processorData.zipcode} ${props.processorData.city}` +
    '</h4>' +
    '<h4>' +
    `${getCountry(props.processorData.country, language)}` +
    '</h4>';

  if (type === 'purchase_confirmation') {
    if (props.currentEntity.deviatingInvoiceAddressSource) {
      address = sourceDeviatingAddress;
    } else {
      address = sourceAddress;
    }
  } else if (type === 'sales_confirmation') {
    if (props.currentEntity.deviatingInvoiceAddressProcessor) {
      address = processorDeviatingAddress;
    } else {
      address = processorAddress;
    }
  } else if (type === 'transportation') {
    address = props.carrierData
      ? '<div class="font-12 bold">' +
        `${props.carrierData.name1}` +
        '</div>\n' +
        '<div class="font-12 bold">' +
        `${props.carrierData.street} ${props.carrierData.houseNumber}` +
        '</div>\n' +
        '<div class="font-12 bold">' +
        `${props.carrierData.zipcode} ${props.carrierData.city}` +
        '</div>\n' +
        '<div class="font-12 bold" style="margin-top: 10px">' +
        `${getCountry(props.carrierData.country, language)}` +
        '</div>'
      : '';
  }

  return address;
}

export function getDates(data, process = null) {
  let date = '';

  if (process === 'Delivery after arrangement' || process === 'Pickup after arrangement') {
    return '';
  }

  if (data) {
    if (data.startDate) {
      date += moment(data.startDate).format('DD.MM.YYYY');
    }

    if (data.endDate && (process === 'Delivery Period' || process === 'Pickup Period')) {
      date += ` - ${moment(data.endDate).format('DD.MM.YYYY')}`;
    }
  }
  return date;
}

export function getGermanCollectionValues(value) {
  const data = {
    'Pickup From': 'ab',
    'Pickup On': 'am',
    'Pickup Period': 'zwischen',
    'Pickup after arrangement': 'Abholung nach Absprache',
    'Delivery After': 'ab',
    'Delivery On': 'am',
    'Delivery Period': 'zwischen',
    'Delivery after arrangement': 'Lieferung nach Absprache'
  };

  return data[value];
}

export function getCountry(country, language) {
  return language === 'en' ? country : German[country];
}

export function getCompanyAddress(company, language) {
  return `${company.street} ${company.houseNumber}, ${company.zipcode} ${
    company.city
  }, ${getCountry(company.country, language)}`;
}

export function getSalutation(salutation, language) {
  return language === 'en' ? salutation : German[salutation];
}

export function getGermanNumber(number, type = 'price') {
  let fractionDigits = type === 'price' ? 2 : number.toString().indexOf('.') > -1 ? 3 : 2;

  return parseFloat(number).toLocaleString('de-DE', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

export function getPartnerPurchaseConf(source, processor, order) {
  let partner = {};

  switch (order.incotermSource) {
    case 'DAP':
      partner = {
        partner: processor,
        heading: 'Entladestelle',
        reference: 'Entladereferenz',
        date: 'Entladedatum',
        headingEnglish: 'Unloading point',
        referenceEnglish: 'Unloading Ref',
        dateEnglish: 'Unloading date',
        referenceValue: order.loadingReferenceProcessor,
        referenceDate: order.deliveryDate,
        collection: order.deliveryProcess,
      };
      break;
    default:
      partner = {
        partner: source,
        heading: 'Ladestelle',
        reference: 'Ladereferenz',
        date: 'Ladedatum',
        headingEnglish: 'Loading point',
        referenceEnglish: 'Loading ref',
        dateEnglish: 'Loading date',
        referenceValue: order.loadingReferenceSource,
        referenceDate: order.pickupDate,
        collection: order.collectionSource,
      };
  }

  return partner;
}

export function getUnloadingPartner(source, processor, order, incoterm = 'incotermProcessor') {
  let partner = {};

  switch (order[incoterm]) {
    case 'EXW':
      partner = {
        partner: source,
        heading: 'Ladestelle',
        reference: 'Ladereferenz',
        date: 'Ladedatum',
        transport: 'Ladedaten',
        headingEnglish: 'Loading point',
        referenceEnglish: 'Loading ref',
        dateEnglish: 'Loading date',
        transportEnglish: 'Öffnungszeiten',
      };
      break;
    case 'DAP':
      partner = {
        partner: processor,
        heading: 'Entladestelle',
        reference: 'Entladereferenz',
        date: 'Entladedatum',
        transport: 'Entladedaten',
        headingEnglish: 'Unloading point',
        referenceEnglish: 'Unloading ref',
        dateEnglish: 'Unloading date',
        transportEnglish: 'Unloading data',
      };
      break;
    case 'FAS':
      partner = {
        partner: processor,
        heading: 'Entladestelle',
        reference: 'Entladereferenz',
        date: 'Entladedatum',
        transport: 'Entladedaten',
        headingEnglish: 'Unloading point',
        referenceEnglish: 'Unloading ref',
        dateEnglish: 'Unloading date',
        transportEnglish: 'Unloading data',
      };
      break;
    case 'CFR':
      partner = {
        partner: processor,
        heading: 'Entladestelle',
        reference: 'Entladereferenz',
        date: 'Entladedatum',
        transport: 'Entladedaten',
        headingEnglish: 'Unloading point',
        referenceEnglish: 'Unloading ref',
        dateEnglish: 'Unloading date',
        transportEnglish: 'Unloading data',
      };
      break;
    case 'DDP':
      partner = {
        partner: processor,
        heading: 'Entladestelle',
        reference: 'Entladereferenz',
        date: 'Entladedatum',
        transport: 'Entladedaten',
        headingEnglish: 'Unloading point',
        referenceEnglish: 'Unloading ref',
        dateEnglish: 'Unloading date',
        transportEnglish: 'Unloading data',
      };
      break;
    default:
  }

  return partner;
}

export function getLanguageAcronym(language) {
  return language === 'English' ? 'en' : 'de';
}

export function getArticleDetails(article, type) {
  let response = { price: '', weight: '', amount: '' };

  response.price = !isNaN(parseFloat(article[`${type}_price`]))
    ? parseFloat(formatNumberToEn(article[`${type}_price`]))
    : 0;
  response.weight = !isNaN(parseFloat(article[`${type}_weight`]))
    ? parseFloat(formatNumberToEn(article[`${type}_weight`]))
    : 0;
  response.amount = !isNaN(parseFloat(article[`${type}_amount`]))
    ? parseFloat(formatNumberToEn(article[`${type}_amount`]))
    : 0;

  return response;
}

export const sumArticles = (articles, type = 'source') => {
  const total = articles.reduce((acc, article) => {
    const { price, weight } = getArticleDetails(article, type);

    return acc + (price * weight);
  }, 0);

  return formatDecimals(total);
};

export const getCurrency = (articles) => {
  return articles.length > 0
    ? articles[0].source_currency === 'Euro'
      ? '€'
      : '$'
    : '';
};

export const shouldDisplayArticle = (article, dataSource) => {
  return (
    (article.source_weight && article.source_cashflow === 'Supplementary Payment' && dataSource === 'sourceData') ||
    (article.processor_weight && article.processor_cashflow === 'Compensation' && dataSource === 'processorData')
  );
};
