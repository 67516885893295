import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationUS from 'translations/en-US.json';
import translationDE from 'translations/de-DE.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  resources: {
    en: {
      common: translationUS,
    },
    de: {
      common: translationDE,
    },
  },
})
  .catch(err => console.error(err));

export default i18n;
