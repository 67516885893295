import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PATHS } from 'util/appConstants';
import { selectWarehouse, selectWarehouseStatus, getWarehouse, editWarehouse } from 'redux/slices/warehouseSlice';
import WarehouseForm from 'components/System/Warehouses/Form';

const currentAction = 'EDIT';

const EditWarehouse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectWarehouseStatus);
  const warehouse = useSelector(selectWarehouse);

  useEffect(() => {
    if ((!warehouse || warehouse.id !== id) && id && !loading) {
      dispatch(getWarehouse(id));
    }
  }, [warehouse, id]);

  const handleEditWarehouse = async (payload) => {
    await dispatch(editWarehouse(id, payload));

    history.push(PATHS.system.warehouses.root);
  };

  if (loading || !warehouse) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <WarehouseForm
      initialValues={warehouse}
      onSubmit={handleEditWarehouse}
      action={currentAction}
    />
  );
};

export default EditWarehouse;
