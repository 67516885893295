import React from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import efactList from 'assets/efact/efact.json';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Helpers
import { getEfactOptions, PartnerSchema, allowedFields } from './constants';
import { PATHS, formActions } from 'util/appConstants';
import pick from 'lodash/pick';

// Actions
import { selectPartnersStatus } from 'redux/slices/partnerSlice';

// Components
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  BankingForm,
  BasicDataForm,
  OpeningHoursForm,
  AdditionalLocationsForm,
} from './components';
import ContactPersons from 'components/Person';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: 10,
    backgroundColor: 'white',
  },
  buttonSubmit: {
    color: 'green',

    '&:hover': {
      color: '#fff',
    },
  },
  buttonCancel: {
    color: 'red',
  },
}));


const efactOptions = getEfactOptions(efactList);

const PartnerForm = ({ action, initialValues, handleAddPartner, handleEditPartner }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const history = useHistory();
  const loading = useSelector(selectPartnersStatus);

  if (loading) return <div>Loading..</div>;
  return (
    <div className={classes.root}>
      <Formik
        enableReinitialize={true}
        validationSchema={PartnerSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            let payload = pick(values, allowedFields);
            if (typeof payload.pod === 'string') {
              if (!payload.pod.length) {
                payload.pod = [];
              } else {
                payload.pod = [payload.pod];
              }
            }

            // When editing a partner, the API is adding additional fields to the location objects in the
            // "filterNewAndOldLocations" function (__v, id, partnerId), but it doesn't accept them back
            // when editing the partner again... Need to check if they
            // are needed at all in the first place
            payload.locations = values.locations.map(n => ({
              _id: n._id || null,
              name: n.name,
              city: n.city,
              street: n.street,
              zipcode: n.zipcode,
              country: n.country,
              houseNumber: n.houseNumber,
            }));

            if (action === 'ADD') {
              return await handleAddPartner(payload);
            }

            if (action === 'EDIT') {
              return await handleEditPartner(payload);
            }
          } catch (err) {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          dirty,
          submitCount,
        }) => {
          if (!submitCount) {
            handleBlur = null;
          }

          console.log(errors);

          return (
            <Form noValidate>
              <Prompt when={dirty} message={t('unsaved_changes_message')} />
              <BasicDataForm
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                efactOptions={efactOptions}
              />
              <OpeningHoursForm
                values={values}
                errors={errors}
                handleChange={handleChange}
              />
              {values.partnerType === 'Debitor/Kreditor' && (
                <BankingForm
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              )}
              <AdditionalLocationsForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              {
                action === formActions.edit && (
                  <ContactPersons
                    contactPersons={values.contactPersons}
                    type="PARTNER"
                    initialValues={{
                      partnerId: values._id || values.id,
                    }}
                    setFieldValue={setFieldValue}
                  />
                )
              }
              <Button
                className={clsx(classes.button, classes.buttonSubmit)}
                variant='contained'
                color='primary'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {t('Save Partner')}
              </Button>
              <Button
                className={clsx(classes.button, classes.buttonCancel)}
                variant='contained'
                startIcon={<CancelIcon />}
                onClick={() => {
                  resetForm({});
                  return history.push(PATHS.partners.root);
                }}
              >
                {t('Cancel')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

PartnerForm.propTypes = {
  action: PropTypes.oneOf(['ADD', 'EDIT']).isRequired,
  initialValues: PropTypes.object.isRequired,
  handleAddPartner: PropTypes.func,
  handleEditPartner: PropTypes.func,
};

export default PartnerForm;
