import React from 'react';
import { FieldArray } from 'formik';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  Input,
} from 'components/Shared/mui-formik-inputs';
import { coreApi } from 'api/core';
import { fixPreviousBrokenDates } from 'util/index';

const IncomingDocumentsSection = ({
  name,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  label,
  relationalLabel,
  relationalValue,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const { t } = useTranslation();

  const handleSelected = (file, i) => {
    if (selectedIndex !== null && selectedIndex !== i) {
      setSelectedIndex(i);
      return setSelectedFiles([file]);
    }

    setSelectedIndex(i);

    if (selectedFiles.includes(file)) {
      return setSelectedFiles(selectedFiles.filter((f) => f !== file));
    }

    setSelectedFiles(selectedFiles.concat([file]));
  };

  const handleFileDownload = async (name) => {
    const data = await coreApi.fetch(
      `/orders/download-file/${name}`,
      'GET',
      {},
      { responseType: 'blob' },
    );

    return download(data, name);
  };

  const onDrop = async (files, index) => {
    const filenames = [];

    for (let file in files) {
      const form = new FormData();
      form.append('files', files[file]);

      const data = await coreApi.post('/orders/upload', form);

      filenames.push(data.fileName);
    }

    const newArray = (values.weightslips[name][index].files || []).concat(filenames);
    setFieldValue(`weightslips.${name}.${index}.files`, newArray);
  };

  return (
    <FieldArray name={`weightslips.${name}`}>
      {({ remove, push }) => (
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography style={{ flex: 1 }}><b>{t(label)}</b></Typography>
              <Box flex={1} textAlign="right">
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  type="button"
                  onClick={() => {
                    push({
                      invoiceDate: '',
                      invoiceNumber: '',
                      files: [],
                    })
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          {
            values.weightslips[name].map((row, i) => (
              <Grid item xs={12} key={i} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Input
                    label={relationalLabel}
                    name={`weightslips.${name}.${i}.dummy`}
                    value={relationalValue}
                    errors={{}}
                    onChange={() => {}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Input
                    label={'Invoice number'}
                    name={`weightslips.${name}.${i}.invoiceNumber`}
                    value={row.invoiceNumber}
                    errors={errors}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD.MM.YYYY"
                    margin="dense"
                    label={t('Invoice date')}
                    value={fixPreviousBrokenDates(row.invoiceDate)}
                    onChange={(date) => {
                      setFieldValue(`weightslips.${name}.${i}.invoiceDate`, date ? date.toDate() : null);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Dropzone
                    onDrop={(event) => onDrop(event, i)}
                    maxSize={10 * 1024 * 1024}
                    onDropRejected={() => alert(t('file_size_limit'))}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box>
                        <div
                          style={{ width: '100%', cursor: 'pointer' }}
                          {...getRootProps({ className: 'dropzone' })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            {t(
                              "Drag 'n' drop invoice doc here, or click to select file",
                            )}
                          </p>
                        </div>
                        <Box>
                          {
                            row.files && row.files.map((file, fileIndex) => (
                              <div className="display-flex" key={fileIndex}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        e.stopPropagation();

                                        handleSelected(file, i);
                                      }}
                                      checked={selectedFiles.includes(file)}
                                    />
                                  }
                                  label={(
                                    <span
                                      className={'file-link'}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();

                                        return handleFileDownload(file);
                                      }}
                                    >
                                      {file}
                                    </span>
                                  )}
                                />
                              </div>
                            ))
                          }
                          {
                            !!selectedFiles.length && selectedIndex === i && (
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                type="button"
                                onClick={() => {
                                  setFieldValue(`weightslips.${name}.${i}.files`, row.files.filter((f) => !selectedFiles.includes(f)));
                                  setSelectedFiles([]);
                                  setSelectedIndex(null);
                                }}
                                style={{ borderColor: 'red', color: 'red', marginTop: '5px' }}
                                fullWidth
                              >
                                <DeleteIcon style={{ color: 'red' }} /> {t('Delete selected files')}
                              </Button>
                            )
                          }
                        </Box>
                      </Box>
                    )}
                  </Dropzone>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    type="button"
                    onClick={() => {
                      remove(i);
                      setSelectedIndex(null);
                    }}
                    fullWidth
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      )}
    </FieldArray>
  );
};

export default IncomingDocumentsSection;
