import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { initialValues } from 'components/Partners/constants';
import { PATHS } from 'util/appConstants';
import merge from 'lodash/merge';
import omit from 'lodash/omit';

// Actions
import {
  editPartner,
  selectPartner,
  fetchPartnerById,
  selectPartnersStatus,
} from 'redux/slices/partnerSlice';

// Components
import PartnerForm from 'components/Partners/PartnerForm';


const currentAction = 'EDIT';

const EditPartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectPartnersStatus);
  const { id: partnerId } = useParams();
  const currentPartner = useSelector(selectPartner);

  useEffect(() => {
    if ((!currentPartner || currentPartner.id !== partnerId) && partnerId && !loading) {
      dispatch(fetchPartnerById(partnerId));
    }
  }, [currentPartner, partnerId]);

  const handleEditPartner = async payload => {
    const res = await dispatch(editPartner(partnerId, payload));

    if (typeof res !== 'string' || res && !res.errors) {
      return history.push(PATHS.partners.root);
    }
  };

  if (loading) return <div>Loading..</div>;

  return (
    <PartnerForm
      action={currentAction}
      initialValues={{
        ...initialValues,
        ...omit(currentPartner, ['partnerNumber', 'signatures', '__v', '_id', 'updatedAt', 'createdAt', 'address']),
      }}
      handleEditPartner={handleEditPartner}
    />
  );
};

export default EditPartner;
