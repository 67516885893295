import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
import clsx from 'clsx';

// Helpers
import {
  getColumns,
  mapTableData,
  getLocalization,
} from 'util/table-utils';
import { TAX_MATRIX_COLUMNS } from 'constants/ui-constants';
import { taxApi } from 'api';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '14px',
  },
  hiddenInput: {
    display: 'none',
  },
}));

const getKey = str => str.replace(/\u21b5/g, '')
  .toLowerCase()
  .replace(/\s+/g, '_');

const TaxMatrix = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!taxes.length && loading) {
      return taxApi.fetchTaxes(callback);
    }
  }, [taxes, loading]);

  const callback = (taxes) => {
    setLoading(false)
    setTaxes(taxes);
  };

  const importCallback = taxes => {
    setTaxes(taxes);
  };

  const handleFileChange = e => {
    const { files: inputFiles } = e.target;

    if (!inputFiles) return;

    const files = Object.keys(inputFiles).map(file => inputFiles[file]);
    const reader = new FileReader();
    const readAsBinary = !!reader.readAsBinaryString;

    reader.onload = async event => {
      const { result } = event.target;
      const wb = XLSX.read(result, {
        type: readAsBinary ? 'binary' : 'array',
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);

      let newData = [];
      for (let row of data) {
        let d = {};

        for (let key of Object.keys(row)) {
          d[getKey(key)] = String(row[key]);
        }

        newData.push(d);
      }

      await taxApi.importTax(newData, importCallback);
    };

    if (readAsBinary) {
      reader.readAsBinaryString(files[0]);
    } else {
      reader.readAsArrayBuffer(files[0]);
    }
  };

  if (loading) return <div>Loading..</div>;

  return (
    <>
      <div className={classes.inputWrapper}>
        <input
          id='tax-file-upload'
          type='file'
          className={clsx('hidden', classes.hiddenInput)}
          onChange={(e) => handleFileChange(e)}
        />
        <InputLabel htmlFor='tax-file-upload' className='custom-file-upload'>
          <Button component='span' variant='contained' color='secondary'>
            {t('Upload File')}
          </Button>
        </InputLabel>
      </div>

      <div className={'custom-table-styles'}>
        <MaterialTable
          title=''
          data={mapTableData(taxes)}
          columns={getColumns(TAX_MATRIX_COLUMNS, t)}
          options={{ filtering: true }}
          localization={getLocalization(t)}
          minRows={0}
        />
      </div>
    </>
  );
};

export default TaxMatrix;
