import * as yup from 'yup';

export const ContractSchema = yup.object().shape({
  // Partners
  buyer: yup.string().required('Required'),
  shipper: yup.string().required('Required'),
  consignee: yup.string().required('Required'),
  notify: yup.string().required('Required'),
  endUser: yup.string().required('Required'),
  commercial: yup.string().required('Required'),
  // Shipping
  pod: yup.string().nullable(),
  pol: yup.mixed().when('incoterms', {
    is: 'FAS',
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  incoterms: yup.string().required('Required'),
  documents: yup.array().min(1).required('Required'),
  dtch: yup.string().required('Required'),
  lots: yup.string().required('Required'),
  noOfLots: yup.string().when('lots', {
    is: 'Multiple Lots',
    then: yup.string().nullable(),
    otherwise: yup.string(),
  }),
  buyerContractPersons: yup.string().nullable(),
  // Payment
  bank: yup.string().required('Required'),
  bankId: yup.string().required('Required'),
  paymentTerm: yup.string().required('Required'),
  // Articles
  articles: yup.array().of(yup.object().shape({
    article_id: yup.string().required('Required'),
    article_name: yup.string().nullable(),
    price: yup.string().nullable(),
    currency: yup.string().nullable(),
    unit: yup.string().nullable(),
    loads: yup.string().nullable(),
    container_type: yup.string().nullable(),
    deviating_article_name: yup.string().required('Required'),
    weight_per_load: yup.string().when('loads', (loads) => {
      if (loads && loads.length > 1) {
        return yup.string().required('Required');
      } else {
        return yup.string().nullable();
      }
    }),
  })).required('Required'),
});

export const defaultValues = {
  // Partners
  buyer: '',
  buyerId: null,
  shipper: '',
  shipperId: null,
  consignee: 'Same as buyer',
  consigneeId: null,
  notify: 'Same as buyer',
  notifyId: null,
  endUser: 'Same as buyer',
  endUserId: null,
  commercial: '',
  commercialId: null,
  buyerContactPersonId: null,
  // Shipping
  pod: '',
  pol: '',
  incoterms: '',
  documents: [],
  dtch: '',
  lots: '',
  noOfLots: '',
  // Payment
  bank: '',
  bankId: '',
  paymentTerm: '',
  // Articles
  articles: [{
    article_id: '',
    article_name: '',
    price: '',
    currency: 'Euro',
    unit: 't',
    loads: '',
    container_type: '40ft HC',
    deviating_article_name: '',
    weight_per_load: '',
  }],
};

export const allowedFields = [
  'buyer',
  'buyerId',
  'shipper',
  'shipperId',
  'consignee',
  'consigneeId',
  'notify',
  'notifyId',
  'endUser',
  'endUserId',
  'commercial',
  'commercialId',
  'buyerContactPersonId',
  'pod',
  'pol',
  'incoterms',
  'documents',
  'dtch',
  'lots',
  'noOfLots',
  'bank',
  'bankId',
  'paymentTerm',
  'articles',
];
