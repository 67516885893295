import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { partnerTypeOptions, certifiedRecoveryOptions } from 'components/Shared/selectInputOptions';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  Input,
  InputOnlyNumbers,
  CountriesAutocomplete,
  SelectAC,
  Select,
  AutocompleteSelect,
  ChipsInput,
} from 'components/Shared/mui-formik-inputs';
import { handleZipcodeChange } from 'util/index';

const useStyles = makeStyles(() => ({
  title: {
    paddingBottom: 30,
  },
  chipInput: {
    minWidth: 300,
    margin: '8px 10px',
  },
}));

const BasicDataForm = ({
  values,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  efactOptions,
}) => {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  return (
    <Box boxShadow={3} p={2}>
      <Typography className={classes.title} variant='h4'>
        {t('Basic Data')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            label='Partner Type'
            name='partnerType'
            value={values.partnerType}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            options={[{ value: '', label: '' }].concat(partnerTypeOptions.map((o) => ({ value: o.value, label: t(o.label) })))}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='name1'
            value={values.name1}
            label='Name1'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='name2'
            value={values.name2}
            label='Name2'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='street'
            value={values.street}
            label='Street'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='houseNumber'
            value={values.houseNumber}
            label='House Number'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='zipcode'
            value={values.zipcode}
            label='Zipcode'
            onChange={handleZipcodeChange(handleChange, setFieldValue, i18n.language)}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='city'
            value={values.city}
            label='City'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <CountriesAutocomplete
            name='country'
            value={values.country}
            errors={errors}
            label='Country'
            required={true}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <InputOnlyNumbers
            name='phone1'
            value={values.phone1}
            label='Phone 1'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <InputOnlyNumbers
            name='phone2'
            value={values.phone2}
            label='Phone 2'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <InputOnlyNumbers
            name='mobile'
            value={values.mobile}
            label='Mobile'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='fax'
            value={values.fax}
            label='Fax'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            name='email'
            value={values.email}
            label='Email'
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <SelectAC
            label='Certified Recovery Options'
            name='certifiedRecoveryOptions'
            value={values.certifiedRecoveryOptions}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            errors={errors}
            options={certifiedRecoveryOptions}
            required={false}
            margin='normal'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <AutocompleteSelect
            onBlur={handleBlur}
            name={`eFact`}
            label={'Efact Nr'}
            errors={errors}
            value={values.eFact}
            settings={{
              disableClearable: false,
              valueProp: 'name',
              labelProp: 'name',
            }}
            onChange={(selected) => {
              setFieldValue('eFact', selected ? selected.name : null);
            }}
            options={efactOptions}
          />
        </Grid>

        {values.partnerType === 'Debitor/Kreditor' && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ChipsInput
              name="pod"
              value={values.pod || []}
              label="POD"
              errors={errors}
              required={false}
              setFieldValue={setFieldValue}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

BasicDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  efactOptions: PropTypes.array.isRequired,
};

export default BasicDataForm;
