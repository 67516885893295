import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { PATHS } from 'util/appConstants';
import { selectCarrier, selectCarrierStatus, getCarrier, editCarrier } from 'redux/slices/carrierSlice';
import CarrierForm from 'components/Carriers/Form';

const currentAction = 'EDIT';

const EditCarrier = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectCarrierStatus);
  const carrier = useSelector(selectCarrier);

  useEffect(() => {
    if ((!carrier || carrier._id !== id) && id && !loading) {
      dispatch(getCarrier(id));
    }
  }, [carrier, id]);

  const handleEditCarrier = async (payload) => {
    await dispatch(editCarrier(id, payload));

    history.push(PATHS.carriers.root);
  };

  if (loading || !carrier) {
    return (
      <div>Loading..</div>
    );
  }

  return (
    <CarrierForm
      initialValues={{
        ...carrier,
      }}
      onSubmit={handleEditCarrier}
      action={currentAction}
    />
  );
};

export default EditCarrier;
