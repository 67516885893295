import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { WAREHOUSE_STOCK_TABLE_COLUMNS } from 'constants/ui-constants';
import { defaultTableOptions, getLocalization, getColumns } from 'util/table-utils';
import { mapTableData } from 'util/table-utils';

// Actions
import { selectWarehouseStatus, getStock } from 'redux/slices/warehouseSlice';

const WarehouseStock = () => {
  const { t } = useTranslation('common');
  const [warehouses, setWarehouses] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectWarehouseStatus);

  const fetch = async () => {
    const res = await dispatch(getStock());

    setWarehouses(res);
  };

  React.useEffect(() => {
    if (!loading) {
      fetch();
    }
  }, []);

  if (loading) {
    return <div>Loading..</div>;
  }

  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapTableData(warehouses)}
        title={t('WAREHOUSES')}
        minRows={0}
        columns={getColumns(WAREHOUSE_STOCK_TABLE_COLUMNS, t)}
        options={defaultTableOptions}
        localization={getLocalization(t)}
        detailPanel={(row) => {
          return (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Article')}</TableCell>
                  <TableCell>{t('Weight')}</TableCell>
                  <TableCell>{t('Estimated value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  row.stocks.map((stock, i) => (
                    <TableRow key={i}>
                      <TableCell>{stock.Article.descriptionInEnglish}</TableCell>
                      <TableCell>{stock.amount} {stock.unit}</TableCell>
                      <TableCell>
                        {
                          stock.amount > 0 && (
                            <>
                              € {stock.amount * (stock.total_price_sum / stock.total_price_operations)}
                            </>
                          )
                        }
                        {
                          stock.amount < 0 && (<>€ 0</>)
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          );
        }}
      />
    </div>
  );
};

export default WarehouseStock;
