import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const baseUrl = '/banks';
const initialState = {
  banks: {},
  currentBank: null,
  loading: false,
  timestamp: null,
  search: ""
};

const bankSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    addNewBank: (state, action) => {
      const { _id } = action.payload;
      state.banks[_id] = action.payload;
      state.bank = action.payload;
    },
    setEditBank: (state, action) => {
      const { id } = action.payload;
      state.banks[id] = {
        ...state.banks[id],
        ...action.payload,
      };
      state.bank = action.payload;
    },
    setBank: (state, action) => {
      state.currentBank = action.payload;
    },
    setBanks: (state, action) => {
      const banks = {};
      action.payload.forEach(bank => {
        banks[bank.id] = bank;
      });
      state.banks = banks;
      state.timestamp = +new Date();
    },
    resetBanks: (state) => {
      state.banks = {};
      state.timestamp = null;
    },
    removeBank: (state, action) => {
      delete state.banks[action.payload];
    },
    setBanksLoading: (state) => {
      state.loading = true;
    },
    setBanksReady: (state) => {
      state.loading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  addNewBank,
  setBanks,
  setBank,
  setEditBank,
  removeBank,
  setBanksLoading,
  setBanksReady,
  resetBanks,
  setSearch
} = bankSlice.actions;
export default bankSlice.reducer;

export const getBank = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setBanksLoading());

  try {
    const data = await coreApi.fetch(url);
    dispatch(setBank(data));

    return data;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setBanksReady());
  }
};

export const getBanks = () => async dispatch => {
  dispatch(setBanksLoading());

  try {
    const data = await coreApi.fetch(baseUrl);
    dispatch(setBanks(data));

    return data;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setBanksReady());
  }
};

export const addBank = (payload) => async dispatch => {
  const url = baseUrl + '/';
  dispatch(setBanksLoading());

  try {
    const { bank, error } = await coreApi.post(url, payload);

    if (error) {
      dispatch(setShowMessage({
        description: error ?? 'Invalid IBAN',
        type: 'error',
      }));

      return error;
    } else {
      dispatch(resetBanks());
      dispatch(setShowMessage({
        description: 'Bank Added Successfully!',
        type: 'success',
      }));

      return bank;
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed Adding Bank. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setBanksReady());
  }
};

export const editBank = (id, payload) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setBanksLoading());

  try {
    const res = await coreApi.put(url, payload);

    if (res) {
      dispatch(setEditBank({ ...payload, id }));
      dispatch(resetBanks());
      dispatch(setShowMessage({
        description: 'Bank Updated Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed Updating Bank. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setBanksReady());
  }
};

export const deleteBank = (id) => async dispatch => {
  const url = baseUrl + `/${id}`;
  dispatch(setBanksLoading());

  try {
    const res = await coreApi.delete(url);

    if (res) {
      dispatch(removeBank(id));
      dispatch(setShowMessage({
        description: 'Bank Deleted Successfully!',
        type: 'success',
      }));
    }
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed Deleting Bank. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setBanksReady());
  }
};

const bankSelector = ({ banks }) => banks.currentBank;
const banksSelector = ({ banks }) => banks.banks;
const bankStatusSelector = ({ banks }) => banks.loading;
const searchSelector = ({ banks }) => banks.search;

export const selectBank = createSelector(bankSelector, currentBank => currentBank);
export const selectBanks = createSelector(banksSelector, banks => Object.values(banks));
export const selectBankStatus = createSelector(bankStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ banks }) => banks.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
