import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid ' + theme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
}))(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    '&:hover': {
      color: theme.palette.secondary.main,
      opacity: 1,
    },

    '&:selected': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },

    '&:active': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },

    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
