import React from 'react';
import moment from 'moment';
import {
  getCompanyAddress,
  getCountry,
  getDates,
  getGermanCollectionValues,
  getGermanNumber,
  getLanguageAcronym,
  getOpeningHours,
  getPaymentTerms,
  getSalutation,
  getUnloadingPartner,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';

const ContactPersons = ({ partner, language, defaultEmail }) => {
  const persons = (partner.contactPersons || []).filter((p) => p.role.includes('Transportation Order'));

  return (
    <>
      {
        persons.map((person, i) => (
          <div style={{ breakInside: 'avoid', float: 'left', marginRight: '20px', minWidth: '100px', marginBottom: '10px', wordWrap: 'break-word', overflowWrap: 'break-word' }} key={i}>
            <div>
              {getSalutation(person.salutation, language)} {person.firstName} {person.lastName || person.surname}
            </div>
            <div>
              {person.emails[0] || defaultEmail}
            </div>
            <div>
              {person.phone}
            </div>
          </div>
        ))
      }
    </>
  );
};

class TransportationOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
      unloadingPartner: null,
    };
  }

  getArticleNames = (language = 'de') => {
    let names = '';
    let namesArray = [];
    let avv = [];
    if (this.props.currentEntity.articles.length > 0) {
      for (let article of this.props.currentEntity.articles) {
        if (article.articleDetails.avv) {
          avv.push(article.articleDetails.avv);
        }

        if (article.source_deviating) {
          namesArray.push(`${article.source_deviating}`);
        } else {
          namesArray.push(
            language === 'en'
              ? article.articleDetails.descriptionInEnglish
              : article.articleDetails.descriptionInGerman,
          );
        }
      }
      names = namesArray.join(', ');
    } else {
      names = 'N/A';
    }

    avv = new Set(avv);
    avv = [...avv];
    return { names, avv };
  };

  getUnloadingPartnerDetails() {
    let partner = getUnloadingPartner(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
    );

    return partner;
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.carrierData
      ? this.props.carrierData
        ? this.props.carrierData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  render() {
    const { sourceData } = this.props;
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'transport-order-document-container'}>
          <div className={'doc-logo'}>
            <img
              src={`${this.state.apiUrl}/companies/image/${this.props.companyData.companyLogo}`}
              width={'140'}
            />
          </div>
          {/* carrier */}
          <div className={'carrier-container'}>
            <div>
              <div className="font-10 border-bottom-solid mg-bottom-14">
                <p
                  className={'mg-0'}
                >{`${this.props.companyName}, ${this.props.companyAddress}`}</p>
              </div>
              {this.props.carrierData && (
                <React.Fragment>
                  <div className="font-12">{`${this.props.carrierData.name1}`}</div>
                  <div className="font-12">{`${this.props.carrierData.street} ${this.props.carrierData.houseNumber}`}</div>
                  <div
                    className={'font-12'}
                  >{`${this.props.carrierData.zipcode} ${this.props.carrierData.city}`}</div>
                  <div className="font-12">{`${getCountry(
                    this.props.carrierData.country,
                    language,
                  )}`}</div>
                </React.Fragment>
              )}
            </div>
            <div className={'right-section'}>
              <div className="font-12">{`Kontakt: ${this.props.firstName} ${this.props.surName}`}</div>
              <div className="font-12">{`Telefon: ${this.props.phone}`}</div>
              <div className="font-12">{`E-Mail: ${this.props.email}`}</div>
              <div className="font-12">{`Datum: ${moment(this.state.today).format(
                'DD.MM.YYYY',
              )}`}</div>
            </div>
          </div>

          <div className={'padding-top-12 font-12 bold'}>
            Dispo-Nummer: {`${this.props.dispositionNumber}`}
          </div>

          <div className={'padding-top-12 font-12'}>
            <p className={'mg-0'}>Sehr geehrte Damen und Herren,</p> <br />
            <p className={'mg-0'}>hiermit bestätigen wir Ihnen folgenden Auftrag:</p>
          </div>

          <div className={'padding-top-12 flex justify-space-between'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">Transportauftrag</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Transport von:</b>{' '}
                  {`${sourceData.locationName ? `${sourceData.locationName}, ` : ''}${
                    this.props.sourceData.zipcode
                    } ${this.props.sourceData.city} ${getCountry(
                      this.props.sourceData.country,
                      language,
                    )} nach ${this.props.processorData.zipcode} ${
                    this.props.processorData.city
                    }, ${getCountry(this.props.processorData.country, language)}`}
                </p>
                <p className={'mg-0'}>
                  <b>Material:</b> {this.getArticleNames().names}
                </p>
                {this.getArticleNames().avv.length > 0 && (
                  <p className={'mg-0'}>
                    <b>AVV:</b> {this.getArticleNames().avv.join()}
                  </p>
                )}
                <p className={'mg-0'}>
                  <b>Fahrzeug:</b> {this.props.t(this.props.currentEntity.vehicle || "")}
                </p>
                <p className={'mg-0'}>
                  <b>Zahlungsbedingungen:</b>{' '}
                  {this.props.carrierData ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getPaymentTerms(
                          this.props.carrierData,
                          language,
                        ),
                      }}
                    />
                  ) : (
                      'N/A'
                    )}{' '}
                </p>
                <p className={'mg-0'}>
                  <b>Bemerkung:</b>{' '}
                  {this.props.transportRemark}
                </p>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }}>
              <div className="title font-14 bold title-container">Preis</div>
              <p className={'mg-0 padding-top-5'}>
                {getGermanNumber(this.props.currentEntity.shippingCost)}{' '}
                {this.props.currentEntity.currency === 'Euro' ? '€' : '$'}
              </p>
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">Ladedaten</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Ladestelle:</b>{' '}
                  {this.props.sourceData.locationName
                    ? this.props.sourceData.locationName
                    : this.props.sourceData.name1}
                  , {getCompanyAddress(this.props.sourceData, language)}
                </p>
                <p className={'mg-0'}>
                  <b>{getGermanCollectionValues(this.props.currentEntity.collectionSource)}:</b>{' '}
                  {getDates(this.props.currentEntity.pickupDate, this.props.currentEntity.collectionSource)}
                </p>
                <p className={'mg-0'}>
                  <b>Ladenummer:</b> {this.props.currentEntity.loadingReferenceSource}
                </p>
                <div className={'mg-0'}>
                  <div><b>Avisierung:</b></div>
                  <ContactPersons
                    partner={this.props.sourceData}
                    language={language}
                    defaultEmail={this.props.companyData.docEmail}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }} className={'page-avoid'}>
              <div className="title font-14 bold title-container">Öffnungszeiten</div>
              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">
                {this.getUnloadingPartnerDetails()
                  ? this.getUnloadingPartnerDetails().transport
                  : 'Entladedaten'}
              </div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <b>{this.getUnloadingPartnerDetails().heading}:</b>{' '}
                      {`${
                        this.getUnloadingPartnerDetails().partner.locationName
                          ? this.getUnloadingPartnerDetails().partner.locationName
                          : this.getUnloadingPartnerDetails().partner.name1
                        }, `}{' '}
                      {getCompanyAddress(this.getUnloadingPartnerDetails().partner, language)}
                    </React.Fragment>
                  )}
                </p>
                <p className={'mg-0'}>
                  <b>{getGermanCollectionValues(this.props.currentEntity.deliveryProcess)}:</b>{' '}
                  {getDates(this.props.currentEntity.deliveryDate, this.props.currentEntity.deliveryProcess)}
                </p>
                <p className={'mg-0'}>
                  <b>
                    {this.getUnloadingPartnerDetails().heading === 'Entladestelle'
                      ? 'Entladenummer'
                      : 'Ladenummer'}
                    :
                  </b>{' '}
                  {this.props.currentEntity.loadingReferenceProcessor}
                </p>
                <div className={'mg-0'}>
                  <div><b>Avisierung:</b></div>
                  <ContactPersons
                    partner={this.getUnloadingPartnerDetails().partner}
                    language={language}
                    defaultEmail={this.props.companyData.docEmail}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }} className={'page-avoid'}>
              <div className="title font-14 bold title-container">Öffnungszeiten</div>

              {this.props.processorData.openingHours && this.props.processorData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.processorData.openingHours && this.props.processorData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 page-avoid'}>
            <p className={'font-12'}>Bei Fragen stehen wir Ihnen gerne zur Verfügung.</p>
            <p className={'font-12'}>Mit freundlichen Grüßen</p>
            <p className={'font-12'}>Ihr {this.props.companyData.name2} Team</p>
          </div>

          <Footer {...this.props} />
        </div>
      );
    } else {
      return (
        <div className={'transport-order-document-container'}>
          <div className={'doc-logo'}>
            <img
              src={`${this.state.apiUrl}/companies/image/${this.props.companyData.companyLogo}`}
              width={'140'}
            />
          </div>
          {/* carrier */}
          <div className={'carrier-container'}>
            <div>
              <div className="font-10 border-bottom-solid mg-bottom-14">
                <p
                  className={'mg-0'}
                >{`${this.props.companyName}, ${this.props.companyAddress}`}</p>
              </div>
              {this.props.carrierData && (
                <React.Fragment>
                  <div className="font-12">{`${this.props.carrierData.name1}`}</div>
                  <div className="font-12">{`${this.props.carrierData.street} ${this.props.carrierData.houseNumber}`}</div>
                  <div
                    className={'font-12'}
                  >{`${this.props.carrierData.zipcode} ${this.props.carrierData.city}`}</div>
                  <div className="font-12">{`${getCountry(
                    this.props.carrierData.country,
                    language,
                  )}`}</div>
                </React.Fragment>
              )}
            </div>
            <div className={'right-section'}>
              <div className="font-12">{`Contact: ${this.props.firstName} ${this.props.surName}`}</div>
              <div className="font-12">{`Phone: ${this.props.phone}`}</div>
              <div className="font-12">{`E-Mail: ${this.props.email}`}</div>
              <div className="font-12">{`Date: ${moment(this.state.today).format(
                'DD.MM.YYYY',
              )}`}</div>
            </div>
          </div>

          <div className={'padding-top-12 font-12 bold'}>
            Order number: {`${this.props.dispositionNumber}`}
          </div>

          <div className={'padding-top-12 font-12'}>
            <p className={'mg-0'}>Dear Sir or Madam,</p> <br />
            <p className={'mg-0'}>we hereby confirm the following order:</p>
          </div>

          <div className={'padding-top-12 flex justify-space-between'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">Transport order</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Transport from:</b>{' '}
                  {`${sourceData.locationName ? `${sourceData.locationName}, ` : ''}${
                    this.props.sourceData.zipcode
                    } ${this.props.sourceData.city} ${getCountry(
                      this.props.sourceData.country,
                      language,
                    )} to ${this.props.processorData.zipcode} ${
                    this.props.processorData.city
                    }, ${getCountry(this.props.processorData.country, language)}`}
                </p>
                <p className={'mg-0'}>
                  <b>Material:</b> {this.getArticleNames('en').names}
                </p>
                {this.getArticleNames('en').avv.length > 0 && (
                  <p className={'mg-0'}>
                    <b>AVV:</b> {this.getArticleNames('en').avv.join()}
                  </p>
                )}
                <p className={'mg-0'}>
                  <b>Vehicle:</b> {this.props.currentEntity.vehicle}
                </p>
                <p className={'mg-0'}>
                  <b>Payment terms:</b>{' '}
                  {this.props.carrierData ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getPaymentTerms(
                          this.props.carrierData,
                          language,
                        ),
                      }}
                    />
                  ) : (
                      'N/A'
                    )}{' '}
                </p>
                <p className={'mg-0'}>
                  <b>Remark:</b>{' '}
                  {this.props.transportRemark}
                </p>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }}>
              <div className="title font-14 bold title-container">Price</div>
              <p className={'mg-0 padding-top-5'}>
                {getGermanNumber(this.props.currentEntity.shippingCost)}{' '}
                {this.props.currentEntity.currency === 'Euro' ? '€' : '$'}
              </p>
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">Loading data</div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  <b>Loading point:</b>{' '}
                  {this.props.sourceData.locationName
                    ? this.props.sourceData.locationName
                    : this.props.sourceData.name1}
                  , {getCompanyAddress(this.props.sourceData, language)}
                </p>
                <p className={'mg-0'}>
                  <b>{this.props.currentEntity.collectionSource}:</b>{' '}
                  {getDates(this.props.currentEntity.pickupDate, this.props.currentEntity.collectionSource)}
                </p>
                <p className={'mg-0'}>
                  <b>Loading reference:</b> {this.props.currentEntity.loadingReferenceSource}
                </p>
                <div className={'mg-0'}>
                  <div><b>Notification:</b></div>
                  <ContactPersons
                    partner={this.props.sourceData}
                    language={language}
                    defaultEmail={this.props.companyData.docEmail}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }} className={'page-avoid'}>
              <div className="title font-14 bold title-container">Opening hours</div>
              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.sourceData.openingHours && this.props.sourceData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.sourceData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 flex justify-space-between page-avoid'}>
            <div style={{ width: '50%' }}>
              <div className="title font-14 bold title-container">
                {this.getUnloadingPartnerDetails()
                  ? this.getUnloadingPartnerDetails().transportEnglish
                  : 'Entladedaten'}
              </div>
              <div className="font-12 padding-top-5">
                <p className={'mg-0'}>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <b>{this.getUnloadingPartnerDetails().headingEnglish}:</b>{' '}
                      {`${
                        this.getUnloadingPartnerDetails().partner.locationName
                          ? this.getUnloadingPartnerDetails().partner.locationName
                          : this.getUnloadingPartnerDetails().partner.name1
                        }, `}{' '}
                      {getCompanyAddress(this.getUnloadingPartnerDetails().partner, language)}
                    </React.Fragment>
                  )}
                </p>
                <p className={'mg-0'}>
                  <b>{this.props.currentEntity.deliveryProcess}:</b>{' '}
                  {getDates(this.props.currentEntity.deliveryDate, this.props.currentEntity.deliveryProcess)}
                </p>
                <p className={'mg-0'}>
                  <b>
                    {this.props.t(
                      this.getUnloadingPartnerDetails().heading === 'Entladestelle'
                        ? 'Unloading Reference'
                        : 'Loading Reference',
                    )}
                    :
                  </b>{' '}
                  {this.props.currentEntity.loadingReferenceProcessor}
                </p>
                <div className={'mg-0'}>
                  <div><b>Notification:</b></div>
                  <ContactPersons
                    partner={this.getUnloadingPartnerDetails().partner}
                    language={language}
                    defaultEmail={this.props.companyData.docEmail}
                  />
                </div>
              </div>
            </div>

            <div style={{ width: '50%', textAlign: 'right' }} className={'page-avoid'}>
              <div className="title font-14 bold title-container">Opening hours</div>

              {this.props.processorData.openingHours && this.props.processorData.openingHours[0] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[0], language),
                  }}
                />
              )}

              {this.props.processorData.openingHours && this.props.processorData.openingHours[1] && (
                <div
                  className={'font-12'}
                  dangerouslySetInnerHTML={{
                    __html: getOpeningHours(this.props.processorData.openingHours[1], language),
                  }}
                />
              )}
            </div>
          </div>

          <div className={'padding-top-12 page-avoid'}>
            <p className={'font-12'}>Feel free to contact us if you have any questions.</p>
            <p className={'font-12'}>With best regards</p>
            <p className={'font-12'}>Your {this.props.companyData.name2} team</p>
          </div>

          <Footer {...this.props} />
        </div>
      );
    }
  }
}

export default withTranslation('common')(TransportationOrder);
