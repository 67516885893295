import { configureStore } from '@reduxjs/toolkit';
import newUserReducer from './slices/userSlice';
import companyReducer from './slices/companySlice';
import partnerReducer from './slices/partnerSlice';
import uiReducer from './slices/uiSlice';
import authReducer from './slices/authSlice';
import bankReducer from './slices/bankSlice';
import personReducer from './slices/personSlice';
import modalReducer from './slices/modalSlice';
import articleReducer from './slices/articleSlice';
import warehouseReducer from './slices/warehouseSlice';
import contractsReducer from './slices/contractsSlice';
import ordersReducer from './slices/ordersSlice';
import carriersReducer from './slices/carrierSlice';
import harborsReducer from './slices/harborSlice';

const store = configureStore({
  reducer: {
    newUser: newUserReducer,
    newPartner: partnerReducer,
    newPersons: personReducer,
    newContracts: contractsReducer,
    newOrders: ordersReducer,
    companies: companyReducer,
    carriers: carriersReducer,
    ui: uiReducer,
    modals: modalReducer,
    auth: authReducer,
    banks: bankReducer,
    articles: articleReducer,
    warehouses: warehouseReducer,
    harbors: harborsReducer,
  },
});

export default store;
