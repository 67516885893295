import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { defaultValues } from 'components/Contracts/constants';
import { PATHS } from 'util/appConstants';
import { usePartners, useCompanies, useBanks, useHarbors } from 'hooks';

// Actions
import { selectContractStatus, addContract } from 'redux/slices/contractsSlice';
import {
  fetchContractPersons,
  fetchAllTeamMembers,
  selectBuyerContractPersons,
  selectAllTeamMembers,
} from 'redux/slices/personSlice';

// Components
import ContractForm from 'components/Contracts/ContractForm';

const AddContract = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { banks } = useBanks();
  const { partners } = usePartners();
  const { companies } = useCompanies();
  const { harbors } = useHarbors();
  const loading = useSelector(selectContractStatus);
  const teamMembers = useSelector(selectAllTeamMembers);
  const buyerContractPersons = useSelector(selectBuyerContractPersons);
  const [podOptions, setPodOptions] = useState([]);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [commercialOptions, setCommercialOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchAllTeamMembers());
  }, []);

  useEffect(() => {
    if (partners.length && !partnerOptions.length) {
      const mappedPartners = partners.map(partner => ({
        value: partner.name2,
        label: partner.name2,
        ...partner,
      }));
      setPartnerOptions([{ value: 'Same as buyer', label: 'Same as buyer' }, ...mappedPartners]);
    }
  }, [partners, partnerOptions]);

  useEffect(() => {
    if (!!partners.length && !buyerOptions.length) {
      const filteredList = partners.map(partner => ({
        value: partner.name2,
        label: partner.name2,
        partnerId: partner._id,
        pod: partner.pod,
      }));
      setBuyerOptions(filteredList);
    }
  }, [partners]);

  useEffect(() => {
    if (!!teamMembers.length && !commercialOptions.length) {
      const mappedList = teamMembers
        .filter((member) => member.role.includes('Account Manager'))
        .map(item => ({ value: item.fullName, label: item.fullName, ...item }));

      setCommercialOptions(mappedList);
    }
  }, [teamMembers, commercialOptions]);

  useEffect(() => {
    if (!!partners.length && !!companies.length && !shipperOptions.length) {
      const joinedPartners = partners.concat(companies);
      const filteredPartners = joinedPartners.filter((item, i) => joinedPartners.indexOf(item) === i);
      const shippers = filteredPartners.map(shipper => ({
        value: shipper.name2,
        label: shipper.name2,
        shipperId: shipper._id,
      }));
      setShipperOptions(shippers);
    }
  }, [partners, companies, shipperOptions]);

  const buyerCallback = useCallback((buyer) => {
    const { partnerId, pod } = buyer;

    if (pod && !!pod.length) {
      const options = pod.split(',');
      setPodOptions(options.map(option => ({ value: option, label: option })));
    }

    dispatch(fetchContractPersons(partnerId));
  }, [dispatch]);

  const handleAddContract = async payload => {
    const res = await dispatch(addContract(payload));

    if (typeof res !== 'string' || (res && !res.errors)) {
      return history.push(PATHS.contracts.root);
    }
  };

  if (
    loading ||
    !buyerOptions ||
    !buyerContractPersons ||
    !partners.length ||
    !companies.length ||
    !banks.length ||
    !shipperOptions.length ||
    !commercialOptions.length
  ) return <div>Loading..</div>;

  return (
    <ContractForm
      onSubmit={handleAddContract}
      buyerCallback={buyerCallback}
      initialValues={defaultValues}
      podOptions={podOptions}
      banks={banks}
      buyerOptions={buyerOptions}
      partnerOptions={partnerOptions}
      shipperOptions={shipperOptions}
      commercialOptions={commercialOptions}
      buyerContractPersons={buyerContractPersons}
      harbors={harbors}
    />
  );
};

export default AddContract;
