import isString from 'lodash/isString';
import moment from 'moment';
import ZipcodeMap from './zipcodes';
export * from './storage';

export const nullifyObject = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (isString(obj[key]) && !obj[key].length) {
      acc[key] = null;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

export const handleZipcodeChange = (handleChange, setFieldValue, l, prependToName = '') => (e) => {
  const { value } = e.target;
  const key = ZipcodeMap[value]
    ? value
    : ZipcodeMap[parseInt(value, 10)] ? parseInt(value, 10) : null;

  if (key) {
    setFieldValue(`${prependToName}city`, ZipcodeMap[key][0] || '');
    setFieldValue(`${prependToName}state`, ZipcodeMap[key][1] || '');
    setFieldValue(`${prependToName}country`, 'Germany');
  }

  return handleChange(e);
};

export const fixPreviousBrokenDates = (value) => {
  if (!value) {
    return null;
  }

  if (isString(value)) {
    if (/^[0-9]{2}[-|\.]{1}[0-9]{2}[-|\.]{1}[0-9]{4}$/.test(value)) {
      return moment(value, 'DD.MM.YYYY').toISOString();
    }
  }

  return value;
};
