import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutWithRedirect } from 'redux/slices/authSlice';

export default (ComposedComponent) => {
  class Authentication extends Component {
    static propTypes = {
      authenticated: PropTypes.bool,
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
      match: PropTypes.shape({
        path: PropTypes.string,
      }),
    };

    componentDidMount = () => this.ensureAuthentication();

    ensureAuthentication = () => {
      if (!this.props.authenticated) {
        // Redirect to the login page

        this.props.logoutWithRedirect(() => {});
        // return window.location.href = '/';
      }
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
  })
  const mapDispatchToProps = (dispatch) => ({
    logoutWithRedirect: (r) => dispatch(logoutWithRedirect(r)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication));
};
