import React from 'react';
import { withTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      error: false,
    };
  }

  componentDidCatch(err, info) {
    this.setState({ error: true });

    Sentry.captureException(err);
  }

  render() {
    if (this.state.error) {
      return (
        <Alert severity="error">
          {this.props.t(this.props.message)}
        </Alert>
      );
    }

    return (
      <>
        {this.props.children}
      </>
    );
  }
}

export default withTranslation('common')(ErrorBoundary);
