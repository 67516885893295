import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { formatDecimals, formatNumberToEn } from 'helpers/common';
import { Typography } from '@material-ui/core';
import { getGermanNumber } from 'helpers/documents';

const sumArticles = (articles = [], key = 'source') => {
  return articles.reduce((acc, article) => {
    const price = formatNumberToEn(article[`${key}_price`] || 0);
    const weight = formatNumberToEn(article[`${key}_weight`] || 0);

    if (article[`${key}_cashflow`]) {
      if (article[`${key}_cashflow`] !== 'Compensation') {
        return acc - (price * weight);
      }

      return acc + (price * weight);
    }


    return acc;
  }, 0);
};

const sumCommission = (commissionArticles = []) => {
  return commissionArticles.reduce((acc, cur) => acc + (parseFloat(cur.commission_price) || 0), 0);
};


export default ({
  order,
  showProfit,
}) => {
  const { t } = useTranslation();
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (order && showProfit) {
      const source = sumArticles(order.articles, 'source');
      const processor = sumArticles(order.articles, 'processor');
      const commission = sumCommission(order.commissionArticles);
      const shipping = parseFloat(order.shippingCost) || 0;


      setTotal(formatDecimals(processor - source  - shipping));
    }
  }, [order, showProfit]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant={'h4'}>
          {t('Order Status')} <span style={{ fontWeight: 'normal' }}>{order.orderNumber}</span>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant={'h4'} style={{ float: 'right', marginRight: '35px' }}>
          {
            showProfit && (
              <span className={total > 0 ? 'primary' : 'error'}>
                {getGermanNumber(total)}
                {order.articles.length > 0
                  ? order.articles[0].source_currency === 'Euro'
                    ? '€'
                    : '$'
                  : ''}
              </span>
            )
          }
          &nbsp;
          <span style={{ marginLeft: '12px' }}>
            {order.status && order.status.yellow && <span className={'yellow'} />}
            {order.status && order.status.orange && <span className={'orange'} />}
            {order.status && order.status.green && <span className={'green'} />}
            {order.status && order.status.checkbox && <span className={'blue'} />}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
