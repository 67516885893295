import useBaseHook from './useBaseHook';
import { selectCompanies, getCompanies, selectCompanyStatus, selectTimestamp } from 'redux/slices/companySlice';

const useCompanies = () => {
  const {
    records: companies,
    loading,
    revalidate,
  } = useBaseHook({
    fetchRecords: getCompanies,
    loadingSelector: selectCompanyStatus,
    timestampSelector: selectTimestamp,
    recordsSelector: selectCompanies,
  });

  return { companies, loading, revalidate };
};

export default useCompanies;
