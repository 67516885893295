import React from 'react';
import { useSelector } from 'react-redux';
import { selectModal } from 'redux/slices/modalSlice';
import ExternPersonsModalForm from 'components/Person/ExternPersonsModalForm';

const ModalManager = () => {
  const modalLookup = {
    AddPerson: ExternPersonsModalForm,
    EditPerson: ExternPersonsModalForm,
  };
  const currentModal = useSelector(selectModal);
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
};

export default ModalManager;
