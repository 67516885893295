import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as pick from 'lodash/pick';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CarrierSchema, AllowedFields, defaultValues } from './constants';
import { formActions } from 'util/appConstants';
import {
  Input,
  InputOnlyNumbers,
  Select,
  MultiSelectChips,
  CountriesAutocomplete,
  IbanInput,
} from 'components/Shared/mui-formik-inputs';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactPersons from 'components/Person';
import { handleZipcodeChange } from 'util/index';

const CarrierForm = ({ initialValues, onSubmit, action }) => {
  const { t, i18n } = useTranslation('common');

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: CarrierSchema,
    initialValues: {
      ...defaultValues,
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(pick(values, AllowedFields));
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const { values, handleChange, setFieldValue, errors, handleSubmit, dirty, submitCount, submitting } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Prompt when={dirty} message={t('unsaved_changes_message')} />
      <Box boxShadow={3} p={2}>
        <Typography variant='h4'>{t('Basic Data')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'Name1'}
              name='name1'
              value={values.name1}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'Name2'}
              name='name2'
              value={values.name2}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Street'
              name='street'
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='House Number'
              name='houseNumber'
              value={values.houseNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Zipcode'
              name='zipcode'
              value={values.zipcode}
              onChange={handleZipcodeChange(handleChange, setFieldValue, i18n.language)}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='City'
              name='city'
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <CountriesAutocomplete
              name="country"
              label={'Country'}
              setFieldValue={setFieldValue}
              value={values.country}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label={'Phone 1'}
              value={values.phone1}
              name={'phone1'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label={'Phone 2'}
              value={values.phone2}
              name={'phone2'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label='Mobile'
              name='mobile'
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Fax'
              name='fax'
              value={values.fax}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Email'
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <MultiSelectChips
              name='vehicle'
              value={values.vehicle}
              label={'Vehicle'}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              errors={errors}
              options={[
                'Walking Floor', 'Tautliner', 'Tipper', 'Shipping Container',
                'Container vehicle', 'Silo',
              ]}
              margin='dense'
              required
            />
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} my={4}>
        <Typography variant='h4'>{t('Banking and Billing Details')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              margin="normal"
              label={'Payment Terms'}
              name='paymentTerms'
              value={values.paymentTerms}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              options={[
                { value: 'Immediately', label: t('Immediately') },
                { value: '3 net', label: t('3 net') },
                { value: '7 net', label: t('7 net') },
                { value: '8 net', label: t('8 net') },
                { value: '10 net', label: t('10 net') },
                { value: '14 net', label: t('14 net') },
                { value: '21 net', label: t('21 net') },
                { value: '30 net', label: t('30 net') },
                { value: 'Upfront', label: t('Upfront') },
                { value: 'Other', label: t('Other') },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              margin="normal"
              label={'Currency'}
              name='currency'
              value={values.currency}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              options={[
                { value: 'EUR', label: 'EUR' },
                { value: 'CHF', label: 'CHF' },
                { value: 'DKK', label: 'DKK' },
                { value: 'USD', label: 'USD' },
              ]}
            />
          </Grid>
          <IbanInput
            bic={values.bic}
            label='IBAN'
            value={values.iban}
            bankName={values.bankName}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            errors={errors}
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label={'Our Client No At Partner'}
              name='clientNumberAtClient'
              value={values.clientNumberAtClient}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'Tax Identification Number'}
              name='taxID'
              value={values.taxID}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'National Tax Number'}
              name='nationalTaxID'
              value={values.nationalTaxID}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              margin="normal"
              label={'Invoice Delivery Method'}
              name='invoiceDeliveryMethod'
              value={values.invoiceDeliveryMethod}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              options={[
                { value: 'Email', label: t('Email') },
                { value: 'Mail', label: t('Mail') },
              ]}
            />
          </Grid>
          {
            values.invoiceDeliveryMethod === 'Email' && (
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Input
                  label={'Invoice email'}
                  name='invoiceEmail'
                  value={values.invoiceEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  required={values.invoiceDeliveryMethod === 'Email'}
                />
              </Grid>
            )
          }
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              margin="normal"
              label={'Choose Document Language'}
              name='documentLanguage'
              value={values.documentLanguage}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              options={[
                { value: 'English', label: t('English') },
                { value: 'German', label: t('German') },
              ]}
            />
          </Grid>
        </Grid>
      </Box>
      {
        action === formActions.edit && (
          <ContactPersons
            contactPersons={values.contactPersons}
            type="CARRIER"
            initialValues={{
              carrierId: values._id || values.id,
            }}
            setFieldValue={setFieldValue}
          />
        )
      }
      <Button
        variant='contained'
        color='primary'
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        type='submit'
        disabled={submitting}
      >
        {t(`${action}`) + ' ' + t('Carriers')}
      </Button>
    </form>
  );
};

CarrierForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  action: PropTypes.string,
};

export default CarrierForm;
