import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const DropzoneFileUpload = ({ name, label, values, onDrop, handleFileDownload, handleFileDelete }) => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <Typography variant='h4'>
        {t(label)}
      </Typography>
      <Dropzone
        onDrop={onDrop}
        maxSize={10 * 1024 * 1024}
        onDropRejected={() => alert(t('file_size_limit'))}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='dropzone-container'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop your file here, or click to select file</p>
            </div>
            {
              !!values[name] && (
                <aside>
                  <h4>{t('Files')}</h4>
                  <ul>
                    <li>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          className={'file-link'}
                          onClick={handleFileDownload}
                        >
                          {values[name]}
                        </p>
                        <Button
                          startIcon={<DeleteIcon />}
                          variant='outlined'
                          style={{
                            color: 'red',
                            backgroundColor: 'white',
                            float: 'right',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={handleFileDelete}
                        />
                      </div>
                    </li>
                  </ul>
                </aside>
              )
            }
          </section>
        )}
      </Dropzone>
    </Box>
  );
};

DropzoneFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  values: PropTypes.shape({}).isRequired,
  onDrop: PropTypes.func.isRequired,
  handleFileDownload: PropTypes.func.isRequired,
  handleFileDelete: PropTypes.func.isRequired,
};

export default DropzoneFileUpload;
