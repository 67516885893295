import React from 'react';
import { FormikProvider } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Input,
} from 'components/Shared/mui-formik-inputs';
import PreviewInvoice from 'components/Modals/previewInvoice';
import { useCompanies } from 'hooks';
import { sumArticles, getCurrency, getCompanyAddress, getGermanNumber } from 'helpers/documents';
import { selectUserSettings } from 'redux/slices/userSlice';
import IncomingDocsSection from './IncomingDocsSection';
import WeightslipsArticlesSection from './WeightslipsArticlesSection';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default ({
  formik,
  partners,
  deviatings,
  carriers,
  articles,
  accountManagers,
  companyOfficer,
  tax,
  order,
  processorIsWarehouse,
  sourceIsWarehouse,
  selectedSource,
  selectedProcessor,
}) => {
  const { t, i18n } = useTranslation('common');
  const { companies } = useCompanies();
  const settings = useSelector(selectUserSettings);
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitCount,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const company = companies[0] || {};
  const carrier = values.carrier
    ? carriers.find((c) => c._id === values.carrier)
    : null;

  return (
    <>
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">
            {t('Weightslips')} {values.orderNumber}
          </Typography>
        </Box>

        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={9} md={3}>
            <Input
              name="orderRemark"
              value={values.orderRemark}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              label={'Order remark'}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            {/* TODO: FIX THIS NONSENSE */}
            <PreviewInvoice
              fullButton={true}
              {...{
                companyOfficer,
                language: i18n.language,
                accountManagerProcessor: order.accountManagerProcessor,
                fieldAgent: order.fieldAgent,
                companyAddress: getCompanyAddress(company, i18n.language),
                companyName: company.name1,
                phone: order.fieldAgent ? order.fieldAgent.phone : null,
                email: order.fieldAgent && order.fieldAgent.emails.length ? order.fieldAgent.emails[0] : 'N/A',
                firstName: order.fieldAgent ? order.fieldAgent.firstName : null,
                surName: order.fieldAgent ? order.fieldAgent.surname : null,
                loadingReferenceNumber: order.loadingReferenceSource,
                dispositionNumber: order.orderNumber,
                carrierData: order.carrier,
                currentEntity: order,
                sourceData: order.source,
                processorData: order.processor,
                companyData: company,
                zipcode: company.zipcode,
                city: company.city,
                countries: order.countries,
                tax,
                articles: order.articles,
                billingConfirmationEmail: settings.billingConfirmationEmail,
                client: settings.client,
                transportRemark: order.transportRemark || '',
                oldInvoiceNumber: order.invoiceNumber,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="right">
              <Typography component="p" className={'font-16'}>
                <b className='display-flex justify-content-between w-80'>
                  <span> {t('Purchase total')}:</span>{' '}
                  <span>
                    {getGermanNumber(sumArticles(order.articles, 'source'))}
                    {getCurrency(order.articles)}{' '}
                  </span>
                </b>
              </Typography>
              <Typography component="p" className={'font-16'}>
                <b className='display-flex justify-content-between w-80'>
                  <span>{t('Sales total')}: </span>
                  <span>
                    {getGermanNumber(sumArticles(order.articles, 'processor'))}
                    {getCurrency(order.articles)}{' '}
                  </span>
                </b>
              </Typography>
              <Typography component="p" className={'font-16'}>
                <b className='display-flex justify-content-between w-80'>
                  <span> {t('Transport')}: </span>{' '}
                  <span>
                    {order.shippingCost || 0}
                    {getCurrency(order.articles)}{' '}
                  </span>
                </b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">
            {t('Incoming Documents')}
          </Typography>
        </Box>

        <Grid container spacing={2} alignItems="flex-end">
          <FormikProvider value={formik}>
            <IncomingDocsSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              name="sourceEntries"
              label="Purchase"
              relationalLabel="Source"
              relationalValue={selectedSource?.name2}
            />
            <Grid item xs={12}>
              <hr />
            </Grid>
            <IncomingDocsSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              name="processorEntries"
              label="Sale"
              relationalLabel="Processor"
              relationalValue={selectedProcessor?.name2}
            />
            <Grid item xs={12}>
              <hr />
            </Grid>
            <IncomingDocsSection
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              name="carrierEntries"
              label="Transport"
              relationalLabel="Forwarder"
              relationalValue={carrier ? carrier.name2 : null}
            />
          </FormikProvider>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">
            {t('Articles')}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <FormikProvider value={formik}>
            <WeightslipsArticlesSection
              articles={articles}
              formik={formik}
              {...{
                processorIsWarehouse,
                sourceIsWarehouse,
                selectedSource,
                selectedProcessor,
              }}
            />
          </FormikProvider>
        </Grid>
      </Box>
    </>
  );
};
