import React from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  Input,
  AutocompleteSelect,
  Select,
  ChipsInput,
  ToggleInput,
} from 'components/Shared/mui-formik-inputs';
import ArticlesSection from './ArticlesSection';
import CommissionArticlesSection from './CommissionArticlesSection';

export default ({
  formik,
  onSourceChange,
  onProcessorChange,
  partners,
  warehouses,
  deviatings,
  carriers,
  articles,
  accountManagers,
  sourceLocations,
  processorLocations,
  processorIsWarehouse,
  sourceIsWarehouse,
  selectedSource,
  selectedProcessor,
}) => {
  const { t } = useTranslation('common');
  const { values, errors, handleChange, setFieldValue, submitCount } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const carrierRequired = !(
    (values.incotermSource === 'EXW' && values.incotermProcessor === 'EXW') ||
    (values.incotermSource === 'DAP' && values.incotermProcessor === 'DAP') ||
    (values.incotermSource === 'DDP' && values.incotermProcessor !== 'DDP') ||
    (values.incotermSource !== 'DDP' && values.incotermProcessor === 'DDP')
  );
  let vehicleOptions = values.carrier ? carriers.find((c) => c._id === values.carrier).vehicle : [];

  vehicleOptions = [{ value: null, label: t('None') }].concat(
    vehicleOptions.map((v) => ({ label: v, value: v })),
  );

  const sourceAndProcessorSameCountry =
    !!(selectedSource && selectedProcessor) && selectedSource.country === selectedProcessor.country;

  const sourceOptions = (values.destinationType === 'dropshippment' || values.destinationType === 'commission')
    ? partners
    : (processorIsWarehouse ? partners : partners.concat(warehouses))

  const processorOptions = (values.destinationType === 'dropshippment' || values.destinationType === 'commission')
    ? partners
    : (sourceIsWarehouse ? partners : partners.concat(warehouses));

  return (
    <>
      {/********** FIRST SECTION ************/}
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">{t('Order Partner')}</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ToggleInput
              value={values.destinationType}
              onChange={(val) => {
                setFieldValue('destinationType', val);
                setFieldValue('processor', '');
                setFieldValue('source', '');
                setFieldValue('sourceLocationId', '');
                setFieldValue('processorLocationId', '');
              }}
              options={[
                { label: t('Drop Shipment'), value: 'dropshippment' },
                { label: t('To Warehouse'), value: 'warehouse' },
                { label: t('Commission'), value: 'commission' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="source"
              label={'Source'}
              errors={errors}
              value={values.source}
              settings={{
                disableClearable: true,
                valueProp: '_id',
                labelProp: (o) => o.name2 || o.alias,
              }}
              onChange={(selected) => {
                setFieldValue('sourceLocationId', '');
                setFieldValue('source', selected._id);

                onSourceChange(selected);
              }}
              options={sourceOptions}
              required
            />
          </Grid>
          {!!sourceLocations.length && (
            <Grid item xs={12} md={3}>
              <AutocompleteSelect
                onBlur={handleBlur}
                name="sourceLocationId"
                label={'Source Locations'}
                errors={errors}
                value={values.sourceLocationId}
                settings={{
                  disableClearable: false,
                  valueProp: '_id',
                  labelProp: (o) => `${o.name ? `${o.name}, ` : ''}${o.address}`,
                }}
                onChange={(selected) => {
                  setFieldValue('sourceLocationId', selected ? selected._id : '');
                }}
                options={sourceLocations}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="deviatingInvoiceAddressSource"
              label={'Deviating Invoice Address Source'}
              errors={errors}
              value={values.deviatingInvoiceAddressSource}
              settings={{
                disableClearable: false,
                valueProp: '_id',
                labelProp: 'name2',
              }}
              onChange={(selected) => {
                setFieldValue('deviatingInvoiceAddressSource', selected ? selected._id : '');
              }}
              options={deviatings}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="fieldAgent"
              label={'Account Manager Source'}
              errors={errors}
              value={values.fieldAgent}
              settings={{
                disableClearable: true,
                valueProp: '_id',
                labelProp: 'fullName',
              }}
              onChange={(selected) => {
                setFieldValue('fieldAgent', selected._id);
              }}
              options={accountManagers}
              required
            />
          </Grid>
        </Grid>

        {/***** PROCESSOR *******/}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="processor"
              label={'Processor'}
              errors={errors}
              value={values.processor}
              settings={{
                disableClearable: true,
                valueProp: '_id',
                labelProp: (o) => o.name2 || o.alias,
              }}
              onChange={(selected) => {
                setFieldValue('processorLocationId', null);
                setFieldValue('processor', selected._id);

                onProcessorChange(selected);
              }}
              options={processorOptions}
              required
            />
          </Grid>
          {!!values.processor && !!processorLocations.length && (
            <Grid item xs={12} md={3}>
              <AutocompleteSelect
                onBlur={handleBlur}
                name="processorLocationId"
                label={'Processor Locations'}
                errors={errors}
                value={values.processorLocationId}
                settings={{
                  disableClearable: false,
                  valueProp: '_id',
                  labelProp: (o) => `${o.name ? `${o.name}, ` : ''}${o.address}`,
                }}
                onChange={(selected) => {
                  setFieldValue('processorLocationId', selected ? selected._id : null);
                }}
                options={processorLocations}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="deviatingInvoiceAddressProcessor"
              label={'Deviating invoice address Processor'}
              errors={errors}
              value={values.deviatingInvoiceAddressProcessor}
              settings={{
                disableClearable: false,
                valueProp: '_id',
                labelProp: 'name2',
              }}
              onChange={(selected) => {
                setFieldValue('deviatingInvoiceAddressProcessor', selected ? selected._id : null);
              }}
              options={deviatings}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="accountManagerProcessor"
              label={'Account Manager Processor'}
              errors={errors}
              value={values.accountManagerProcessor}
              settings={{
                disableClearable: true,
                valueProp: '_id',
                labelProp: 'fullName',
              }}
              onChange={(selected) => {
                setFieldValue('accountManagerProcessor', selected._id);
              }}
              options={accountManagers}
              required
            />
          </Grid>
        </Grid>
      </Box>

      {/********** SECOND SECTION ************/}
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">{t('Transport')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <AutocompleteSelect
              onBlur={handleBlur}
              name="carrier"
              label={'Carrier'}
              errors={errors}
              value={values.carrier}
              settings={{
                disableClearable: false,
                valueProp: '_id',
                labelProp: 'name2',
              }}
              onChange={(selected) => {
                setFieldValue('carrier', selected ? selected._id : null);
                setFieldValue('vehicle', '');
              }}
              options={carriers}
              required={carrierRequired}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ChipsInput
              name="countries"
              value={values.countries}
              label="Transit country"
              errors={errors}
              required={false}
              setFieldValue={setFieldValue}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="transportationBudget"
              value={values.transportationBudget}
              label="Transportation Budget"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="shippingCost"
              value={values.shippingCost}
              label="Shipping Cost"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required={carrierRequired}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              name="currency"
              value={values.currency}
              label={'Currency'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { label: 'Euro', value: 'Euro' },
                { label: 'USD', value: 'USD' },
              ]}
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="minLoadingWeight"
              value={values.minLoadingWeight}
              label="Minimum Loading Weight"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required={carrierRequired}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              name="vehicle"
              value={values.vehicle}
              label={'Vehicle'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={vehicleOptions}
              disabled={vehicleOptions.length === 1}
              required={carrierRequired}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="bookingNo"
              value={values.bookingNo}
              label="Booking No"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="transportRemark"
              value={values.transportRemark}
              label="Transport remark"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Select
              name="category"
              value={values.category}
              label={'Certificate'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { label: t('Certificate Needed'), value: 'Certificate Needed' },
                { label: t('No Certificate Needed'), value: 'No Certificate Needed' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Select
              name="incotermSource"
              value={values.incotermSource}
              label={'Source Incoterm'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: null, label: '' },
                { value: 'EXW', label: t('EXW') },
                { value: 'DAP', label: t('DAP') },
                { value: 'FAS', label: t('FAS') },
                { value: 'CFR', label: t('CFR') },
                { value: 'DDP', label: t('DDP') },
              ]}
              required
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Select
              name="collectionSource"
              value={values.collectionSource}
              label={'Source Collection'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: null, label: '' },
                { label: t('Pickup From'), value: 'Pickup From' },
                { label: t('Pickup On'), value: 'Pickup On' },
                { label: t('Pickup Period'), value: 'Pickup Period' },
                { label: t('Pickup after arrangement'), value: 'Pickup after arrangement' },
              ]}
              required
            />
          </Grid>
          {values.collectionSource !== 'Pickup after arrangement' && (
            <Grid item xs={12} md={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YYYY"
                margin="dense"
                label={values.collectionSource === 'Pickup Period' ? t('Start') : t('Pickup Date')}
                value={values.pickupDate.startDate || null}
                onChange={(date) => {
                  setFieldValue('pickupDate.startDate', date ? date.toDate() : null);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
                fullWidth
              />
            </Grid>
          )}
          {values.collectionSource === 'Pickup Period' && values.collectionSource !== 'Pickup after arrangement' && (
            <Grid item xs={12} md={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YYYY"
                margin="dense"
                label={t('End')}
                value={values.pickupDate.endDate || null}
                onChange={(date) => {
                  setFieldValue('pickupDate.endDate', date ? date.toDate() : null);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <Input
              name="loadingReferenceSource"
              value={values.loadingReferenceSource}
              label="Source Loading reference"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Select
              name="incotermProcessor"
              value={values.incotermProcessor}
              label={'Processor Incoterm'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: null, label: '' },
                { value: 'EXW', label: t('EXW') },
                { value: 'DAP', label: t('DAP') },
                { value: 'FAS', label: t('FAS') },
                { value: 'CFR', label: t('CFR') },
                { value: 'DDP', label: t('DDP') },
              ]}
              required
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Select
              name="deliveryProcess"
              value={values.deliveryProcess}
              label={'Processor Delivery'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: null, label: '' },
                { value: 'Delivery After', label: t('Delivery After') },
                { value: 'Delivery On', label: t('Delivery On') },
                { value: 'Delivery Period', label: t('Delivery Period') },
                { value: 'Delivery Till', label: t('Delivery Till') },
                { value: 'Delivery after arrangement', label: t('Delivery after arrangement') },
              ]}
              required
            />
          </Grid>
          {values.deliveryProcess !== 'Delivery after arrangement' && (
            <Grid item xs={12} md={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YYYY"
                margin="dense"
                label={
                  values.deliveryProcess === 'Delivery Period' ? t('Start') : t('Delivery Date')
                }
                value={values.deliveryDate.startDate || null}
                onChange={(date) => {
                  setFieldValue('deliveryDate.startDate', date ? date.toDate() : null);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
                fullWidth
              />
            </Grid>
          )}
          {values.deliveryProcess === 'Delivery Period' &&
            values.deliveryProcess !== 'Delivery after arrangement' && (
              <Grid item xs={12} md={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD.MM.YYYY"
                  margin="dense"
                  label={'End'}
                  value={values.deliveryDate.endDate || null}
                  onChange={(date) => {
                    setFieldValue('deliveryDate.endDate', date ? date.toDate() : null);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                  fullWidth
                />
              </Grid>
            )}
          <Grid item xs={12} md={2}>
            <Input
              name="loadingReferenceProcessor"
              value={values.loadingReferenceProcessor}
              label="Unloading reference"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="internRemarks"
              value={values.internRemarks}
              label="Intern remarks"
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              multiline
              rows={5}
            />
          </Grid>

        </Grid>
      </Box>

      {/********** ARTICLES SECTION ************/}
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">{t('Articles')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <FormikProvider value={formik}>
            <ArticlesSection
              {...{
                values,
                articles,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                sourceAndProcessorSameCountry,
                selectedProcessor,
                selectedSource,
                sourceIsWarehouse,
                processorIsWarehouse,
              }}
            />
          </FormikProvider>
        </Grid>
      </Box>

      {/********** COMMISSION ARTICLES SECTION ************/}
      <Box boxShadow={3} p={2} mb={2}>
        <Box pb={2}>
          <Typography variant="h4">{t('Commission')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <FormikProvider value={formik}>
            <CommissionArticlesSection
              {...{
                values,
                errors,
                handleChange,
                handleBlur,
                setFieldValue,
                partners,
              }}
            />
          </FormikProvider>
        </Grid>
      </Box>
    </>
  );
};
