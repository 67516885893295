import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// Helpers
import { useCompanies } from 'hooks';
import { PATHS } from 'util/appConstants';

// Actions
import { selectBank, selectBankStatus, getBank, editBank } from 'redux/slices/bankSlice';

// Components
import BankForm from './BankForm';


const currentAction = 'EDIT';

const EditBank = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectBankStatus);
  const bank = useSelector(selectBank);
  const { companies } = useCompanies();

  useEffect(() => {
    if ((!bank || bank._id !== id) && id && !loading) {
      dispatch(getBank(id));
    }
  }, [bank, id]);

  const handleEditBank = async (payload) => {
    await dispatch(editBank(id, payload));

    history.push(PATHS.system.banks.root);
  };

  if (loading || !bank) return <div>Loading..</div>;
  return (
    <BankForm
      initialValues={bank}
      handleEditBank={handleEditBank}
      companies={companies}
      action={currentAction}
    />
  );
};

export default EditBank;
