import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from 'util/appConstants';
import Alert from '@material-ui/lab/Alert';
import {
  usePartners,
  useDeviatingPartners,
  useCarriers,
  useArticles,
  useAccountManagers,
  useWarehouses,
} from 'hooks';

import { selectOrderStatus, fetchOrderLocations, createOrder } from 'redux/slices/ordersSlice';

// Components
import OrdersForm from 'components/Orders/Form';

const AddContract = () => {
  const [tab, setTab] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const loading = useSelector(selectOrderStatus);
  const { partners, loading: partnersLoading, timestamp } = usePartners();
  const { deviatings, loading: deviatingsLoading } = useDeviatingPartners();
  const { carriers, loading: carriersLoading } = useCarriers();
  const { articles, loading: articlesLoading } = useArticles();
  const { warehouses, loading: warehousesLoading } = useWarehouses();
  const { accountManagers, loading: amLoading } = useAccountManagers();
  const [sourceLocations, setSourceLocations] = useState([]);
  const [processorLocations, setProcessorLocations] = useState([]);

  const depsLoading = partnersLoading || deviatingsLoading || carriersLoading || articlesLoading || warehousesLoading || amLoading;

  const onSubmit = async (values) => {
    await dispatch(createOrder(values));

    return history.push(PATHS.orders.root);
  };

  const onSourceChange = async (selected) => {
    const data = await dispatch(fetchOrderLocations(selected._id));

    setSourceLocations(data);
  };

  const onProcessorChange = async (selected) => {
    const data = await dispatch(fetchOrderLocations(selected._id));

    setProcessorLocations(data);
  };

  if (loading || depsLoading || !timestamp) {
    return (
      <div>Loading...</div>
    );
  }

  if (
    !partners.length ||
    !deviatings.length ||
    !carriers.length ||
    !articles.length ||
    !accountManagers.length
  ) {
    return (
      <Alert severity="info">
        {t('Some of the required resources might be missing. Check that you created Partners, Carriers, Articles, Team members and Warehouses')}
      </Alert>
    );
  };

  return (
    <OrdersForm
      onSubmit={onSubmit}
      onSourceChange={onSourceChange}
      onProcessorChange={onProcessorChange}
      partners={partners}
      deviatings={deviatings}
      carriers={carriers}
      articles={articles}
      warehouses={warehouses}
      accountManagers={accountManagers}
      sourceLocations={sourceLocations}
      processorLocations={processorLocations}
      tab={tab}
      setTab={setTab}
    />
  );
};

export default AddContract;
