import axios from 'axios';
import { storage } from 'util/storage';
import { parseMethod, interceptedMethods } from './helpers';
class Api {
  axiosInstance;

  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: { 'Content-Type': 'application/json' },
    });

    /**
     * Log endpoint calls to console in development
     * Set default authorization headers
     */
    this.axiosInstance.interceptors.request.use(config => {
      if (process.env.NODE_ENV === 'development') {
        if (config.method === 'get' || config.method === 'delete') {
          console.info(`${config.method} - ${config.url}`);
        }
        if (interceptedMethods.some(m => m === config.method)) {
          console.info(`${config.method} - ${config.url}, ${JSON.stringify(config.data)}`);
        }
      }

      config.headers['Authorization'] = this.getAuthToken();
      return config;
    }, err => {
      console.error(err);
    });

    this.axiosInstance.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        storage.clear();

        if (!window.location.href.includes('/login')) {
          return window.location.href = '/login';
        }
      }

      return error.response.data;
    });
  }

  getAuthToken = () => {
    return `Bearer ${storage.get('token')}`;
  };

  async fetch(url, method = 'GET', data = {}, config, params = {}, rawResponse = false) {
    try {
      let options = {
        method: parseMethod(method),
        data,
        params,
        ...config,
      };


      const response = await this.axiosInstance({ url, ...options });

      if (rawResponse) {
        return response;
      }

      if (response) {
        return response.data ?? response;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  async post(url, data, config) {
    return this.fetch(url, 'POST', data, config);
  }

  async put(url, data, config) {
    return this.fetch(url, 'PUT', data, config);
  }

  async patch(url, data, config) {
    return this.fetch(url, 'PATCH', data, config);
  }

  async delete(url, config) {
    return this.fetch(url, 'DELETE', undefined, config);
  }
}

const baseURL = process.env.REACT_APP_API_URL;

export { baseURL };
export const coreApi = new Api(baseURL);
export default Api;
