import React from 'react';
import * as merge from 'lodash/merge';
import PropTypes from 'prop-types';
import * as pick from 'lodash/pick';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Box, Typography, TextField, Button } from '@material-ui/core';
import {
  Input,
  InputOnlyNumbers,
  Select,
  ColorInput,
  CountriesAutocomplete,
} from 'components/Shared/mui-formik-inputs';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RichTextEditor from 'components/Shared/rich-text-editor';
import FileUploadSection from './file-upload-form-section';
import { CompanySchema, CompanyFormAllowedFields } from './constants';
import { PATHS } from 'util/appConstants';
import { handleZipcodeChange } from 'util/index';

const useStyles = makeStyles(() => ({
  button: {
    margin: 10,
    backgroundColor: 'white',
  },
  buttonSubmit: {
    color: 'green',

    '&:hover': {
      color: '#fff',
    },
  },
  buttonCancel: {
    color: 'red',
  },
}));

const CompanyForm = ({ initialValues, handleAddCompany, handleEditCompany, banks, action }) => {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: CompanySchema,
    initialValues: {
      document_type_hack: 'default',
      security: 'SSL',
      documents: {
        default: { subject: '', description: '' },
        annex: { subject: '', description: '' },
        purchase_confirmation: { subject: '', description: '' },
        sales_confirmation: { subject: '', description: '' },
        transport_information: { subject: '', description: '' },
        transport_order: { subject: '', description: '' },
        invoice: { subject: '', description: '' },
        billing_confirmation: { subject: '', description: '' },
        invoice_cancellation: { subject: '', description: '' },
        sales_contract: { subject: '', description: '' },
        commission_invoice: { subject: '', description: '' },
        commission_confirmation: { subject: '', description: '' },
        ...(initialValues.documents || {}),
      },
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (action === 'ADD') {
          return await handleAddCompany(pick(values, CompanyFormAllowedFields));
        }
        if (action === 'EDIT') {
          return await handleEditCompany(values._id, pick(values, CompanyFormAllowedFields));
        }
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const { values, handleChange, setFieldValue, handleSubmit, resetForm, errors, submitCount, isValid, submitting } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Box boxShadow={3} p={2}>
        <Typography variant='h4'>{t('Basic Data')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Company Code'
              name='companyCode'
              value={values.companyCode}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'Name1'}
              name='name1'
              value={values.name1}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={'Name2'}
              name='name2'
              value={values.name2}
              onChange={handleChange}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Street'
              name='street'
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='House Number'
              name='houseNumber'
              value={values.houseNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Zipcode'
              name='zipcode'
              value={values.zipcode}
              onChange={handleZipcodeChange(handleChange, setFieldValue, i18n.language)}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='City'
              name='city'
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <CountriesAutocomplete
              name='country'
              label={'Country'}
              setFieldValue={setFieldValue}
              value={values.country}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label={'Phone 1'}
              value={values.phone1}
              name={'phone1'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <InputOnlyNumbers
              label={'Phone 2'}
              value={values.phone2}
              name={'phone2'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Fax'
              name='fax'
              value={values.fax}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Website'
              name='website'
              value={values.website}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Email'
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='E-Mail for documents'
              name='docEmail'
              value={values.docEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              label={'Pin Color'}
              value={values.pinColor}
              name={'pinColor'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                'Red', 'Yellow', 'Blue', 'Green', 'Purple',
              ].map((c) => ({ label: c, value: c }))}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <ColorInput
              label={'Primary Color'}
              value={values.primaryColor}
              name={'primaryColor'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <ColorInput
              label={'Secondary Color'}
              value={values.secondaryColor}
              name={'secondaryColor'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Disponumber'
              name='disponumber'
              value={values.disponumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Article Number'
              name='articleNumber'
              value={values.articleNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Invoice number prefix'
              name='invoiceNumberPrefix'
              value={values.invoiceNumberPrefix}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Invoice number'
              name='invoiceNumber'
              value={values.invoiceNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Invoice number suffix'
              name='invoiceNumberSuffix'
              value={values.invoiceNumberSuffix}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='SC No.'
              name='scNumber'
              value={values.scNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='SC No. Prefix'
              name='scNumberPrefix'
              value={values.scNumberPrefix}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='SC No. Suffix'
              name='scNumberSuffix'
              value={values.scNumberSuffix}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Purchase Email Address'
              name='purchaseEmailAddress'
              value={values.purchaseEmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Sales Email Address'
              name='salesEmailAddress'
              value={values.salesEmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Transport Email Address'
              name='transportEmailAddress'
              value={values.transportEmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Purchase Invoice Email Address'
              name='purchaseInvoiceEmailAddress'
              value={values.purchaseInvoiceEmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Sales Invoice Email Address'
              name='salesInvoiceEmailAddress'
              value={values.salesInvoiceEmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Archive Email'
              name='bccDocsEmail'
              value={values.bccDocsEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} my={4}>
        <Typography variant='h4'>{t('Invoice Data')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Executive Director'
              name='executiveDirector'
              value={values.executiveDirector}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Commercial Register Number'
              name='commercialRegisterNumber'
              value={values.commercialRegisterNumber}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Commercial Register'
              name='commercialRegister'
              value={values.commercialRegister}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Complementary'
              name='complementary'
              value={values.complementary}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='VAT-ID'
              name='ustID'
              required
              value={values.ustID}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Tax Number'
              name='taxNumber'
              value={values.taxNumber}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} my={4}>
        <Typography variant='h4'>{t('Banking Details')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Autocomplete
              id='combo-box-two'
              name='bank'
              disableClearable
              options={banks}
              onChange={(event, newValue) => {
                const { _id, bankName } = newValue;
                setFieldValue('bankId', _id);
                setFieldValue('bank', bankName);
              }}
              getOptionLabel={(option) => option.bankName ?? ''}
              getOptionSelected={(option) => option.bankName === values.bank}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Bank'}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: `new-password`,
                  }}
                  margin='dense'
                  error={errors.bankId || errors.bank}
                  helperText={errors.bankId || errors.bank ? t('Required') : null}
                  required
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} my={4}>
        <Typography variant='h4'>{t('Documents Description')}</Typography>
        <Grid container direction='column'>
          <Grid item xs={12} lg={4}>
            <Select
              label={'Select document type'}
              value={values.document_type_hack}
              name='document_type_hack'
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { value: 'default', label: t('Default') },
                { value: 'annex', label: t('Annex Document') },
                { value: 'purchase_confirmation', label: t('Purchase Confirmation') },
                { value: 'sales_confirmation', label: t('Sales Confirmation') },
                { value: 'transport_information', label: t('Transportation Information') },
                { value: 'invoice', label: t('Invoice Document') },
                { value: 'transport_order', label: t('Transportation Order') },
                { value: 'billing_confirmation', label: t('Billing confirmation') },
                { value: 'invoice_cancellation', label: t('Invoice Cancellation') },
                { value: 'sales_contract', label: t('Sales Contract') },
                { value: 'commission_invoice', label: t('Commission Invoice') },
                { value: 'commission_confirmation', label: t('Commission Confirmation') },
              ]}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Input
              label={'Subject'}
              value={values.documents[values.document_type_hack].subject}
              name={`documents.${values.document_type_hack}.subject`}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              required={values.document_type_hack === 'default'}
            />
            <RichTextEditor
              label={`${t('E-mail description')}${values.document_type_hack === 'default' ? '*' : ''}`}
              name={`documents.${values.document_type_hack}.description`}
              value={values.documents[values.document_type_hack].description}
              onChange={setFieldValue}
              onBlur={handleBlur}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} p={2} my={4}>
        <Typography variant='h4'>{t('SMTP Details')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Sender name'
              name='senderName'
              value={values.senderName}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Username'
              name='username'
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Password'
              name='password'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Host'
              name='host'
              value={values.host}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label='Port'
              name='port'
              value={values.port}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              type='number'
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select
              label={'Security'}
              value={values.security}
              name={'security'}
              errors={errors}
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                'SSL', 'TLS',
              ].map((c) => ({ label: c, value: c }))}
              required
            />
          </Grid>
        </Grid>
      </Box>
      <FileUploadSection
        values={values}
        setFieldValue={setFieldValue}
      />
      <Button
        variant='contained'
        color='primary'
        startIcon={<CloudUploadIcon />}
        // style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
        className={clsx(classes.button, classes.buttonSubmit)}
        type='submit'
        disabled={submitting || !isValid}
      >
        {t('Company') + ' ' + t(`${action}`)}
      </Button>
      <Button
        className={clsx(classes.button, classes.buttonCancel)}
        variant='contained'
        startIcon={<CancelIcon />}
        onClick={() => {
          resetForm({});
          return history.push(PATHS.companies.root);
        }}
      >
        {t('Cancel')}
      </Button>
    </form>
  );
};

CompanyForm.propTypes = {
  initialValues: PropTypes.shape({}),
  handleAddCompany: PropTypes.func,
  handleEditCompany: PropTypes.func,
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CompanyForm;
