import { useCallback } from 'react';
import useBaseHook from './useBaseHook';
import { selectBanks, getBanks, selectBankStatus, selectTimestamp } from 'redux/slices/bankSlice';

const useBanks = () => {
  const {
    records: banks,
    loading,
    revalidate,
  } = useBaseHook({
    recordsSelector: selectBanks,
    fetchRecords: getBanks,
    timestampSelector: selectTimestamp,
    loadingSelector: selectBankStatus,
  });

  return { banks, loading, revalidate };
};

export default useBanks;
