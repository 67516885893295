import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// Helpers
import { PATHS, formActions } from 'util/appConstants';
import { defaultValues } from 'components/System/Teams/constants';

// Actions
import {
  editPerson,
  fetchPersonById,
  selectPersonLoading,
  clearCurrentTeamMember,
  selectCurrentTeamMember,
} from 'redux/slices/personSlice';

// Components
import TeamForm from 'components/System/Teams/TeamForm';

// Very weird bug with enableReinitialize not working, so this hack is fixing the form values being reset
// when getting to addForm after coming from editForm
function getInitialValues(person) {
  return {
    ...defaultValues,
    ...person,
    email: person.userId.email,
    password: '',
  };
}

const EditTeamMember = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: personId } = useParams();
  const loading = useSelector(selectPersonLoading);
  const person = useSelector(selectCurrentTeamMember);

  useEffect(() => {
    dispatch(fetchPersonById(personId));

    return () => {
      dispatch(clearCurrentTeamMember());
    };
  }, []);

  const handleSubmit = async (id, payload) => {
    const { password, ...rest } = payload;

    if (password) {
      rest.password = password;
    }

    await dispatch(editPerson(id, rest));

    history.push(PATHS.system.team.root);
  };

  if (loading || !person) return <div>Loading..</div>;
  return (
    <TeamForm
      onSubmit={handleSubmit}
      action={formActions.edit}
      initialValues={getInitialValues(person)}
    />
  );
};

export default EditTeamMember;
