import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import TransportationInformation from '../pdfToHtml/transportationInformation-new';
import SalesConf from '../pdfToHtml/salesConf-new';
import PurchaseConf from '../pdfToHtml/purchaseConf-new';
import AnnexDocument from '../pdfToHtml/annex-new';
import BillingConfirmation from '../pdfToHtml/billingconfirmation';
import InvoiceCancellation from '../pdfToHtml/invoice-cancellation';
import InvoiceCorrection from '../pdfToHtml/invoice-correction';
import CommissionConfirmation from '../pdfToHtml/commission-confirmation';
import CommissionInvoice from '../pdfToHtml/commission-invoice';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Invoice from '../pdfToHtml/invoice-new';
import { Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import TransportationOrder from '../pdfToHtml/transportationOrder-new';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { fetchEmailUsers, selectPersonLoading } from 'redux/slices/personSlice';

const defaultData = {
  purchaseConfirmation: { emails: [] },
  salesConfirmation: { emails: [] },
  transportOrder: { emails: [] },
  annex: { emails: [] },
  transportInformation: { emails: [] },
  invoiceSource: { emails: [] },
  invoiceProcessor: { emails: [] },
  billingConfirmation: { emails: [] },
  invoice_cancellation_source: { emails: [] },
  invoice_cancellation_processor: { emails: [] },
  invoice_correction_source: { emails: [] },
  invoice_correction_processor: { emails: [] },
  commission_confirmation: { emails: [] },
  commission_invoice: { emails: [] },
};

const emailError = 'E-mail addresses are required';

export default function OnAddOrderPopup(props) {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('common');
  const {
    state,
    createDocumentProps,
    checked,
    checkIfDocAlreadySent,
    currentEntity,
    setLoading,
  } = props;
  const [form, setForm] = useState(defaultData);
  const [users, setUsers] = React.useState({
    annex: [],
    billing_confirmation: [],
    invoice: [],
    invoice_source: [],
    invoice_processor: [],
    purchase_confirmation: [],
    sales_confirmation: [],
    transport_information: [],
    transport_order: [],
    commission: [],
  });
  const [amLoading, setAmLoading] = useState(false);
  const loading = useSelector(selectPersonLoading);
  const [errors, setErrors] = useState({});
  const sourceId = currentEntity.source ? currentEntity.source._id : null;
  const processorId = currentEntity.processor ? currentEntity.processor._id : null;
  const carrierId = currentEntity.carrier ? currentEntity.carrier._id : null;
  const commissionPartners = currentEntity.commissionArticles.map((ca) => {
    if (ca.commissionPartner?._id || ca.commissionPartner?.id) {
      return ca.commissionPartner?._id || ca.commissionPartner?.id;
    }

    return ca.commissionPartner;
  });

  const fetchUsers = async () => {
    const res = await dispatch(fetchEmailUsers([sourceId, processorId, carrierId, currentEntity._id, commissionPartners]));

    setUsers(res.data);
  };

  useEffect(() => {
    if (!loading) {
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    if (state.sendorderdocument) {
      setForm({ ...form, ...defaultData });
    }

    return () => {
      setForm({ ...form, ...defaultData });
    };
  }, [state.sendorderdocument]);

  useEffect(() => {
    if (checked.invoice_source && !checkIfDocAlreadySent('invoice_source')) {
      let data = form;
      let partner = currentEntity.source
      const deviatingSourcePartner = currentEntity.deviatingInvoiceAddressSource ?
        currentEntity.deviatingInvoiceAddressSource :
        null;

      if (deviatingSourcePartner && deviatingSourcePartner.invoiceEmail && deviatingSourcePartner.invoiceEmail.length > 0 ) {
        data.invoiceSource.emails = [...deviatingSourcePartner.invoiceEmail]
        data.invoiceSource.partnerEmails = [...deviatingSourcePartner.invoiceEmail]
      } else {
        data.invoiceSource.emails = [...(partner ? partner.invoiceEmail : [])];
        data.invoiceSource.partnerEmails = [...(partner ? partner.invoiceEmail : [])];
      }
      setForm({ ...form, ...data });
    }

    if (checked.invoice_processor && !checkIfDocAlreadySent('invoice_processor')) {
      let data = form;
      let partner = currentEntity.processor;
      const deviatingProcessorPartner = currentEntity?.deviatingInvoiceAddressProcessor ?
        currentEntity.deviatingInvoiceAddressProcessor :
        null

      if (deviatingProcessorPartner && deviatingProcessorPartner.invoiceEmail && deviatingProcessorPartner.invoiceEmail.length > 0 ) {
        data.invoiceProcessor.emails = [...deviatingProcessorPartner.invoiceEmail];
        data.invoiceProcessor.partnerEmails = [...deviatingProcessorPartner.invoiceEmail];
      } else {
        data.invoiceProcessor.emails = [...(partner ? partner.invoiceEmail : [])];
        data.invoiceProcessor.partnerEmails = [...(partner ? partner.invoiceEmail : [])];
      }

      setForm({ ...form, ...data });
    }
  }, [checked.invoiceProcessor, checked.invoiceSource]);

  const handleClose = () => {
    props.closeDialogCancel(reset);
  };

  const handleCloseOk = async () => {
    setLoading(true);
    let data = { ...form };
    let errors = validate(data);
    if (Object.keys(errors).length === 0) {
      setAmLoading(true);
      await addDocs(data);
    } else {
      setLoading(false);
      setErrors(errors);
    }
  };

  const addDocs = () => {
    let data = { ...form };
    let userData = data.form ? data.form : data;
    for (let key of Object.keys(userData)) {
      if (checked[_.snakeCase(key)] && !props.checkIfDocAlreadySent(_.snakeCase(key))) {
        const docContent = getDocContent(key);
        userData[key].doc = JSON.stringify(docContent);
      } else {
        userData[key] = {};
      }
    }

    setForm({ ...form, ...defaultData });
    return props.closeDialogOk(userData, reset);
  };

  const validate = (data) => {
    let userData = data.form ? data.form : data;
    let errors = {};

    for (let key of Object.keys(userData)) {
      if (
        (!data[key].emails || data[key].emails.length === 0) &&
        checked[_.snakeCase(key)] && !currentEntity.docStatus[_.snakeCase(key)].status
      ) {
        errors[key] = emailError;
      }
    }

    return errors;
  };

  function reset() {
    setForm({ ...form, ...defaultData });
  }

  function handleAutocompleteChange(event, newValue, type) {
    let data = form;

    if (newValue.length > 0) {
      const docContent = getDocContent(type);
      let emails = data[type] && data[type].emails ? data[type].emails : [];
      let partnerEmails = data[type].partnerEmails ? data[type].partnerEmails : []; //adding an entry partner emails to group partner emails later

      let payload = {
        doc: JSON.stringify(docContent),
      };

      newValue.map((value) => {
        if (emails.length === 0) {
          emails = value.emails;
          partnerEmails = value.emails;
        } else {
          for (let email of value.emails) {
            emails.push(email);
            partnerEmails.push(email);
          }
        }
      });

      // removing duplicates email entries
      emails = new Set(emails);
      payload.emails = [...emails];
      partnerEmails = new Set(partnerEmails);
      payload.partnerEmails = [...partnerEmails];
      data[type] = payload;
    } else {
      data[type] = {};
      data[type]['emails'] = [];
      data[type]['partnerEmails'] = [];
    }

    setForm({ ...form, ...data });
  }

  function getDocContent(type) {
    let html = '';
    if (type == 'purchaseConfirmation') {
      html = ReactDOMServer.renderToStaticMarkup(
        <PurchaseConf language={i18n.language} {...createDocumentProps()} />,
      );
    }
    if (type == 'salesConfirmation') {
      html = ReactDOMServer.renderToStaticMarkup(
        <SalesConf language={i18n.language} {...createDocumentProps()} />,
      );
    }
    if (type == 'transportOrder') {
      html = ReactDOMServer.renderToStaticMarkup(
        <TransportationOrder language={i18n.language} {...createDocumentProps()} />,
      );
    }
    if (type == 'annex') {
      html = ReactDOMServer.renderToStaticMarkup(
        <AnnexDocument language={i18n.language} {...props} {...createDocumentProps()} />,
      );
    }
    if (type == 'transportInformation') {
      html = ReactDOMServer.renderToStaticMarkup(
        <TransportationInformation language={i18n.language} {...createDocumentProps()} />,
      );
    }

    if (type == 'invoiceSource') {
      html = ReactDOMServer.renderToStaticMarkup(
        <Invoice language={i18n.language} dataSource={'sourceData'} {...createDocumentProps()} />,
      );
    }
    if (type == 'invoiceProcessor') {
      html = ReactDOMServer.renderToStaticMarkup(
        <Invoice
          language={i18n.language}
          dataSource={'processorData'}
          {...createDocumentProps()}
        />,
      );
    }
    if (type == 'billingConfirmation') {
      html = ReactDOMServer.renderToStaticMarkup(
        <BillingConfirmation language={i18n.language} {...createDocumentProps()} />,
      );
    }

    if (type == 'invoice_correction_source') {
      html = ReactDOMServer.renderToStaticMarkup(
        <InvoiceCorrection
          dataSource={'sourceData'}
          language={i18n.language}
          {...currentEntity.correctionInvoice.order_details}
        />,
      );
    }

    if (type == 'invoice_correction_processor') {
      html = ReactDOMServer.renderToStaticMarkup(
        <InvoiceCorrection
          language={i18n.language}
          dataSource={'processorData'}
          {...currentEntity.correctionInvoice.order_details}
        />,
      );
    }

    if (type == 'invoice_cancellation_source') {
      html = ReactDOMServer.renderToStaticMarkup(
        <InvoiceCancellation
          dataSource={'sourceData'}
          language={i18n.language}
          {...currentEntity.cancellationInvoice.order_details}
        />,
      );
    }

    if (type == 'invoice_cancellation_processor') {
      html = ReactDOMServer.renderToStaticMarkup(
        <InvoiceCancellation
          language={i18n.language}
          dataSource={'processorData'}
          {...currentEntity.cancellationInvoice.order_details}
        />,
      );
    }

    if (type === 'commission_confirmation') {
      html = ReactDOMServer.renderToStaticMarkup(
        <CommissionConfirmation language={i18n.language} {...createDocumentProps()} />
      );
    }

    if (type === 'commission_invoice') {
      html = ReactDOMServer.renderToStaticMarkup(
        <CommissionInvoice language={i18n.language} {...createDocumentProps()} />
      );
    }

    return html;
  }

  function handleChange(chip, type) {
    setForm({
      ...form,
      [type]: {
        ...form[type],
        emails: (form[type].emails || []).concat([chip]),
      },
    });
  }

  function handleDelete(chip, index, type) {
    let data = form;
    let emails = [...data[type]['emails']];
    emails.splice(index, 1);

    data[type]['emails'] = emails;
    setForm({ ...form, ...data });
  }

  return (
    <Dialog open={state.sendorderdocument} onClose={handleClose} maxWidth={'lg'}>
      <AppBar position="static">
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {t('Select Person')}
          </Typography>
        </Toolbar>
      </AppBar>
      {(loading || amLoading) && <LinearProgress color="secondary" />}

      <DialogContent style={{ minHeight: '400px', minWidth: '700px' }}>
        {checked.purchase_confirmation && !checkIfDocAlreadySent('purchase_confirmation') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.purchaseConfirmation.personNameWithResponsibility}
                options={users.purchase_confirmation || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'purchaseConfirmation');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Purchase Confirmation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.purchaseConfirmation.emails}
                onAdd={(chip) => handleChange(chip, 'purchaseConfirmation')}
                onDelete={(chip, index) => handleDelete(chip, index, 'purchaseConfirmation')}
                placeholder={t('E-mail addresses*')}
                error={errors.purchaseConfirmation !== undefined}
              />
              {errors.purchaseConfirmation !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.purchaseConfirmation)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.sales_confirmation && !checkIfDocAlreadySent('sales_confirmation') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                id="combo-box-demo"
                value={form.salesConfirmation.personNameWithResponsibility}
                options={users.sales_confirmation || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'salesConfirmation');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Sales Confirmation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.salesConfirmation.emails}
                onAdd={(chip) => handleChange(chip, 'salesConfirmation')}
                onDelete={(chip, index) => handleDelete(chip, index, 'salesConfirmation')}
                error={errors.salesConfirmation !== undefined}
                placeholder={t('E-mail addresses*')}
              />
              {errors.salesConfirmation !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.salesConfirmation)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.transport_order && !checkIfDocAlreadySent('transport_order') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                id="combo-box-demo"
                value={form.transportOrder.personNameWithResponsibility}
                options={users.transport_order || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'transportOrder');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Transportation Order')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.transportOrder.emails}
                onAdd={(chip) => handleChange(chip, 'transportOrder')}
                onDelete={(chip, index) => handleDelete(chip, index, 'transportOrder')}
                placeholder={t('E-mail addresses*')}
                error={errors.transportOrder !== undefined}
              />
              {errors.transportOrder !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.transportOrder)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.annex && !checkIfDocAlreadySent('annex') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                id="combo-box-demo"
                value={form.annex.personNameWithResponsibility}
                options={users.annex || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'annex');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Annex Document')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.annex.emails}
                onAdd={(chip) => handleChange(chip, 'annex')}
                onDelete={(chip, index) => handleDelete(chip, index, 'annex')}
                placeholder={t('E-mail addresses*')}
                error={errors.annex !== undefined}
              />
              {errors.annex !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.annex)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.transport_information && !checkIfDocAlreadySent('transport_information') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                id="combo-box-demo"
                value={form.transportInformation.personNameWithResponsibility}
                options={users.transport_information || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'transportInformation');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Transportation Notification')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.transportInformation.emails}
                onAdd={(chip) => handleChange(chip, 'transportInformation')}
                onDelete={(chip, index) => handleDelete(chip, index, 'transportInformation')}
                placeholder={t('E-mail addresses*')}
                error={errors.transportInformation !== undefined}
              />
              {errors.transportInformation !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.transportInformation)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_source && !checkIfDocAlreadySent('invoice_source') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoiceSource.personNameWithResponsibility}
                options={users.invoice_source || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoiceSource');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Loading Place Invoice')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoiceSource.emails}
                onAdd={(chip) => handleChange(chip, 'invoiceSource')}
                onDelete={(chip, index) => handleDelete(chip, index, 'invoiceSource')}
                placeholder={t('E-mail addresses*')}
                error={errors.invoiceSource !== undefined}
              />
              {errors.invoiceSource !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.invoiceSource)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_processor && !checkIfDocAlreadySent('invoice_processor') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoiceProcessor.personNameWithResponsibility}
                options={users.invoice_processor || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoiceProcessor');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Unloading Place Invoice')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoiceProcessor.emails}
                onAdd={(chip) => handleChange(chip, 'invoiceProcessor')}
                onDelete={(chip, index) => handleDelete(chip, index, 'invoiceProcessor')}
                placeholder={t('E-mail addresses*')}
                error={errors.invoiceProcessor !== undefined}
              />
              {errors.invoiceProcessor !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.invoiceProcessor)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_cancellation_source && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoice_cancellation_source.personNameWithResponsibility}
                options={users.invoice || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoice_cancellation_source');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Source Invoice Cancellation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoice_cancellation_source.emails}
                onAdd={(chip) => handleChange(chip, 'invoice_cancellation_source')}
                onDelete={(chip, index) => handleDelete(chip, index, 'invoice_cancellation_source')}
                placeholder={t('E-mail addresses*')}
                error={errors.invoice_cancellation_source !== undefined}
              />
              {errors.invoice_cancellation_source !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.invoice_cancellation_source)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_cancellation_processor && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoice_cancellation_processor.personNameWithResponsibility}
                options={users.invoice || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoice_cancellation_processor');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Processor Invoice Cancellation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoice_cancellation_processor.emails}
                onAdd={(chip) => handleChange(chip, 'invoice_cancellation_processor')}
                onDelete={(chip, index) =>
                  handleDelete(chip, index, 'invoice_cancellation_processor')
                }
                placeholder={t('E-mail addresses*')}
                error={errors.invoice_cancellation_processor !== undefined}
              />
              {errors.invoice_cancellation_processor !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>
                  {t(errors.invoice_cancellation_processor)}
                </p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_correction_source && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoice_correction_source.personNameWithResponsibility}
                options={users.invoice || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoice_correction_source');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Source Initial Invoice')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoice_correction_source.emails}
                onAdd={(chip) => handleChange(chip, 'invoice_correction_source')}
                onDelete={(chip, index) => handleDelete(chip, index, 'invoice_correction_source')}
                placeholder={t('E-mail addresses*')}
                error={errors.invoice_correction_source !== undefined}
              />
              {errors.invoice_correction_source !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.invoice_correction_source)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.invoice_correction_processor && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.invoice_correction_processor.personNameWithResponsibility}
                options={users.invoice || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'invoice_correction_processor');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Initial Invoice')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.invoice_correction_processor.emails}
                onAdd={(chip) => handleChange(chip, 'invoice_correction_processor')}
                onDelete={(chip, index) =>
                  handleDelete(chip, index, 'invoice_correction_processor')
                }
                placeholder={t('E-mail addresses*')}
                error={errors.invoice_correction_processor !== undefined}
              />
              {errors.invoice_correction_processor !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.invoice_correction_processor)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.billing_confirmation && !checkIfDocAlreadySent('billingConfirmation') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.billingConfirmation.personNameWithResponsibility}
                options={users.billing_confirmation || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'billingConfirmation');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Billing confirmation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.billingConfirmation.emails}
                onAdd={(chip) => handleChange(chip, 'billingConfirmation')}
                onDelete={(chip, index) => handleDelete(chip, index, 'billingConfirmation')}
                placeholder={t('E-mail addresses*')}
                error={errors.billingConfirmation !== undefined}
              />
              {errors.billingConfirmation !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.billingConfirmation)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.commission_confirmation && !checkIfDocAlreadySent('commission_confirmation') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.commission_confirmation.personNameWithResponsibility}
                options={users.commission || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'commission_confirmation');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Commission confirmation')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.commission_confirmation.emails}
                onAdd={(chip) => handleChange(chip, 'commission_confirmation')}
                onDelete={(chip, index) => handleDelete(chip, index, 'commission_confirmation')}
                placeholder={t('E-mail addresses*')}
                error={errors.commission_confirmation !== undefined}
              />
              {errors.commission_confirmation !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.commission_confirmation)}</p>
              )}
            </Grid>
          </Grid>
        )}

        {checked.commission_invoice && !checkIfDocAlreadySent('commission_invoice') && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Autocomplete
                style={{
                  margin: 10,
                  width: '100%',
                  display: 'inline-flex',
                }}
                multiple
                limitTags={1}
                value={form.commission_invoice.personNameWithResponsibility}
                options={users.commission || []}
                onChange={(event, newValue) => {
                  handleAutocompleteChange(event, newValue, 'commission_invoice');
                }}
                getOptionLabel={(option) =>
                  `${option.firstName}${option.surname ? ` ${option.surname}` : ''}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Commission invoice')}
                    autoComplete={'off'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: `off`,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ChipInput
                classes={{
                  root: 'w-100',
                  input: 'mg-top-20',
                  chipContainer: 'mg-top-20',
                }}
                value={form.commission_invoice.emails}
                onAdd={(chip) => handleChange(chip, 'commission_invoice')}
                onDelete={(chip, index) => handleDelete(chip, index, 'commission_invoice')}
                placeholder={t('E-mail addresses*')}
                error={errors.commission_invoice !== undefined}
              />
              {errors.commission_invoice !== undefined && (
                <p style={{ color: 'red', margin: 0 }}>{t(errors.commission_invoice)}</p>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'red' }}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleCloseOk} color="primary" disabled={loading || amLoading}>
          {t('Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
