import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { useCompanies } from 'hooks';
import { PATHS } from 'util/appConstants';
import { initialValues } from './constants';

// Actions
import { selectBankStatus, addBank } from 'redux/slices/bankSlice';

// Components
import BankForm from './BankForm';


const currentAction = 'ADD';

const AddBank = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectBankStatus);
  const { companies } = useCompanies();

  const handleAddBank = async (payload) => {
    const res = await dispatch(addBank(payload));

    if (typeof res !== 'string') {
      return history.push(PATHS.system.banks.root);
    }
  };

  if (loading) return <div>Loading..</div>;
  return (
    <BankForm
      initialValues={initialValues}
      handleAddBank={handleAddBank}
      companies={companies}
      action={currentAction}
    />
  );
};

export default AddBank;
