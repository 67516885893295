
import React from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Input,
  AutocompleteSelect,
  Select,
} from 'components/Shared/mui-formik-inputs';

const filter = createFilterOptions();

export default ({
  values,
  errors,
  partners,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <FieldArray name="commissionArticles">
      {({ remove, push }) => (
        <Grid item xs={12}>
          {
            values.commissionArticles.map((article, i) => (
              <Box key={i} boxShadow={10} p={2} my={2}>
                <Grid container item xs={12} spacing={2} key={i}>
                  <Grid item xs={12} md={3}>
                    <Input
                      name={`commissionArticles.${i}.source_deviating`}
                      value={article.source_deviating}
                      label="source_deviating"
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={`commissionArticles.${i}.commissionPartner`}
                      label={'Commission Partner'}
                      errors={errors}
                      value={article.commissionPartner}
                      settings={{
                        disableClearable: false,
                        valueProp: '_id',
                        labelProp: 'name1',
                      }}
                      onChange={(selected) => {
                        setFieldValue(`commissionArticles.${i}.commissionPartner`, selected ? selected._id : null);
                      }}
                      options={partners}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Select
                      name={`commissionArticles.${i}.commission_currency`}
                      value={article.commission_currency}
                      label={'Currency'}
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={[
                        { label: 'Euro', value: 'Euro' },
                        { label: 'USD', value: 'USD' },
                      ]}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Input
                      name={`commissionArticles.${i}.commission_price`}
                      value={article.commission_price}
                      label="Commission Price"
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Box p={2} textAlign="right">
                  <IconButton
                    type="button"
                    onClick={() => remove(i)}
                    variant="contained"
                    size="small"
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          }
          <Box p={2} textAlign="right">
            <IconButton
              type="button"
              onClick={() => {
                push({
                  article_id: 0,
                  article_name: t('Commission'),
                  commissionPartner: '',
                  commission_currency: 'Euro',
                  commission_price: '',
                  source_deviating: '',
                })
              }}
              variant="contained"
              color="primary"
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
      )}
    </FieldArray>
  );
};
