import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

const useBaseHook = ({
  recordsSelector,
  loadingSelector,
  timestampSelector,
  fetchRecords,
}) => {
	const dispatch = useDispatch();
  const records = useSelector(recordsSelector);
  const loading = useSelector(loadingSelector);
  const timestamp = useSelector(timestampSelector);

	const fetch = async () => {
		await dispatch(fetchRecords());
	};

	useEffect(() => {
		if (!timestamp && !loading) {
			fetch();
		}
	}, []);

	const revalidate = () => {
		fetch();
	};

	return { records, loading, revalidate, timestamp };
};

export default useBaseHook;
