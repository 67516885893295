import React from 'react';
import {
  getCountry,
  getDates,
  getGermanCollectionValues,
  getGermanNumber,
  getLanguageAcronym,
  getPaymentTerms,
  getRecipient,
  getUnloadingPartner,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';
import { formatNumberToEn } from '../../helpers/common';

class SalesConf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
      unloadingPartner: null,
    };
  }

  getUnloadingPartnerDetails() {
    let partner = getUnloadingPartner(
      this.props.sourceData,
      this.props.processorData,
      this.props.currentEntity,
    );

    return partner;
  }

  getLanguage = () => {
    let defaultLanguage = 'de';
    let language = this.props.processorData
      ? this.props.processorData
        ? this.props.processorData.documentLanguage
        : defaultLanguage
      : defaultLanguage;

    return getLanguageAcronym(language);
  };

  render() {
    const language = this.getLanguage();

    if (language === 'de') {
      return (
        <div className={'documents-container'} style={{ fontFamily: 'Arial !important' }}>
          <div className="main sales">
            <Header
              address={getRecipient(this.props, 'sales_confirmation', language)}
              accountManager={this.props.accountManagerProcessor}
              partnerLanguage={language}
              {...this.props}
            />
            <div className="invoice-body">
              <h3>Verkaufsbestätigung {this.props.currentEntity.orderNumber}</h3>
              <p className="disc">Sehr geehrte Damen und Herren, </p>
              <p className="disc-2">wir bestätigen Ihnen den Verkauf von einer Ladung wie folgt:</p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.getUnloadingPartnerDetails()
                      ? this.getUnloadingPartnerDetails().heading
                      : 'Entladestelle'}
                  </div>
                  <div className="cell bold reference-col">
                    {this.getUnloadingPartnerDetails()
                      ? this.getUnloadingPartnerDetails().reference
                      : 'Entladereferenz'}
                  </div>
                  <div className="cell bold cell-small">
                    {this.getUnloadingPartnerDetails()
                      ? this.getUnloadingPartnerDetails().date
                      : 'Entladedatum'}
                  </div>
                  {/*<div className="cell bold cell-small">Menge in t</div>*/}
                  <div className="cell bold cell-small">Preis je Einheit</div>
                </div>
                {this.props.currentEntity.articles.map((article, index) => (
                  <div className="row" key={index} style={{ borderTop: "none" }}>
                    <div className="cell first-col">
                      {article.processor_deviating && article.processor_deviating !== ''
                        ? `${article.processor_deviating}`
                        : this.props.i18n.language === 'en'
                        ? article.articleDetails.descriptionInEnglish
                        : article.articleDetails.descriptionInGerman}
                    </div>
                    <div className="cell cell-small">
                      {this.getUnloadingPartnerDetails()
                        ? this.getUnloadingPartnerDetails().partner.city
                        : ''}
                    </div>
                    <div className="cell reference-col">
                      {
                        (this.props.currentEntity.incotermProcessor === 'EXW' && this.props.currentEntity.incotermSource === 'EXW')
                          ? this.props.currentEntity.loadingReferenceSource
                          : this.props.currentEntity.loadingReferenceProcessor
                      }
                    </div>
                    <div className="cell cell-small">
                      {getGermanCollectionValues(this.props.currentEntity.deliveryProcess)}{' '}
                      {getDates(this.props.currentEntity.deliveryDate, this.props.currentEntity.deliveryProcess)}
                    </div>
                    <div className="cell cell-small">
                      {article.processor_cashflow === 'Supplementary Payment' ? '-' : ''}
                      {getGermanNumber(formatNumberToEn(article.processor_price))}{' '}
                      {article.processor_currency === 'Euro' ? '€' : '$'}
                    </div>
                  </div>
                ))}
              </div>

              {this.props.currentEntity.articles[0].processor_condition &&
                this.props.currentEntity.articles[0].processor_condition.length > 0 && (
                  <p className="geschreddert">
                    <span className="mr-1">
                      <b>-</b>
                    </span>{' '}
                    <b>{this.props.currentEntity.articles[0].processor_condition}</b>
                  </p>
                )}

              <div className="address">
                <p>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <span className="underline">
                        {this.getUnloadingPartnerDetails().heading}:
                      </span>{' '}
                      <br />{' '}
                      {this.getUnloadingPartnerDetails().partner.locationName
                        ? this.getUnloadingPartnerDetails().partner.locationName
                        : this.getUnloadingPartnerDetails().partner.name1}{' '}
                      <br />
                      {this.getUnloadingPartnerDetails().partner.street}{' '}
                      {this.getUnloadingPartnerDetails().partner.houseNumber} <br />
                      {this.getUnloadingPartnerDetails().partner.zipcode}{' '}
                      {this.getUnloadingPartnerDetails().partner.city}<br />
                      {getCountry(this.getUnloadingPartnerDetails().partner.country, language)}
                    </React.Fragment>
                  )}
                </p>
              </div>

              <span className="mt-4 lieferbedingung">
                Mindestladegewicht:{' '}
                {this.props.currentEntity.minLoadingWeight
                  ? getGermanNumber(
                      formatNumberToEn(this.props.currentEntity.minLoadingWeight),
                      'weight',
                    )
                  : ''}
                {this.props.currentEntity.articles[0].processor_unit}
              </span>
              <br />
              <span className="lieferbedingung">
                Lieferbedingung: {this.props.currentEntity.incotermProcessor}
              </span>
              <h3 className="mt-5 zahlungsziel">
                <span
                  dangerouslySetInnerHTML={{
                    __html: getPaymentTerms(
                      this.props.currentEntity.deviatingInvoiceAddressProcessor
                        ? this.props.currentEntity.deviatingInvoiceAddressProcessor
                        : this.props.processorData,
                      language,
                    ),
                  }}
                />
              </h3>
              <p className="mt-4 steuerschuldnerschaft">
                {this.props.tax ? this.props.tax.tax_text_processor : ''}
              </p>

              <div className="team-address page-avoid">
                <p className={'mt-3'}>Wir danken für Ihren Auftrag.</p>
                <p>Mit freundlichem Gruß</p>
                <p className="mt-3">Ihr {this.props.companyData.name2} Team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'documents-container'} style={{ fontFamily: 'Arial !important' }}>
          <div className="main sales">
            <Header
              address={getRecipient(this.props, 'sales_confirmation', language)}
              accountManager={this.props.accountManagerProcessor}
              partnerLanguage={language}
              {...this.props}
            />
            <div className="invoice-body">
              <h3>Sales Confirmation {this.props.currentEntity.orderNumber}</h3>
              <p className="disc">Dear Sir or Madam, </p>
              <p className="disc-2">we hereby confirm the sale of one load as follows:</p>

              <div className="tbl">
                <div className="row">
                  <div className="cell bold first-col">Material</div>
                  <div className="cell bold cell-small">
                    {this.props.t(
                      this.getUnloadingPartnerDetails()
                        ? this.getUnloadingPartnerDetails().headingEnglish
                        : 'Entladestelle',
                    )}
                  </div>
                  <div className="cell bold reference-col">
                    {this.props.t(
                      this.getUnloadingPartnerDetails()
                        ? this.getUnloadingPartnerDetails().referenceEnglish
                        : 'Entladereferenz',
                    )}
                  </div>
                  <div className="cell bold cell-small">
                    {this.props.t(
                      this.getUnloadingPartnerDetails()
                        ? this.getUnloadingPartnerDetails().dateEnglish
                        : 'Entladedatum',
                    )}
                  </div>
                  <div className="cell bold cell-small">Price per unit</div>
                </div>
                {this.props.currentEntity.articles.map((article, index) => (
                  <div className="row" key={index} style={{ borderTop: "none" }}>
                    <div className="cell first-col">
                      {article.processor_deviating && article.processor_deviating !== ''
                        ? `${article.processor_deviating}`
                        : article.articleDetails.descriptionInEnglish}
                    </div>
                    <div className="cell cell-small">
                      {this.getUnloadingPartnerDetails()
                        ? this.getUnloadingPartnerDetails().partner.city
                        : ''}
                    </div>
                    <div className="cell reference-col">
                      {
                        (this.props.currentEntity.incotermProcessor === 'EXW' && this.props.currentEntity.incotermSource === 'EXW')
                          ? this.props.currentEntity.loadingReferenceSource
                          : this.props.currentEntity.loadingReferenceProcessor
                      }
                    </div>
                    <div className="cell cell-small">
                      {this.props.currentEntity.deliveryProcess}{' '}
                      {getDates(this.props.currentEntity.deliveryDate, this.props.currentEntity.deliveryProcess)}
                    </div>
                    <div className="cell cell-small">
                      {article.processor_cashflow === 'Supplementary Payment' ? '-' : ''}
                      {getGermanNumber(formatNumberToEn(article.processor_price))}{' '}
                      {article.processor_currency === 'Euro' ? '€' : '$'}
                    </div>
                  </div>
                ))}
              </div>

              {this.props.currentEntity.articles[0].processor_condition &&
                this.props.currentEntity.articles[0].processor_condition.length > 0 && (
                  <p className="geschreddert">
                    <span className="mr-1">
                      <b>-</b>
                    </span>{' '}
                    <b>{this.props.currentEntity.articles[0].processor_condition}</b>
                  </p>
                )}

              <div className="address">
                <p>
                  {this.getUnloadingPartnerDetails() && (
                    <React.Fragment>
                      <span className="underline">
                        {this.getUnloadingPartnerDetails().headingEnglish}:
                      </span>{' '}
                      <br />{' '}
                      {this.getUnloadingPartnerDetails().partner.locationName
                        ? this.getUnloadingPartnerDetails().partner.locationName
                        : this.getUnloadingPartnerDetails().partner.name1}{' '}
                      <br />
                      {this.getUnloadingPartnerDetails().partner.street}{' '}
                      {this.getUnloadingPartnerDetails().partner.houseNumber} <br />
                      {this.getUnloadingPartnerDetails().partner.zipcode}{' '}
                      {this.getUnloadingPartnerDetails().partner.city} <br />
                      {getCountry(this.getUnloadingPartnerDetails().partner.country, language)}
                    </React.Fragment>
                  )}
                </p>
              </div>

              <span className="mt-4 lieferbedingung">
                Minimum loading weight:{' '}
                {this.props.currentEntity.minLoadingWeight
                  ? getGermanNumber(
                      formatNumberToEn(this.props.currentEntity.minLoadingWeight),
                      'weight',
                    )
                  : ''}
                {this.props.currentEntity.articles[0].processor_unit}
              </span>
              <br />
              <span className="lieferbedingung">
                Delivery incoterm: {this.props.currentEntity.incotermProcessor}
              </span>
              <h3 className="mt-5 zahlungsziel">
                <span
                  dangerouslySetInnerHTML={{
                    __html: getPaymentTerms(
                      this.props.currentEntity.deviatingInvoiceAddressProcessor
                        ? this.props.currentEntity.deviatingInvoiceAddressProcessor
                        : this.props.processorData,
                      language,
                    ),
                  }}
                />
              </h3>
              <p className="mt-4 steuerschuldnerschaft">
                {this.props.tax ? this.props.tax.tax_text_processor : ''}
              </p>

              <div className="team-address page-avoid">
                <p className={'mt-3'}>Thank you for your order.</p>
                <p>With kind regards</p>
                <p className="mt-3">Your {this.props.companyData.name2} team</p>
              </div>
            </div>
            <Footer {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation('common')(SalesConf);
