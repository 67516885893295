
import React from 'react';
import { FieldArray } from 'formik';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import {
  Input,
  AutocompleteSelect,
  Select,
  getHelpOrError,
} from 'components/Shared/mui-formik-inputs';
import { sumArticles, getCurrency, getGermanNumber } from 'helpers/documents';
import { coreApi } from 'api/core';
import { fixPreviousBrokenDates } from 'util/index';

export default ({
  formik,
  articles,
  processorIsWarehouse,
  sourceIsWarehouse,
  selectedSource,
  selectedProcessor,
}) => {
  const { t, i18n } = useTranslation('common');
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitCount,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const handleSelected = (file, i) => {
    if (selectedIndex !== null && selectedIndex !== i) {
      setSelectedIndex(i);
      return setSelectedFiles([file]);
    }

    setSelectedIndex(i);

    if (selectedFiles.includes(file)) {
      return setSelectedFiles(selectedFiles.filter((f) => f !== file));
    }

    setSelectedFiles(selectedFiles.concat([file]));
  };

  const handleFileDownload = async (name) => {
    const data = await coreApi.fetch(
      `/orders/download-file/${name}`,
      'GET',
      {},
      { responseType: 'blob' },
    );

    return download(data, name);
  };

  const onDrop = async (files, index) => {
    const filenames = [];

    for (let file in files) {
      const form = new FormData();
      form.append('files', files[file]);

      const data = await coreApi.post('/orders/upload', form);

      filenames.push(data.fileName);
    }

    const newArray = (values.articles[index].files || []).concat(filenames);
    setFieldValue(`articles.${index}.files`, newArray);
  };

  return (
    <FieldArray name="articles">
      {({ remove, push }) => (
        <Grid item xs={12}>
          {
            values.articles.map((article, i) => (
              <Box key={i} boxShadow={10} p={2} my={2} style={{ backgroundColor: '#fff5ed7a' }}>
                <Grid container item xs={12} spacing={2} key={i}>
                  <Grid item xs={8} md={3}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={`articles.${i}.article_id`}
                      label={'Article'}
                      errors={errors}
                      value={article.article_id}
                      settings={{
                        disableClearable: true,
                        valueProp: '_id',
                        labelProp: (o) => {
                          if (i18n.language.toLowerCase() === 'en') {
                            return `${o.descriptionInEnglish}`;
                          }

                          return `${o.descriptionInGerman}`;
                        },
                      }}
                      onChange={(selected) => {
                        setFieldValue(`articles.${i}.article_id`, selected._id);

                        let val = `${selected.descriptionInGerman}`;

                        if (i18n.language.toLowerCase() === 'en') {
                          val = `${selected.descriptionInEnglish}`;
                        }

                        setFieldValue(`articles.${i}.article_name`, val);
                      }}
                      options={articles}
                      required
                    />
                  </Grid>
                  <Grid item xs={8} md={3}>
                    <Input
                      name={`articles.${i}.wastecode`}
                      value={article.wastecode}
                      label="Wastecode"
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={!!article.source_weight && (articles.find((a) => a._id === article.article_id)?.itemType !== 'Product')}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Box pt={3}>
                      {
                        !sourceIsWarehouse && (
                          <Typography component="span">
                            <b>{t('Purchase total')}:</b> {getGermanNumber(sumArticles([article], 'source'))} {getCurrency([article])}{' '}
                          </Typography>
                        )
                      }
                      &nbsp;
                      {
                        !processorIsWarehouse && (
                          <Typography component="span">
                            <b>{t('Sales total')}:</b> {getGermanNumber(sumArticles([article], 'processor'))} {getCurrency([article])}{' '}
                          </Typography>
                        )
                      }
                    </Box>
                  </Grid>
                  {
                    !sourceIsWarehouse && (
                      <>
                        <Grid item xs={12}>
                          <Typography gutterBottom={false} component="div" variant="h6">{t('Purchase')}:</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD.MM.YYYY"
                            margin="dense"
                            label={t('Weight Slip Date')}
                            value={fixPreviousBrokenDates(article.source_weightslip_date)}
                            onChange={(date) => {
                              setFieldValue(`articles.${i}.source_weightslip_date`, date ? date.toDate() : null);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            autoOk={true}
                            fullWidth
                            error={!!errors[`articles.${i}.source_weightslip_date`]}
                          />
                          {
                            !!errors[`articles.${i}.source_weightslip_date`] && (
                              <FormHelperText error>
                                <span>{getHelpOrError(null, errors, `articles.${i}.source_weightslip_date`, t)}</span>
                              </FormHelperText>
                            )
                          }
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_weightslip_number`}
                            value={article.source_weightslip_number}
                            label="Weight Slip Number"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_weight`}
                            value={article.source_weight}
                            label={article.source_unit === 'Piece' ? 'Amount' : 'Weight'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_price`}
                            value={article.source_price}
                            label="Price"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.source_currency`}
                            value={article.source_currency}
                            label={'Currency'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { label: 'Euro', value: 'Euro' },
                              { label: 'USD', value: 'USD' },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.source_unit`}
                            value={article.source_unit}
                            label={'Units'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: 't', label: t('t') },
                              { value: 'Kg', label: t('Kg') },
                              { value: 'Piece',label: t('Piece') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_amount`}
                            value={article.source_amount}
                            label="Amount PU"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.source_packaging_units`}
                            value={article.source_packaging_units}
                            label={'Packaging Unit'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: null, label: '' },
                              { value: 'Bales',  label: t('Bales') },
                              { value: 'Packing Units', label: t('Packing Units') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_deviating`}
                            value={article.source_deviating}
                            label="Deviating Article Name"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_condition`}
                            value={article.source_condition}
                            label="Condition"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    )
                  }
                  {
                    !processorIsWarehouse && (
                      <>
                        { /* SALES SECTION */ }
                        <Grid item xs={12}>
                          <Typography gutterBottom={false} component="div" variant="h6">{t('article_sales_label')}:</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD.MM.YYYY"
                            margin="dense"
                            label={t('Weight Slip Date')}
                            value={fixPreviousBrokenDates(article.processor_weightslip_date)}
                            onChange={(date) => {
                              setFieldValue(`articles.${i}.processor_weightslip_date`, date ? date.toDate() : null);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            autoOk={true}
                            fullWidth
                            error={!!errors[`articles.${i}.processor_weightslip_date`]}
                          />
                          {
                            !!errors[`articles.${i}.processor_weightslip_date`] && (
                              <FormHelperText error>
                                <span>{getHelpOrError(null, errors, `articles.${i}.processor_weightslip_date`, t)}</span>
                              </FormHelperText>
                            )
                          }
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_weightslip_number`}
                            value={article.processor_weightslip_number}
                            label="Weight Slip Number"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_weight`}
                            value={article.processor_weight}
                            label={article.source_unit === 'Piece' ? 'Amount' : 'Weight'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_price`}
                            value={article.processor_price}
                            label="Price"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.processor_currency`}
                            value={article.processor_currency}
                            label={'Currency'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { label: 'Euro', value: 'Euro' },
                              { label: 'USD', value: 'USD' },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.processor_unit`}
                            value={article.processor_unit}
                            label={'Units'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: 't', label: t('t') },
                              { value: 'Kg', label: t('Kg') },
                              { value: 'Piece',label: t('Piece') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_amount`}
                            value={article.processor_amount}
                            label="Amount PU"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.processor_packaging_units`}
                            value={article.processor_packaging_units}
                            label={'Packaging Unit'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: null, label: '' },
                              { value: 'Bales',  label: t('Bales') },
                              { value: 'Packing Units', label: t('Packing Units') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_deviating`}
                            value={article.processor_deviating}
                            label="Deviating Article Name"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_condition`}
                            value={article.processor_condition}
                            label="Condition"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    )
                  }
                  <Grid item xs={12}>
                    <Dropzone
                      onDrop={(event) => onDrop(event, i)}
                      maxSize={10 * 1024 * 1024}
                      onDropRejected={() => alert(t('file_size_limit'))}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box>
                          <div
                            style={{ width: '100%', cursor: 'pointer' }}
                            {...getRootProps({ className: 'dropzone' })}
                          >
                            <input {...getInputProps()} />
                            <p>
                              {t(
                                "Drag 'n' drop invoice doc here, or click to select file",
                              )}
                            </p>
                          </div>
                          <Box>
                            {
                              (article.files || []).map((file, fileIndex) => (
                                <div className="display-flex" key={fileIndex}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          e.stopPropagation();

                                          handleSelected(file, i);
                                        }}
                                        checked={selectedFiles.includes(file)}
                                      />
                                    }
                                    label={(
                                      <span
                                        className={'file-link'}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();

                                          return handleFileDownload(file);
                                        }}
                                      >
                                        {file}
                                      </span>
                                    )}
                                  />
                                </div>
                              ))
                            }
                            {
                              !!selectedFiles.length && selectedIndex === i && (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  type="button"
                                  onClick={() => {
                                    setFieldValue(`articles.${i}.files`, article.files.filter((f) => !selectedFiles.includes(f)));
                                    setSelectedFiles([]);
                                    setSelectedIndex(null);
                                  }}
                                  style={{ borderColor: 'red', color: 'red', marginTop: '5px' }}
                                  fullWidth
                                >
                                  <DeleteIcon style={{ color: 'red' }} /> {t('Delete selected files')}
                                </Button>
                              )
                            }
                          </Box>
                        </Box>
                      )}
                    </Dropzone>
                  </Grid>
                </Grid>
                <Box p={2} textAlign="right">
                  <IconButton
                    type="button"
                    onClick={() => remove(i)}
                    variant="contained"
                    size="small"
                    color="secondary"
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </Box>
              </Box>
            ))
          }
          <Box p={2} textAlign="right">
            <IconButton
              type="button"
              onClick={() => {
                push({
                  article_name: '',
                  article_id: '',
                  source_price: '',
                  source_currency: 'Euro',
                  source_unit: 't',
                  source_cashflow: '',
                  source_condition: '',
                  source_deviating: '',
                  processor_price: '',
                  processor_currency: 'Euro',
                  processor_unit: 't',
                  processor_cashflow: '',
                  processor_condition: '',
                  processor_deviating: '',
                  wastecode: '',
                  source_weightslip_date: null,
                  source_weightslip_number: '',
                  source_weight: '',
                  source_amount: '',
                  source_packaging_units: '',
                  processor_weightslip_date: null,
                  processor_weightslip_number: '',
                  processor_weight: '',
                  processor_amount: '',
                  processor_packaging_units: '',
                  files: [],
                })
              }}
              variant="contained"
              color="primary"
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
      )}
    </FieldArray>
  );
};
