import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Helpers
import { defaultTableOptions, getActions, getColumns, getLocalization, mapTableData } from 'util/table-utils';
import { BANK_TABLE_COLUMNS } from 'constants/ui-constants';
import { useBanks } from 'hooks';
import { PATHS } from 'util/appConstants';

// Actions
import { deleteBank, selectBankStatus, searchFilter, setSearch } from 'redux/slices/bankSlice';
import { selectUser } from 'redux/slices/userSlice';

// Components
import MaterialTable from 'material-table';
import withConfirm from 'components/dialogs/delete';


const tableTitle = 'BANKS';

const BanksList = ({ confirm }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectBankStatus);
  const user = useSelector(selectUser);
  const { banks, revalidate } = useBanks();
  const search = useSelector(searchFilter);

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();

    confirm(
      async () => {
        await dispatch(deleteBank(rowData.id));
        revalidate();
      },
      {
        description: 'Are you sure?',
      },
    );
  };

  const actions = getActions(
    user,
    tableTitle,
    () => history.push(PATHS.system.banks.add),
    (e, rowData) => callbackOnDelete(e, rowData),
  );


  if (loading) return <div>Loading..</div>;

  return (
    <div className={'custom-table-styles'}>
      <MaterialTable
        data={mapTableData(banks)}
        title={t(tableTitle)}
        minRows={0}
        actions={actions}
        columns={getColumns(BANK_TABLE_COLUMNS, t)}
        options={{
          ...defaultTableOptions,
          searchText: search
        }}
        onRowClick={(e, rowData) => history.push(
          PATHS.system.banks.edit.replace(':id', rowData.id),
        )}
        localization={getLocalization(t)}
        onSearchChange={(searchStr) => dispatch(setSearch(searchStr))}
      />
    </div>
  );
};

export default withConfirm(BanksList);
