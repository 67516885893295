import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { initialValues } from 'components/company/constants';

// Helpers
import { useBanks } from 'hooks';
import { PATHS } from 'util/appConstants';

// Actions
import { selectCompanyStatus, addCompany } from 'redux/slices/companySlice';

// Components
import CompanyForm from 'components/company/form';


const currentAction = 'ADD';

const AddCompany = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectCompanyStatus);
  const { banks } = useBanks();

  const handleAddCompany = async (payload) => {
    await dispatch(addCompany(payload));

    history.push(PATHS.companies.root);
  };

  if (loading) return <div>Loading..</div>;
  return (
    <CompanyForm
      initialValues={initialValues}
      handleAddCompany={handleAddCompany}
      banks={banks}
      action={currentAction}
    />
  );
};

export default AddCompany;
