import * as yup from 'yup';

export const CarrierSchema = yup.object().shape({
  email: yup.string().required('Required'),
  name1: yup.string().required('Required'),
  name2: yup.string().required('Required'),
  phone1: yup.string().required('Required'),
  phone2: yup.string().nullable(),
  houseNumber: yup.string().required('Required'),
  city: yup.string().required('Required'),
  street: yup.string().required('Required'),
  zipcode: yup.string().required('Required'),
  country: yup.string().required('Required'),
  mobile: yup.string().nullable(),
  fax: yup.string().nullable(),
  paymentTerms: yup.string().required('Required'),
  invoiceDeliveryMethod: yup.string().required('Required'),
  invoiceEmail: yup.string().when('invoiceDeliveryMethod', {
    is: 'Email',
    then: yup.string().email('Invalid email').required('Required'),
    otherwise: yup.string().nullable()
  }),
  iban: yup.string().nullable(),
  bic: yup.string().nullable(),
  bankName: yup.string().nullable(),
  clientNumberAtClient: yup.number().nullable(),
  taxID: yup.string().required('Required'),
  nationalTaxID: yup.string().nullable(),
  currency: yup.string().required('Required'),
  documentLanguage: yup.string().required('Required'),
  contactPersons: yup.array(),
  vehicle: yup.array().of(yup.string()),
});

export const defaultValues = {
  email: '',
  name1: '',
  name2: '',
  phone1: '',
  phone2: '',
  houseNumber: '',
  city: '',
  street: '',
  zipcode: '',
  country: '',
  mobile: '',
  fax: '',
  paymentTerms: 'Immediately',
  invoiceDeliveryMethod: 'Email',
  iban: '',
  bic: '',
  bankName: '',
  clientNumberAtClient: '',
  taxID: '',
  nationalTaxID: '',
  currency: 'EUR',
  documentLanguage: 'German',
  contactPersons: [],
  vehicle: [],
};

export const AllowedFields = [
  'email', 'name1', 'name2', 'phone1', 'phone2', 'houseNumber', 'city', 'street', 'zipcode',
  'country', 'mobile', 'fax', 'paymentTerms', 'invoiceDeliveryMethod', 'iban', 'bic', 'bankName',
  'clientNumberAtClient', 'taxID', 'nationalTaxID', 'currency', 'documentLanguage', 'contactPersons',
  'vehicle', 'invoiceEmail',
];
