import React from 'react';
import {
  getDates,
  getPaymentTerms,
  getRecipient,
} from '../../helpers/documents';
import { getApiUrl } from '../../util/environment-utils';
import { withTranslation } from 'react-i18next';
import Footer from './footer';
import Header from './header';

class PurchaseConf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      apiUrl: getApiUrl(),
    };
  }

  render() {
    return (
      <div className={'documents-container'} style={{ fontFamily: 'Arial !important' }}>
        <div className="main purchase">
          <Header
            locationData={this.props.currentEntity.commissionArticles[0].commissionPartner}
            {...this.props}
            partnerLanguage="de"
          />
          <div className="invoice-body">
            <h3>Provisionsbestätigung {this.props.currentEntity.orderNumber}</h3>
            <p className="disc">Sehr geehrte Damen und Herren,</p>
            <p className="disc-2">wir bestätigen Ihnen den Einkauf von einer Ladung wie folgt:</p>

            <div className="tbl">
              <div className="row">
                <div className="cell bold first-col">Material</div>
                <div className="cell bold" style={{ width: '76%', paddingLeft: '10px' }}>Provision</div>
              </div>
              {this.props.currentEntity.commissionArticles.map((article, index) => (
                <div className="row" key={index} style={{ borderTop: "none" }}>
                  <div className="cell first-col">
                    {article.source_deviating && article.source_deviating !== ''
                      ? `${article.source_deviating}`
                      : article.article_name}
                  </div>
                  <div className="cell" style={{ width: '76%', paddingLeft: '10px' }}>
                    {article.commission_price} {article.commission_currency === 'Euro' ? '€' : '$'}
                  </div>
                </div>
              ))}
            </div>


            <p className="geschreddert">
              <span className="mr-1">
                <b>-</b>
              </span>{' '}
              geschreddert, mit Anhaftungen und Fremdstoffen in Ballen
            </p>
            <p className="mt-5 lieferbedingung">
              Lieferbedingung: {this.props.currentEntity.incotermSource}
            </p>
            <span
              dangerouslySetInnerHTML={{
                __html: getPaymentTerms(
                  this.props.currentEntity.deviatingInvoiceAddressSource && this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    ? this.props.currentEntity.deviatingInvoiceAddressSource[0]
                    : this.props.sourceData,
                  'de',
                ),
              }}
            />
            <div className="team-address page-avoid">
              <p className={'mt-3'}>Wir danken für Ihren Auftrag.</p>
              <p>Mit freundlichem Gruß</p>
              <p className="mt-3">Ihr {this.props.companyData.name2} Team</p>
            </div>
          </div>
          <Footer {...this.props} />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(PurchaseConf);
