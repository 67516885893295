
import React from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Input,
  AutocompleteSelect,
  Select,
} from 'components/Shared/mui-formik-inputs';

const filter = createFilterOptions();

export default ({
  values,
  articles,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  sourceAndProcessorSameCountry,
  selectedProcessor,
  selectedSource,
  sourceIsWarehouse,
  processorIsWarehouse,
}) => {
  const { t, i18n } = useTranslation('common');

  const wastecodes = [
    '19 12 04', '15 01 02', '20 01 39', '16 01 19',
  ];

  const articlesInStock = sourceIsWarehouse
    ? (selectedSource.stocks || []).filter((s) => s.amount > 0).map((s) => s.article_id)
    : [];
  const filteredArticles = sourceIsWarehouse
    ? articles.filter((a) => articlesInStock.includes(a._id))
    : articles;

  return (
    <FieldArray name="articles">
      {({ remove, push }) => (
        <Grid item xs={12}>
          {
            values.articles.map((article, i) => (
              <Box key={i} boxShadow={10} p={2} my={2} style={{ backgroundColor: '#fff5ed7a' }}>
                <Grid container item xs={12} spacing={2} key={i}>
                  <Grid item xs={6} md={3} key={i}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={`articles.${i}.article_id`}
                      label={'Article'}
                      errors={errors}
                      value={article.article_id}
                      settings={{
                        disableClearable: true,
                        valueProp: '_id',
                        labelProp: (o) => {
                          if (i18n.language.toLowerCase() === 'en') {
                            return `${o.descriptionInEnglish}`;
                          }

                          return `${o.descriptionInGerman}`;
                        },
                      }}
                      onChange={(selected) => {
                        setFieldValue(`articles.${i}.article_id`, selected._id);

                        let val = `${selected.descriptionInGerman}`;

                        if (i18n.language.toLowerCase() === 'en') {
                          val = `${selected.descriptionInEnglish}`;
                        }

                        setFieldValue(`articles.${i}.article_name`, val);
                      }}
                      options={filteredArticles}
                      help={sourceIsWarehouse ? t('Showing only articles available in the selected warehouse') : ''}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    {
                      !sourceAndProcessorSameCountry && (
                        <Input
                          name={`articles.${i}.wastecode`}
                          value={article.wastecode || ''}
                          label={'Wastecode'}
                          errors={errors}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={i === 0 && !values.articles.some((a) => a.wastecode)}
                        />
                      )
                    }

                  </Grid>
                  {
                    !sourceIsWarehouse && (
                      <>
                        <Grid item xs={12}>
                          <Typography gutterBottom={false} component="div" variant="h6">{t('Purchase')}:</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            name={`articles.${i}.source_deviating`}
                            value={article.source_deviating}
                            label="source_deviating"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.source_currency`}
                            value={article.source_currency}
                            label={'source_currency'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { label: 'Euro', value: 'Euro' },
                              { label: 'USD', value: 'USD' },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.source_price`}
                            value={article.source_price}
                            label="source_price"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.source_unit`}
                            value={article.source_unit}
                            label={'source_unit'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: 't', label: t('t') },
                              { value: 'Kg', label: t('Kg') },
                              { value: 'Piece',label: t('Piece') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Select
                            name={`articles.${i}.source_cashflow`}
                            value={article.source_cashflow}
                            label={'source_cashflow'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: null, label: '' },
                              { value: 'Supplementary Payment',  label: t('Supplementary Payment Source') },
                              { value: 'Compensation', label: t('Compensation Source') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            name={`articles.${i}.source_condition`}
                            value={article.source_condition}
                            label="source_condition"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    )
                  }
                  {
                    !processorIsWarehouse && (
                      <>
                        <Grid item xs={12}>
                          <Typography gutterBottom={false} component="div" variant="h6">{t('article_sales_label')}:</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Input
                            name={`articles.${i}.processor_deviating`}
                            value={article.processor_deviating}
                            label="processor_deviating"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.processor_currency`}
                            value={article.processor_currency}
                            label={'processor_currency'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { label: 'Euro', value: 'Euro' },
                              { label: 'USD', value: 'USD' },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Input
                            name={`articles.${i}.processor_price`}
                            value={article.processor_price}
                            label="processor_price"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Select
                            name={`articles.${i}.processor_unit`}
                            value={article.processor_unit}
                            label={'processor_unit'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: 't', label: t('t') },
                              { value: 'Kg', label: t('Kg') },
                              { value: 'Piece',label: t('Piece') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Select
                            name={`articles.${i}.processor_cashflow`}
                            value={article.processor_cashflow}
                            label={'processor_cashflow'}
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={[
                              { value: null, label: '' },
                              { value: 'Supplementary Payment',  label: t('Supplementary Payment Processor') },
                              { value: 'Compensation', label: t('Compensation Processor') },
                            ]}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            name={`articles.${i}.processor_condition`}
                            value={article.processor_condition}
                            label="processor_condition"
                            errors={errors}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    )
                  }
                </Grid>
                <Box p={2} textAlign="right">
                  <IconButton
                    type="button"
                    onClick={() => remove(i)}
                    variant="contained"
                    size="small"
                    color="secondary"
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </Box>
              </Box>
            ))
          }
          <Box p={2} textAlign="right">
            <IconButton
              type="button"
              onClick={() => {
                push({
                  article_name: '',
                  article_id: '',
                  source_price: '',
                  source_currency: 'Euro',
                  source_unit: 't',
                  source_cashflow: '',
                  source_condition: '',
                  source_deviating: '',
                  processor_price: '',
                  processor_currency: 'Euro',
                  processor_unit: 't',
                  processor_cashflow: '',
                  processor_condition: '',
                  processor_deviating: '',
                  wastecode: '',
                })
              }}
              variant="contained"
              color="primary"
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Grid>
      )}
    </FieldArray>
  );
};
