import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { InputOnlyNumbers } from 'components/Shared/mui-formik-inputs';

export default ({ onSubmit }) => {
  const { t } = useTranslation('common');
  const formik = useFormik({
    initialValues: {
      copies: '1',
    },
    validate: (values) => {
      const errors = {};

      if (!values.copies) {
        errors.copies = 'Required';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(values);
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, handleSubmit, handleChange, handleBlur, valid } = formik;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={9} md={3}>
          <InputOnlyNumbers
            name="copies"
            value={values.copies}
            errors={errors}
            onChange={handleChange}
            onBlur={handleBlur}
            label={'Copies'}
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginTop: '15px' }}
          >
            {t('copy')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
