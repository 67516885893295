import React from 'react';
import { FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Helpers
import { useArticles } from 'hooks';

// Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Input, SelectAC, AutocompleteSelect } from 'components/Shared/mui-formik-inputs';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
  },
  flexCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    paddingBottom: 30,
  },
  addCircleIcon: {
    fontSize: 28,
    position: 'relative',
    top: -2,
    marginLeft: 15,
    cursor: 'pointer',
  },
  removeCircleIcon: {
    color: '#FF3B2F',
    backgroundColor: 'white',
    float: 'right',
    margin: '57px 40px 20px',
    cursor: 'pointer',
  },
}));

const ArticlesForm = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const classes = useStyles();
  const { articles, loading } = useArticles();
  const { t, i18n } = useTranslation('common');

  if (loading) {
    return null;
  }

  return (
    <Box boxShadow={3} p={2} my={4}>
      <FieldArray name='articles'>
        {({ remove, push }) => (
          <>
            <div className={classes.flex}>
              <Typography className={classes.title} variant='h4'>
                {t('Articles')}
              </Typography>
              <AddCircleOutlineIcon
                className={classes.addCircleIcon}
                onClick={() => push({
                  article_id: '',
                  article_name: '',
                  price: '',
                  currency: '',
                  unit: '',
                  loads: '',
                  container_type: '40ft HC',
                  deviating_article_name: '',
                  weight_per_load: '',
                })}
                color='primary'
              />
            </div>

            {values.articles.length > 0 && values.articles.map((article, index) => (
              <React.Fragment key={index}>
                <Grid container spacing={2} wrap='wrap'>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={`articles.${index}.article_id`}
                      label={'Article'}
                      errors={errors}
                      value={article.article_id}
                      settings={{
                        disableClearable: false,
                        valueProp: '_id',
                        labelProp: (a) => {
                          const name = i18n.language === 'en'
                            ? `${a['descriptionInEnglish']} ${a.avv ? `AVV ${a['avv']}` : ''}`
                            : `${a['descriptionInGerman']} ${a.avv ? `AVV ${a['avv']}` : ''}`;

                          return name;
                        }
                      }}
                      onChange={(selected) => {
                        if (!selected) {
                          setFieldValue(`articles.${index}.article_name`, '');
                          setFieldValue(`articles.${index}.article_id`, '');

                          return;
                        }

                        const name = i18n.language === 'en'
                          ? `${selected['descriptionInEnglish']} ${selected.avv ? `AVV ${selected['avv']}` : ''}`
                          : `${selected['descriptionInGerman']} ${selected.avv ? `AVV ${selected['avv']}` : ''}`;

                        setFieldValue(`articles.${index}.article_name`, name);
                        setFieldValue(`articles.${index}.article_id`, selected._id);
                      }}
                      options={articles}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} wrap='nowrap'>
                  <div className={classes.flexCentered}>
                    <span className='article_label'>{t('article_sales_label')}: </span>
                  </div>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <Input
                      name={`articles.${index}.price`}
                      value={values.articles[index].price}
                      errors={errors}
                      label='Price'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <SelectAC
                      name={`articles.${index}.currency`}
                      value={values.articles[index].currency || 'Euro'}
                      label='currency'
                      errors={errors}
                      options={[
                        { value: 'Euro', label: 'Euro' },
                        { value: 'USD', label: 'USD' },
                      ]}
                      required={false}
                      setFieldValue={setFieldValue}
                      margin='normal'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <SelectAC
                      name={`articles.${index}.unit`}
                      value={values.articles[index].unit || 't'}
                      label='unit'
                      errors={errors}
                      options={[
                        { value: 't', label: 't' },
                        { value: 'Kg', label: 'Kg' },
                        { value: 'Piece', label: 'Piece' },
                      ]}
                      required={false}
                      setFieldValue={setFieldValue}
                      margin='normal'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <Input
                      name={`articles.${index}.loads`}
                      value={values.articles[index].loads}
                      errors={errors}
                      label='Loads'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <SelectAC
                      name={`articles.${index}.container_type`}
                      value={values.articles[index].container_type || '40ft HC'}
                      label='container_type'
                      errors={errors}
                      options={[{ value: '40ft HC', label: '40ft HC' }]}
                      required={false}
                      setFieldValue={setFieldValue}
                      margin='normal'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <Input
                      name={`articles.${index}.deviating_article_name`}
                      value={values.articles[index].deviating_article_name}
                      errors={errors}
                      label='deviating_article_name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={4} lg={2}>
                    <Input
                      name={`articles.${index}.weight_per_load`}
                      value={values.articles[index].weight_per_load}
                      errors={errors}
                      label='weight_per_load'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={values.articles[index].loads > 0}
                      disabled={values.articles[index].loads < 1}
                    />
                  </Grid>
                  <span
                    className={clsx('material-icons', classes.removeCircleIcon)}
                    onClick={() => remove(index)}
                  >
                   remove_circle
                  </span>
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

ArticlesForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default ArticlesForm;
