import React from 'react';
import pick from 'lodash/pick';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Helpers
import { ContractSchema, allowedFields } from './constants';
import { PATHS } from 'util/appConstants';

// Components
import { Input, Select, SelectAC, MultiSelectChips, AutocompleteSelect } from 'components/Shared/mui-formik-inputs';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import ArticlesForm from './ArticlesForm';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    paddingBottom: 30,
  },
  button: {
    margin: 10,
    backgroundColor: 'white',

  },
  buttonSubmit: {
    color: 'green',
  },
  buttonCancel: {
    color: 'red',
  },
}));

const ContractForm = ({
  onSubmit,
  buyerCallback,
  initialValues,
  podOptions,
  banks,
  buyerOptions,
  shipperOptions,
  partnerOptions,
  commercialOptions,
  buyerContractPersons,
  harbors,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  return (
    <div className={classes.root}>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={ContractSchema}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            return await onSubmit(pick(values, allowedFields));
          } catch (err) {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          resetForm,
          handleBlur,
          handleChange,
          setFieldValue,
          submitCount,
        }) => {
          if (!submitCount) {
            handleBlur = null;
          }
          console.log(errors);

          return (
            <Form noValidate autoComplete="off">
              <Button
                className={clsx(classes.button, classes.buttonSubmit)}
                variant='contained'
                color='primary'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {t('Save Contract')}
              </Button>
              <Button
                className={clsx(classes.button, classes.buttonCancel)}
                variant='contained'
                startIcon={<CancelIcon />}
                onClick={() => {
                  resetForm({});
                  history.push(PATHS.contracts.root);
                }}
              >
                {t('Cancel')}
              </Button>
              <Box boxShadow={3} p={2}>
                <Typography className={classes.title} variant='h4'>
                  {t('Partners')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='buyer'
                      value={values.buyer}
                      label='Buyer'
                      errors={errors}
                      options={buyerOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      callback={(selected) => {
                        if (selected && selected.partnerId) {
                          setFieldValue('buyerId', selected.partnerId);
                        }

                        buyerCallback(selected);

                        setFieldValue('buyerContactPersonId', null);
                      }}
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='shipper'
                      value={values.shipper}
                      label='Shipper'
                      errors={errors}
                      options={shipperOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      callback={(selected) => {
                        if (selected && selected.shipperId) {
                          setFieldValue('shipperId', selected.shipperId);
                        }
                      }}
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='consignee'
                      value={values.consignee}
                      label='Consignee'
                      errors={errors}
                      options={partnerOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      callback={(selected) => {
                        if (selected && selected._id) {
                          setFieldValue('consigneeId', selected._id);
                        } else {
                          setFieldValue('consigneeId', null);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='notify'
                      value={values.notify}
                      label='Notify'
                      errors={errors}
                      options={partnerOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      callback={(selected) => {
                        if (selected && selected._id) {
                          setFieldValue('notifyId', selected._id);
                        } else {
                          setFieldValue('notifyId', null);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='endUser'
                      value={values.endUser}
                      label='End User'
                      errors={errors}
                      options={partnerOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      callback={(selected) => {
                        if (selected && selected._id) {
                          setFieldValue('endUserId', selected._id);
                        } else {
                          setFieldValue('endUserId', null);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <SelectAC
                      name='commercial'
                      value={values.commercial}
                      label='Commercial'
                      errors={errors}
                      options={commercialOptions}
                      required={true}
                      setFieldValue={setFieldValue}
                      disableClearable
                      callback={(selected) => {
                        if (selected && selected._id) {
                          setFieldValue('commercialId', selected._id);
                        }
                      }}
                    />
                  </Grid>
                  {!!buyerContractPersons.length && (
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <SelectAC
                        name='buyerContactPersonId'
                        value={values.buyerContactPersonId ? buyerContractPersons.find((p) => p._id === values.buyerContactPersonId) : null}
                        label='Contact Person'
                        errors={errors}
                        options={buyerContractPersons.map(person => ({
                          label: person._id,
                          value: `${person.firstName} ${person.surname || ''}`,
                          ...person,
                        }))}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box boxShadow={3} p={2} my={4}>
                <Typography className={classes.title} variant='h4'>
                  {t('Shipping')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={'pod'}
                      label={'POD'}
                      errors={errors}
                      value={values.pod}
                      settings={{
                        disableClearable: false,
                        valueProp: 'value',
                        labelProp: 'label'
                      }}
                      onChange={(selected) => {
                        setFieldValue('pod', selected ? selected.value : null);
                      }}
                      options={podOptions}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Select
                      name='incoterms'
                      value={values.incoterms}
                      label='Incoterms'
                      options={[
                        { value: '', label: '' },
                        { value: 'CFR', label: 'CFR' },
                        { value: 'FAS', label: 'FAS' },
                        { value: 'EXW', label: 'EXW' },
                      ]}
                      errors={errors}
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  {
                    values.incoterms === 'FAS' && (
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <AutocompleteSelect
                          onBlur={handleBlur}
                          name={'pol'}
                          label={'POL'}
                          errors={errors}
                          value={values.pol}
                          settings={{
                            disableClearable: false,
                            valueProp: 'name',
                            labelProp: 'name'
                          }}
                          onChange={(selected) => {
                            setFieldValue('pol', selected ? selected.name : null);
                          }}
                          options={harbors}
                        />
                      </Grid>
                    )
                  }
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <MultiSelectChips
                      name='documents'
                      value={values.documents}
                      label='Documents'
                      options={[
                        'B/L',
                        'Invoice',
                        'Packing List',
                        'Loading Photos',
                        'Annex VII',
                        'ATR',
                        'COO',
                      ]}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      required={true}
                      fullWidth
                      noMargin
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Select
                      name='dtch'
                      value={values.dtch}
                      label='DTCH'
                      options={[
                        { value: 'Excluded', label: t('Excluded') },
                        { value: 'Included', label: t('Included') },
                      ]}
                      errors={errors}
                      onChange={handleChange}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Select
                      name='lots'
                      value={values.lots}
                      label='Part shipment'
                      options={[
                        { value: '1 Lot', label: t('1 Shipment') },
                        { value: 'Multiple Lots', label: t('Multiple Shipments') },
                      ]}
                      errors={errors}
                      onChange={handleChange}
                    />
                  </Grid>

                  {values.lots === 'Multiple Lots' && (
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Input
                        name='noOfLots'
                        value={values.noOfLots || ''}
                        label='Number of Part Shipments'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        margin='none'
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box boxShadow={3} p={2}>
                <Typography className={classes.title} variant='h4'>
                  {t('Payment')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      name={'bankId'}
                      label={'Bank'}
                      errors={errors}
                      value={values.bankId}
                      settings={{
                        disableClearable: true,
                        valueProp: '_id',
                        labelProp: (b) => `${b.bankName} - ${b.bankDescription || ''}`
                      }}
                      onChange={(selected) => {
                        setFieldValue('bankId', selected._id);
                        setFieldValue('bank', `${selected.bankName} - ${selected.bankDescription || ''}`);
                      }}
                      options={banks}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Select
                      name='paymentTerm'
                      value={values.paymentTerm}
                      label={'Payment Term (PIA)'}
                      options={[
                        { value: '10%', label: '10%' },
                        { value: '20%', label: '20%' },
                        { value: '30%', label: '30%' },
                        { value: '40%', label: '40%' },
                        { value: '50%', label: '50%' },
                        { value: '60%', label: '60%' },
                        { value: '70%', label: '70%' },
                        { value: '80%', label: '80%' },
                        { value: '90%', label: '90%' },
                        { value: '100%', label: '100%' },
                      ]}
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </Box>
              <ArticlesForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

ContractForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  buyerCallback: PropTypes.func.isRequired,
  podOptions: PropTypes.array.isRequired,
  bankOptions: PropTypes.array.isRequired,
  buyerOptions: PropTypes.array.isRequired,
  partnerOptions: PropTypes.array.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  commercialOptions: PropTypes.array.isRequired,
  buyerContractPersons: PropTypes.array.isRequired,
};

export default ContractForm;
