import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { selectAllTeamMembers, fetchAllTeamMembers } from 'redux/slices/personSlice';
import { useStyles } from 'components/Layout/SystemTableNav/styles/TableNav.styles';
import { AntTab, AntTabs } from 'components/Layout/SystemTableNav/AntTabs';
import TabCurrent from './TabCurrent';
import TabSelectTeam from './TabSelectTeam';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

const ContactPersons = ({ contactPersons, type, initialValues, setFieldValue }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fetched, setFetched] = React.useState(false);
  const teamMembers = useSelector(selectAllTeamMembers);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (!teamMembers.length && !fetched) {
      setFetched(true);
      dispatch(fetchAllTeamMembers());
    }
  }, [teamMembers]);

  const handleChange = (e, value) => {
    setCurrentTab(value);
  };

  const onAdd = (addedPerson) => {
    const list = [...contactPersons];

    const index = list.findIndex((cp) => cp._id === addedPerson._id);

    if (index !== -1) {
      list[index] = {
        ...list[index],
        ...addedPerson,
      };

      return setFieldValue('contactPersons', list);
    } else {
      return setFieldValue('contactPersons', contactPersons.concat([addedPerson]));
    }
  };

  const onRemove = (removedPerson) => {
    setFieldValue('contactPersons', contactPersons.filter((cp) => cp._id !== removedPerson._id));
  };

  const onBulkAdd = (persons) => {
    setFieldValue('contactPersons', contactPersons.concat(persons));
    handleChange(null, 0);
  };

  const contactPersonsIds = (contactPersons || []).map((cp) => cp._id);

  return (
    <Box boxShadow={3} p={2} my={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {t('Contact Persons')}
          </Typography>
        </Grid>
        <div className={classes.root}>
          <div className={classes.themedBg}>
            <AntTabs value={currentTab} onChange={handleChange} aria-label='persons sub tabs'>
              <AntTab label={t('Current')} />
              <AntTab label={t('Select Team')} />
            </AntTabs>
            <TabPanel value={currentTab} index={0}>
              <TabCurrent {...{ type, initialValues, contactPersons, onAdd, onRemove }} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <TabSelectTeam contactPersonsIds={contactPersonsIds} allPersons={teamMembers} onAdd={onBulkAdd} />
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

export default ContactPersons;
