import useBaseHook from './useBaseHook';
import { selectHarbors, selectHarborStatus, getHarbors, selectTimestamp } from 'redux/slices/harborSlice';

const useHarbors = () => {
	const {
		records: harbors, loading, revalidate,
	} = useBaseHook({
		recordsSelector: selectHarbors,
		loadingSelector: selectHarborStatus,
		timestampSelector: selectTimestamp,
		fetchRecords: getHarbors,
	});

	return { harbors, loading, revalidate };
};

export default useHarbors;
