import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Input, Select, MultiSelectChips, InputOnlyNumbers, ChipsInput } from 'components/Shared/mui-formik-inputs';
import {
  ROLES_FOR_EXTERNAL_PERSON_CARRIER,
  ROLES_FOR_EXTERNAL_PERSON_PARTNER,
  PersonSchema,
  defaultValues,
} from './constants';

const ExternPersonsModalForm = ({
  initialValues,
  onSubmit,
  type,
  onClose,
}) => {
  const { t } = useTranslation('common');
  const formik = useFormik({
    initialValues: {
      ...defaultValues,
      ...initialValues,
      type: initialValues.type || 'Extern',
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: PersonSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        return await onSubmit(omit(values, [
          'createdAt',
          'id',
          'tableData',
          'updatedAt',
          '__v',
          '_id',
        ]));
      } catch (err) {
        console.log(err);

        setSubmitting(false);
      }
    },
  });

  const { handleSubmit, handleChange, errors, values, touched, setFieldValue, submitCount } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={onClose}>
      <Box p={2}>
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MultiSelectChips
                name='role'
                value={values.role}
                label={'Role'}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                errors={errors}
                options={type === 'CARRIER' ? ROLES_FOR_EXTERNAL_PERSON_CARRIER : ROLES_FOR_EXTERNAL_PERSON_PARTNER}
                margin='dense'
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                name='salutation'
                value={values.salutation}
                label={'Salutation'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                options={[
                  { label: t('Mr'), value: t('Mr') },
                  { label: t('Mrs'), value: t('Mrs') },
                  { label: t('Dr'), value: t('Dr') },
                  { label: t('Other'), value: t('Other') },
                ]}
                margin='normal'
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='firstName'
                value={values.firstName}
                label={'First Name'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='surname'
                value={values.surname}
                label={'Surname'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='position'
                value={values.position}
                label={'Position'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='responsibility'
                value={values.responsibility}
                label={'Responsibility'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='department'
                value={values.department}
                label={'Department'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputOnlyNumbers
                name='phone'
                value={values.phone}
                label={'Phone'}
                type='tel'
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputOnlyNumbers
                name='mobileNumber'
                value={values.mobileNumber}
                label={'Mobile Number'}
                type='tel'
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='fax'
                value={values.fax}
                label={'Fax'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <FormikProvider value={formik}>
                <ChipsInput
                  name='emails'
                  value={values.emails}
                  label='E-mail addresses*'
                  margin='dense'
                  errors={errors}
                  touched={touched}
                  required={true}
                  setFieldValue={setFieldValue}
                  fullWidth
                />
              </FormikProvider>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Input
                name='comments'
                value={values.comments}
                label={'Comments'}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
            type="submit"
          >
            {t('SAVE')}
          </Button>
        </form>
      </Box>
    </Dialog>
  );
};

ExternPersonsModalForm.propTypes = {
  type: PropTypes.oneOf(['CARRIER', 'PARTNER']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default ExternPersonsModalForm;
