import React from 'react';
import { useTranslation } from 'react-i18next';
import querystring from 'querystring';
import { useFormik } from 'formik';
import isString from 'lodash/isString';
import isObject from 'lodash/isString';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CheckboxWithLabel, Select } from 'components/Shared/mui-formik-inputs';
import moment from 'moment';
import momentLocaleEn from 'moment/locale/en-gb';

export default ({ initialValues, onSubmit }) => {
  const { t, i18n } = useTranslation('common');
  const formik = useFormik({
    initialValues,
    onSubmit: async ({ ...values }) => {
      // convert booleans to 1/0 for the api
      onSubmit(Object.keys(values).reduce((acc, key) => {
        if (isString(values[key])) {
          acc[key] = values[key];
        } else if (isObject(values[key]) || moment.isMoment(values[key])) {
          acc[key] = moment(values[key]).format('YYYY-MM-DD');
        } else if (values[key] !== null) {
          acc[key] = values[key] ? 1 : 0;
        }

        return acc;
      }, {}));
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { values, handleChange, handleSubmit, submitForm, setFieldValue } = formik;

  const onChange = (e) => {
    handleChange(e);

    if (e.target.checked && values.onlyAllStatuses === '1') {
      setFieldValue('noStatus', false);
    }

    submitForm();
  };

  const handleAllChange = (e) => {
    const { checked } = e.target;

    if (checked && values.onlyAllStatuses === '1') {
      setFieldValue('yellow', false);
      setFieldValue('orange', false);
      setFieldValue('green', false);
      setFieldValue('checkbox', false);
    } else if (values.onlyAllStatuses === '1') {
      setFieldValue('yellow', true);
      setFieldValue('orange', true);
      setFieldValue('green', false);
      setFieldValue('checkbox', true);
    }

    handleChange(e);
    submitForm();
  };

  return (
    <Box px={2} mb={2} boxShadow={3}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={momentLocaleEn}>
        <form noValidate onSubmit={handleSubmit}>
          <Box display="flex">
            <Box display="inline-block"  mr={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YYYY"
                margin="dense"
                label={t('Created from')}
                value={values.created_from}
                onChange={(date) => {
                  if (date && date.isValid && date.isValid()) {
                    setFieldValue('created_from', date);

                    submitForm();
                  }

                  if (date === null) {
                    setFieldValue('created_from', null);

                    submitForm();
                  }
                }}
                autoOk={true}
                fullWidth
              />
            </Box>
            <Box display="inline-block"  mr={2}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YYYY"
                margin="dense"
                label={t('Created to')}
                value={values.created_to}
                onChange={(date) => {
                  if (date && date.isValid && date.isValid()) {
                    setFieldValue('created_to', date);

                    submitForm();
                  }

                  if (date === null) {
                    setFieldValue('created_to', null);

                    submitForm();
                  }
                }}
                autoOk={true}
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="inline-block" mr={2}>
              <CheckboxWithLabel
                color="primary"
                value={!!values.yellow}
                name="yellow"
                onChange={onChange}
                label={(
                  <>
                    <span className="yellow" style={{ verticalAlign: 'middle', marginLeft: 0 }} />
                    {t('orders_filters_yellow')}
                  </>
                )}
              />
            </Box>
            <Box display="inline-block" mr={2}>
              <CheckboxWithLabel
                color="primary"
                value={!!values.orange}
                name="orange"
                onChange={onChange}
                label={(
                  <>
                    <span className="orange" style={{ verticalAlign: 'middle', marginLeft: 0 }} />
                    {t('orders_filters_orange')}
                  </>
                )}
              />
            </Box>
            <Box display="inline-block" mr={2}>
              <CheckboxWithLabel
                color="primary"
                value={!!values.checkbox}
                name="checkbox"
                onChange={onChange}
                label={(
                  <>
                    <span className="blue" style={{ verticalAlign: 'middle', marginLeft: 0 }} />
                    {t('orders_filters_checkbox')}
                  </>
                )}
              />
            </Box>
            <Box display="inline-block" mr={2}>
              <CheckboxWithLabel
                color="primary"
                value={!!values.green}
                name="green"
                onChange={onChange}
                label={(
                  <>
                    <span className="green" style={{ verticalAlign: 'middle', marginLeft: 0 }} />
                    {t('orders_filters_green')}
                  </>
                )}
              />
            </Box>
            <Box display="inline-block" mr={2}>
              <CheckboxWithLabel
                color="primary"
                value={!!values.noStatus}
                name="noStatus"
                onChange={handleAllChange}
                label={t('orders_filters_no_status')}
              />
            </Box>
            <Box display="inline-block" mr={2}>
              <Select
                name="onlyAllStatuses"
                value={values.onlyAllStatuses}
                errors={{}}
                onChange={onChange}
                label="Filters interaction"
                options={[
                  { label: t('orders_filters_at_least_one'), value: '0' },
                  { label: t('orders_filters_all_selected'), value: '1' },
                  { label: t('orders_filters_only_all_selected'), value: '2' },
                ]}
              />
            </Box>
          </Box>
          <Box textAlign="right" p={2}>
            <Button
              component="a"
              href={`${process.env.REACT_APP_API_URL}/orders/csv?taira=${localStorage.getItem('token')}&${querystring.encode(initialValues)}`}
              variant="contained"
              color="secondary"
              size="small"
            >
              <SaveIcon fontSize="small" />&nbsp; {t('orders_download_csv')}
            </Button>
          </Box>
        </form>
      </MuiPickersUtilsProvider>
    </Box>
  );
};
