import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import { PATHS, formActions } from 'util/appConstants';
import { defaultValues } from 'components/System/Teams/constants';

// Actions
import { selectPersonLoading, addPerson } from 'redux/slices/personSlice';

// Components
import TeamForm from 'components/System/Teams/TeamForm';

/* Persons in this tab are always "Externs" */
const AddTeamMember = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectPersonLoading);

  const handleSubmit = async (payload) => {
    await dispatch(addPerson(payload));

    history.push(PATHS.system.team.root);
  };

  if (loading) return <div>Loading..</div>;
  return (
    <TeamForm
      onSubmit={handleSubmit}
      action={formActions.add}
      initialValues={defaultValues}
    />
  );
};

export default AddTeamMember;
