import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const validationErr = 'VALIDATION_ERROR';
const baseUrl = '/partners';
const initialState = {
  partners: {},
  deviatingPartners: {},
  partner: null,
  loading: false,
  deviatingLoading: false,
  timestamp: null,
  deviatingTimestamp: null,
  search: ""
};

const partnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartners: (state, action) => {
      const partners = {};
      action.payload.forEach(partner => {
        partners[partner.id] = partner;
      });
      state.partners = partners;
      state.timestamp = +new Date();
    },
    setDeviatingPartners: (state, { payload }) => {
      state.deviatingPartners = payload.reduce((acc, cur) => {
        acc[cur._id] = cur;

        return acc;
      }, {});
      state.deviatingTimestamp = +new Date();
    },
    setCurrentPartner: (state, action) => {
      state.partner = action.payload;
    },
    clearCurrentPartner: (state) => {
      state.partner = null;
    },
    clearPartners: (state) => {
      state.partners = {};
      state.deviatingPartners = {};
      state.timestamp = null;
      state.deviatingTimestamp = null;
    },
    setPartnersLoading: state => {
      state.loading = true;
    },
    setPartnersReady: state => {
      state.loading = false;
    },
    setDeviatingPartnersLoading: state => {
      state.deviatingLoading = true;
    },
    setDeviatingPartnersReady: state => {
      state.deviatingLoading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setPartners,
  setCurrentPartner,
  clearCurrentPartner,
  setPartnersLoading,
  setPartnersReady,
  clearPartners,
  setDeviatingPartners,
  setDeviatingPartnersLoading,
  setDeviatingPartnersReady,
  setSearch
} = partnerSlice.actions;
export default partnerSlice.reducer;

export const fetchPartnerById = id => async dispatch => {
  dispatch(setPartnersLoading());
  dispatch(clearCurrentPartner());

  const url = baseUrl + `/${id}`;

  try {
    const partner = await coreApi.fetch(url);
    dispatch(setCurrentPartner(partner));

    return partner;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPartnersReady());
  }
};

export const fetchPartners = () => async dispatch => {
  dispatch(setPartnersLoading());

  try {
    const res = await coreApi.fetch(baseUrl);
    dispatch(setPartners(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setPartnersReady());
  }
};

export const fetchDeviatingPartners = () => async dispatch => {
  dispatch(setDeviatingPartnersLoading());
  const url = baseUrl + '/deviating';

  try {
    const res = await coreApi.fetch(url);

    dispatch(setDeviatingPartners(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setDeviatingPartnersReady());
  }
};

export const addPartner = payload => async dispatch => {
  dispatch(setPartnersLoading());

  try {
    const res = await coreApi.post(baseUrl, payload);

    if (res.error === validationErr) {
      return { errors: res.errors };
    }

    dispatch(setShowMessage({
      description: 'Partner Created Successfully!',
      type: 'success',
    }));
    dispatch(clearPartners());
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Creating Partner. Please try again later.',
      type: 'error',
    }));
  } finally {
    dispatch(setPartnersReady());
  }
};

export const editPartner = (id, data) => async (dispatch) => {
  dispatch(setPartnersLoading());
  const url = baseUrl + `/${id}`;

  try {
    const res = await coreApi.put(url, data);

    if (res && res.error === validationErr) {
      return { errors: res.errors };
    }

    dispatch(setShowMessage({ description: 'Partner Updated Successfully!', type: 'success' }));
    dispatch(clearCurrentPartner());
    dispatch(clearPartners());
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Updating Partner. Please try again later.',
      type: 'error',
    }));
  } finally {
    dispatch(setPartnersReady());
  }
};

export const deletePartner = id => async dispatch => {
  dispatch(setPartnersLoading());
  const url = baseUrl + `/${id}`;

  try {
    const res = await coreApi.delete(url);

    if (res.status === 1) {
      dispatch(setShowMessage({ description: 'Partner Removed Successfully!', type: 'success' }));
    }
  } catch (err) {
    dispatch(setShowMessage({ description: 'Failed Deleting Partner. Please try again later.', type: 'error' }));
  } finally {
    dispatch(setPartnersReady());
  }
};

const partnerSelector = ({ newPartner }) => newPartner.partner;
const partnersSelector = ({ newPartner }) => newPartner.partners;
const partnersStatusSelector = ({ newPartner }) => newPartner.loading;
const deviatingPartnersSelector = ({ newPartner }) => newPartner.deviatingPartners;
const searchSelector = ({ newPartner }) => newPartner.search;

export const selectPartner = createSelector(partnerSelector, partner => partner);
export const selectPartnersStatus = createSelector(partnersStatusSelector, loading => loading);
export const selectPartners = createSelector(partnersSelector, partners => Object.values(partners));
export const selectDeviatingPartners = createSelector(deviatingPartnersSelector, deviating => Object.values(deviating));
export const selectTimestamp = createSelector(({ newPartner }) => newPartner.timestamp, t => t);
export const selectDeviatingTimestamp = createSelector(( { newPartner }) => newPartner.deviatingTimestamp, t => t);
export const selectDeviatingPartnersStatus = createSelector(( { newPartner }) => newPartner.deviatingLoading, l => l);
export const searchFilter = createSelector(searchSelector, search => search);
