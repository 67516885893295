import React from 'react';
import pick from 'lodash/pick';
import { useDispatch } from 'react-redux';
import { setShowMessage } from 'redux/slices/uiSlice';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import momentLocaleEn from 'moment/locale/en-gb';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DetailsTab from './DetailsTab';
import WeightslipsTab from './WeightslipsTab';
import {
  CreateSchema,
  acceptedFields,
  articlesAcceptedFields,
  commissionArticlesAcceptedFields,
  ArticleRequiredSourceOnly,
  ArticleRequiredProcessorOnly,
} from '../constants';
import { nullifyObject } from 'util/index';
import FinanceHeader from '../FinanceHeader';
import SendDocs from '../SendDocs';

export default ({
  onSubmit,
  onSendDocuments,
  onSourceChange,
  onProcessorChange,
  partners,
  deviatings,
  carriers,
  articles,
  warehouses,
  accountManagers,
  sourceLocations,
  processorLocations,
  initialValues,
  showTabs,
  companyOfficer,
  tax,
  user,
  onCreateCorrection,
  order,
  tab,
  setTab,
}) => {
  const dispatch = useDispatch();
  const [processorIsWarehouse, setProcessorIsWarehouse] = React.useState(false);
  const [sourceIsWarehouse, setSourceIsWarehouse] = React.useState(false);
  const [selectedSource, setSelectedSource] = React.useState(null);
  const [selectedProcessor, setSelectedProcessor] = React.useState(null);
  const [lastHandled, setLastHandled] = React.useState(0);
  const { t, i18n } = useTranslation('common');

  const handleTabChange = (e, v) => setTab(v);

  const formik = useFormik({
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const clean = {
          ...nullifyObject(pick(values, acceptedFields)),
          articles: values.articles.map((a) => nullifyObject(pick(a, articlesAcceptedFields))),
          commissionArticles: values.commissionArticles.map((a) => nullifyObject(pick(a, commissionArticlesAcceptedFields))),
          countries: (values.countries || []).filter((c) => !!c),
        };

        if (values._id) {
          clean.weightslips = {
            carrierEntries: values.weightslips.carrierEntries.filter((v) => v.invoiceDate || v.invoiceNumber | v?.files?.length ),
            processorEntries: values.weightslips.processorEntries.filter((v) => v.invoiceDate || v.invoiceNumber | v?.files?.length ),
            sourceEntries: values.weightslips.sourceEntries.filter((v) => v.invoiceDate || v.invoiceNumber | v?.files?.length ),
          }
        }

        await onSubmit(clean);
      } catch (err) {
        console.log(err);

        setSubmitting(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: CreateSchema,
    initialValues: {
      source: '',
      processor: '',
      sourceLocationId: '',
      processorLocationId: '',
      destinationType: 'dropshippment',
      currency: 'Euro',
      pickupDate: {
        startDate: moment().format('YYYY-MM-DD'),
      },
      deliveryDate: {
        startDate: moment().add(1, 'day').format('YYYY-MM-DD'),
      },
      countries: [],
      commissionArticles: [],
      articles: [{
        article_id: '',
        article_name: null,
        source_deviating: '',
        wastecode: null,
        processor_deviating: '',
        processor_cashflow: '',
        processor_price: '',
        processor_currency: 'Euro',
        processor_unit: 't',
        processor_condition: '',
        source_cashflow: '',
        source_price: '',
        source_currency: 'Euro',
        source_unit: 't',
        source_condition: '',
      }],
      ...initialValues,
    },
    validate: (values) => {
      const errors = {};

      if (values.articles && values.articles.length) {
        values.articles.forEach((a, i) => {
          if (a.source_weight && !a.wastecode) {
            const article = articles.find((art) => art._id === a.article_id);

            if (article && article.itemType !== 'Product') {
              errors[`articles.${i}.wastecode`] = 'Required';
            }
          }

          /*if (!sourceIsWarehouse && a.files && a.files.length) {
            if (!a.source_weightslip_date) {
              errors[`articles.${i}.source_weightslip_date`] = 'Required';
            }
          }

          if (!processorIsWarehouse && a.files && a.files.length) {
            if (!a.processor_weightslip_date) {
              errors[`articles.${i}.processor_weightslip_date`] = 'Required';
            }
          }*/
        });
      }

      return errors;
    },
  });

  const { isSubmitting, handleSubmit, values, setFieldValue, errors, submitCount, isValid } = formik;

  console.log(errors);

  React.useEffect(() => {
    let _sourceIsWarehouse = false;
    let _processorIsWarehouse = false;

    let _selectedSource = partners.find((p) => p._id === values.source);
    let _selectedProcessor = partners.find((p) => p._id === values.processor);

    if (values.source && !_selectedSource && values.destinationType === 'warehouse') {
      _selectedSource = warehouses.find((w) => w._id === values.source);
      _sourceIsWarehouse = true;
    }

    if (values.processor && !_selectedProcessor && values.destinationType === 'warehouse') {
      _selectedProcessor = warehouses.find((w) => w._id === values.processor);
      _processorIsWarehouse = true;
    }

    setProcessorIsWarehouse(_processorIsWarehouse);
    setSourceIsWarehouse(_sourceIsWarehouse);
    setSelectedProcessor(_selectedProcessor);
    setSelectedSource(_selectedSource);
  }, [values.source, values.processor]);

  React.useEffect(() => {
    setFieldValue('processorIsWarehouse', processorIsWarehouse);
    setFieldValue('sourceIsWarehouse', sourceIsWarehouse);
  }, [sourceIsWarehouse, processorIsWarehouse]);


  React.useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      dispatch(setShowMessage({
        description: 'There are empty or invalid fields',
        type: 'error',
      }));
      setLastHandled(submitCount);
    }
  }, [submitCount, isValid, lastHandled]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={momentLocaleEn}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        {
          showTabs === true && (
            <AppBar position="static" color="transparent" style={{ marginBottom: '10px' }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label={t('Order Details')} />
                <Tab label={t('WS+ Invoice')} />
              </Tabs>
            </AppBar>
          )
        }
        {
          showTabs === true && (
            <>
              <Box mb={1} p={2} boxShadow={3}>
                <FinanceHeader
                  showProfit={user && user.permissions.finance_read}
                  order={order}
                />
                <SendDocs
                  order={order}
                  sendDocuments={onSendDocuments}
                  createCorrection={onCreateCorrection}
                  partners={partners}
                  deviatings={deviatings}
                  carriers={carriers}
                  accountManagers={accountManagers}
                  companyOfficer={companyOfficer}
                  tax={tax}
                  user={user}
                />
              </Box>
              <Box mt={1} mb={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
                  size="large"
                  disabled={isSubmitting}
                >
                  {t('Update')}
                </Button>
              </Box>
            </>
          )
        }

        {
          tab === 0 && (
            <DetailsTab
              {...{
                formik,
                onSourceChange,
                onProcessorChange,
                partners,
                deviatings,
                carriers,
                articles,
                warehouses,
                accountManagers,
                sourceLocations,
                processorLocations,
                processorIsWarehouse,
                sourceIsWarehouse,
                selectedSource,
                selectedProcessor,
              }}
            />
          )
        }
        {
          tab === 1 && (
            <WeightslipsTab
              {...{
                formik,
                onSourceChange,
                onProcessorChange,
                partners,
                deviatings,
                carriers,
                articles,
                accountManagers,
                sourceLocations,
                processorLocations,
                companyOfficer,
                tax,
                order,
                processorIsWarehouse,
                sourceIsWarehouse,
                selectedSource,
                selectedProcessor,
              }}
            />
          )
        }
        {
          !values._id && (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              style={{ marginTop: 20, backgroundColor: 'white', color: 'green' }}
              size="large"
              disabled={isSubmitting}
            >
              {t(values._id ? 'Update' : 'Save')}
            </Button>
          )
        }

      </form>
    </MuiPickersUtilsProvider>
  );
};
