import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: localStorage.getItem('custom-primary-color') || '#56E39F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: localStorage.getItem('custom-secondary-color') || '#1D283E',
      contrastText: '#FFFFFF',
    },
  },
});

export default theme;
