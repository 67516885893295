import { createSlice, createSelector } from '@reduxjs/toolkit';
import { coreApi } from 'api/core';
import { setShowMessage } from 'redux/slices/uiSlice';

const initialState = {
  harbors: {},
  harbor: null,
  timestamp: null,
  loading: false,
  search: ""
};

const harborSlice = createSlice({
  name: 'harbors',
  initialState,
  reducers: {
    setHarbor: (state, action) => {
      state.harbor = action.payload;
    },
    resetHarbors: (state, action) => {
      state.harbors = {};
      state.timestamp = null;
    },
    setHarbors: (state, action) => {
      const harbors = {};
      action.payload.forEach(harbor => {
        harbors[harbor._id] = harbor;
      });
      state.harbors = harbors;
      state.timestamp = +new Date();
    },
    removeHarbor: (state, action) => {
      delete state.harbors[action.payload];
    },
    setHarborLoading: (state) => {
      state.loading = true;
    },
    setHarborReady: (state) => {
      state.loading = false;
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    }
  },
});

export const {
  setHarbor,
  setHarbors,
  removeHarbor,
  setHarborLoading,
  setHarborReady,
  resetHarbors,
  setSearch
} = harborSlice.actions;
export default harborSlice.reducer;

export const getHarbor = (id) => async dispatch => {
  dispatch(setHarborLoading());

  try {
    const res = await coreApi.fetch(`/harbors/${id}`);
    dispatch(setHarbor(res));

    return res;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setHarborReady());
  }
};

export const getHarbors = () => async dispatch => {
  dispatch(setHarborLoading());

  try {
    const harbors = await coreApi.fetch('/harbors');

    dispatch(setHarbors(harbors));

    return harbors;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setHarborReady());
  }
};

export const addHarbor = (payload) => async dispatch => {
  dispatch(setHarborLoading());

  try {
    const harbor = await coreApi.post('/harbors', payload);
    dispatch(resetHarbors());
    dispatch(setShowMessage({
      description: 'Harbor Added Successfully!',
      type: 'success',
    }));

    return harbor;
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed adding harbor. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setHarborReady());
  }
};

export const editHarbor = (id, payload) => async dispatch => {
  dispatch(setHarborLoading());

  try {
    const res = await coreApi.put(`/harbors/${id}`, payload);

    dispatch(resetHarbors());
    dispatch(setShowMessage({
      description: 'Harbor Updated Successfully',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: err.message ?? 'Failed editing harbor. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setHarborReady());
  }
};

export const deleteHarbor = (id) => async dispatch => {
  dispatch(setHarborLoading());

  try {
    const res = await coreApi.delete(`/harbors/${id}`);

    dispatch(removeHarbor(id));
    dispatch(setShowMessage({
      description: 'Harbor Deleted Successfully!',
      type: 'success',
    }));
  } catch (err) {
    dispatch(setShowMessage({
      description: 'Failed Deleting Harbor. Please try again later',
      type: 'error',
    }));
  } finally {
    dispatch(setHarborReady());
  }
};

const harborSelector = ({ harbors }) => harbors.harbor;
const harborsSelector = ({ harbors }) => harbors.harbors;
const harborStatusSelector = ({ harbors }) => harbors.loading;
const searchSelector = ({ harbors }) => harbors.search;

export const selectHarbor = createSelector(harborSelector, harbor => harbor);
export const selectHarbors = createSelector(harborsSelector, harbors => Object.values(harbors));
export const selectHarborStatus = createSelector(harborStatusSelector, loading => loading);
export const selectTimestamp = createSelector(({ harbors }) => harbors.timestamp, t => t);
export const searchFilter = createSelector(searchSelector, search => search);
