export const defaultPermissions = {
  carriers_create: false,
  carriers_delete: false,
  carriers_read: false,
  carriers_update: false,
  invoice_create: false,
  invoice_read: false,
  finance_read: false,
  orders_create: false,
  orders_delete: false,
  orders_read: false,
  orders_update: false,
  partners_create: false,
  partners_delete: false,
  partners_read: false,
  partners_update: false,
  tuning_delete: false,
  tuning_read: false,
  tuning_update: false,
  weightSlips_delete: false,
  weightSlips_read: false,
  weightSlips_update: false,
  system_read: false,
  stocks_read: false,
  company_read: false,
  contracts_read: false,
  contracts_update: false,
  contracts_create: false,
  contracts_delete: false,
};

export const permissionsList = [
  { label: 'Carriers%%(CREATE)', value: 'carriers_create' },
  { label: 'Carriers%%(READ)', value: 'carriers_read' },
  { label: 'Carriers%%(UPDATE)', value: 'carriers_update' },
  { label: 'Carriers%%(DELETE)', value: 'carriers_delete' },

  { label: 'Finance%%(READ)', value: 'finance_read' },

  { label: 'Invoice%%(CREATE)', value: 'invoice_create' },
  { label: 'Invoice%%(READ)', value: 'invoice_read' },

  { label: 'Orders%%(CREATE)', value: 'orders_create' },
  { label: 'Orders%%(READ)', value: 'orders_read' },
  { label: 'Orders%%(UPDATE)', value: 'orders_update' },
  { label: 'Orders%%(DELETE)', value: 'orders_delete' },
  { label: 'Orders restrict only to assigned', value: 'orders_restrictToAssignedOrders' },

  { label: 'Partners%%(CREATE)', value: 'partners_create' },
  { label: 'Partners%%(READ)', value: 'partners_read' },
  { label: 'Partners%%(UPDATE)', value: 'partners_update' },
  { label: 'Partners%%(DELETE)', value: 'partners_delete' },

  { label: 'Contracts%%(CREATE)', value: 'contracts_create' },
  { label: 'Contracts%%(READ)', value: 'contracts_read' },
  { label: 'Contracts%%(UPDATE)', value: 'contracts_update' },
  { label: 'Contracts%%(DELETE)', value: 'contracts_delete' },

  { label: 'Tuning%%(READ)', value: 'tuning_read' },
  { label: 'Tuning%%(UPDATE)', value: 'tuning_update' },

  { label: 'Weightslips%%(READ)', value: 'weightSlips_read' },
  { label: 'Weightslips%%(UPDATE)', value: 'weightSlips_update' },

  { label: 'Stocks', value: 'stocks_read' },
  { label: 'System', value: 'system_read' },

  { label: 'Company', value: 'company_read' },
];
